import useAppConfig from '@hooks/useAppConfig';

import GTM_ITEM_CATEGORIES from './constants/gtmItemCategories';
import getBasketDiscountCodes from './utils/getBasketDiscountCodes';
import getGroupedBasketItems from './utils/getGroupedBasketItems';
import getGtmAddonItems from './utils/getGtmAddonItems';
import getGtmDietItemsWithDietAddons from './utils/getGtmDietItemsWithDietAddons';
import getGtmDishItems from './utils/getGtmDishItems';
import usePushGtmCartEvents from './usePushGtmCartEvents';

const useSyncGtmCart = () => {
  const {
    data: { branding },
  } = useAppConfig();

  const pushCartEvents = usePushGtmCartEvents();

  const brandName = branding.name;

  return (
    prevBasket,
    currentBasket,
    { asSeparateEvents = true, withAddedDiet = true, withDietDiff = false } = {}
  ) => {
    const { prevBasketDiscountCode, currentBasketDiscountCode } =
      getBasketDiscountCodes(prevBasket, currentBasket);

    const {
      dietItems: prevDietItems,
      dishItems: prevDishItems,
      addonItems: prevAddonItems,
    } = getGroupedBasketItems(prevBasket.rows || []);
    const {
      dietItems: currentDietItems,
      dishItems: currentDishItems,
      addonItems: currentAddonItems,
    } = getGroupedBasketItems(currentBasket.rows || []);

    const {
      gtmRemovedDietItemsWithDietAddons,
      gtmAddedDietItemsWithDietAddons,
    } = getGtmDietItemsWithDietAddons({
      brandName,
      prevDietItems,
      currentDietItems,
      prevBasketDiscountCode,
      currentBasketDiscountCode,
      withDietDiff,
    });
    const { gtmRemovedAddonItems, gtmAddedAddonItems } = getGtmAddonItems({
      brandName,
      prevAddonItems,
      currentAddonItems,
      prevBasketDiscountCode,
      currentBasketDiscountCode,
    });
    const { gtmRemovedDishItems, gtmAddedDishItems } = getGtmDishItems({
      brandName,
      prevDishItems,
      currentDishItems,
      prevBasketDiscountCode,
      currentBasketDiscountCode,
    });

    const gtmRemovedItems = [
      ...gtmRemovedDietItemsWithDietAddons,
      ...gtmRemovedAddonItems,
      ...gtmRemovedDishItems,
    ];
    const gtmAddedItems = [
      ...(withAddedDiet
        ? gtmAddedDietItemsWithDietAddons
        : gtmAddedDietItemsWithDietAddons.filter(
            ({ item_category }) => item_category !== GTM_ITEM_CATEGORIES.diet
          )),
      ...gtmAddedAddonItems,
      ...gtmAddedDishItems,
    ];

    if (gtmRemovedItems.length > 0) {
      pushCartEvents({
        event: 'remove_from_cart',
        items: gtmRemovedItems,
        asSeparateEvents,
      });
    }

    if (gtmAddedItems.length > 0) {
      pushCartEvents({
        event: 'add_to_cart',
        items: gtmAddedItems,
        asSeparateEvents,
      });
    }
  };
};

export default useSyncGtmCart;
