import { useTranslation } from 'next-i18next';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { updateUserData } from '@store/auth/auth.actions';
import { selectIsAuthenticated, selectUser } from '@store/auth/auth.slice';
import { Form, Formik } from 'formik';
import tw from 'twin.macro';

import Alert from '@components/elements/Alert';
import Button from '@components/elements/Button';
import InputsIndex from '@components/elements/Form/InputsIndex';
import Modal from '@components/elements/Modal/Modal';
import INPUT_IDS from '@constants/inputs/userDataInputs';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useMediaQuery from '@hooks/useMediaQuery';
import { down } from '@utils/screens';
import showToast from '@utils/showToast';

import { initialValues, useValidationSchema } from './formikData';
import useFields from './useFields';

const MissingDataModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { lastName, firstName, phone, email } = useSelector(selectUser);
  const phoneNumber = typeof phone === 'string' ? phone : phone?.number;
  const { demandPhoneNumber } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );
  const [isOpen, setIsOpen] = useState(false);
  const isDown500 = useMediaQuery(down('500px'), true);

  const fields = useFields({
    phoneNumber,
    firstName,
    lastName,
    email,
    demandPhoneNumber,
  });

  const validationSchema = useValidationSchema({
    demandPhoneNumber,
    firstName,
    lastName,
    email,
  });

  useEffect(() => {
    if (!isAuthenticated) {
      setIsOpen(false);

      return;
    }

    if (!phoneNumber || !lastName || !firstName || !email) {
      setIsOpen(true);
    }
  }, [isAuthenticated, phoneNumber, lastName, firstName, email]);

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    if (phoneNumber) {
      delete values[INPUT_IDS.PHONE];
    }
    if (lastName) {
      delete values[INPUT_IDS.LAST_NAME];
    }
    if (firstName) {
      delete values[INPUT_IDS.FIRST_NAME];
    }
    if (email) {
      delete values[INPUT_IDS.EMAIL];
    }

    dispatch(updateUserData(values))
      .then(unwrapResult)
      .then(() => {
        setIsOpen(false);
        showToast(
          t(
            '$*notification.personalData.success',
            'Dane zostały pomyślnie zaktualizowane!'
          ),
          { type: 'success' }
        );
      })
      .catch(error => {
        setStatus({
          apiErrorMessage: error?.message ?? error?.['hydra:description'],
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const isCloseDisabled = fields.some(({ required }) => required);

  return (
    <Modal
      isOpen={isOpen}
      disableClose={isCloseDisabled}
      onClose={handleClose}
      styles={{ wrapper: tw`w-full max-w-lg` }}
      desktopSize="lg"
    >
      <Modal.Header>
        {t('$*components.missingDataModal.title', 'Uwaga')}
      </Modal.Header>
      <Modal.Content>
        <p tw="mb-6">
          {t(
            '$*components.missingDataModal.description',
            'Uzupełnij dane, które ułatwią nam ewentualny kontakt z Tobą w związku z dostawą zamówienia.'
          )}
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, status }) => (
            <Form>
              <div className="row">
                {fields.map(({ id, type, label, required, autoComplete }) => (
                  <div className="col-12" key={id}>
                    <InputsIndex
                      id={id}
                      type={type}
                      label={label}
                      required={required}
                      autoComplete={autoComplete}
                    />
                  </div>
                ))}
              </div>

              {status?.apiErrorMessage && (
                <Alert styles={{ css: tw`mt-4` }}>
                  {/* i18next-extract-disable-next-line */}
                  {t(`$*error.api.${status.apiErrorMessage}`)}
                </Alert>
              )}

              <div tw="pt-3 flex flex-wrap justify-between -mx-2 -my-1">
                {isCloseDisabled ? (
                  <Button
                    type="submit"
                    color="success"
                    variant="contained"
                    isLoading={isSubmitting}
                    styles={{
                      button: [tw`justify-center flex-auto w-full mx-2`],
                    }}
                  >
                    {t('$*common.save', 'Zapisz')}
                  </Button>
                ) : (
                  <>
                    <Button
                      type="button"
                      color="error"
                      variant="outlined"
                      styles={{
                        button: [
                          tw`mx-2 my-1`,
                          isDown500 && tw`justify-center flex-auto`,
                        ],
                      }}
                      onClick={handleClose}
                    >
                      {t('$*common.skip', 'Pomiń')}
                    </Button>
                    <Button
                      type="submit"
                      color="success"
                      variant="outlined"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      styles={{
                        button: [
                          tw`mx-2 my-1`,
                          isDown500 && tw`justify-center flex-auto`,
                        ],
                      }}
                    >
                      {t('$*common.save', 'Zapisz')}
                    </Button>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

MissingDataModal.displayName = 'MissingDataModal';

export default MissingDataModal;
