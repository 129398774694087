import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

const updateDiet = async ({ dietId, payload }) => {
  const response = await axios.put(ENDPOINT.EDIT_DIET(dietId), payload);

  return response?.data ?? {};
};

export const getMacroInPercent = (type, macros) => {
  let multipler;
  if (type === 'fats') {
    multipler = 9;
  } else {
    multipler = 4;
  }

  const resultMacros =
    macros.fats * 9 + macros.protein * 4 + macros.carbohydrates * 4;

  const resultMacrosCalories = macros.calories
    ? resultMacros / macros.calories
    : 0;

  const resultPercent =
    resultMacros === 0 || resultMacrosCalories === 0
      ? 0
      : getIntegerIfIntegerOrFixed(
          ((macros[type] * multipler) / resultMacros) *
            100 *
            resultMacrosCalories,
          0
        );

  return resultPercent;
};

export default {
  updateDiet,
  getMacroInPercent,
};
