import { combineReducers } from '@reduxjs/toolkit';

import orderTabsSlice from '@features/orderTabs/orderTabsSlice';

import authSlice from './auth/auth.slice';
import basketEditOrderSlice from './basket/basketEditOrder.slice';
import basketInitialDataSlice from './basket/basketInitialData.slice';
import basketNewOrderSlice from './basket/basketNewOrder.slice';
import configSlice from './config/config.slice';
import myDietSlice from './myDiet/myDiet.slice';
import nativeAppConfigSlice from './nativeAppConfig/nativeAppConfig.slice';
import orderFormSlice from './orderForm/orderForm.slice';
import emptyApi from './services/emptyApi';

const appReducer = combineReducers({
  [emptyApi.reducerPath]: emptyApi.reducer,

  auth: authSlice,
  basketEditOrder: basketEditOrderSlice,
  basketInitialData: basketInitialDataSlice,
  basketNewOrder: basketNewOrderSlice,
  config: configSlice,
  myDiet: myDietSlice,
  nativeAppConfig: nativeAppConfigSlice,
  orderForm: orderFormSlice,
  orderTabs: orderTabsSlice,
  lastAction: (_, action) => action.type,
});

const rootReducer = (state, action) => {
  /* if you are using RTK, you can import your action and use it's type property instead of the literal definition of the action  */
  if (action.type === 'app/resetToInitialStore') {
    return appReducer({}, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
