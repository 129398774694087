import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import { transformApiErrors } from '@services/Api.serviceTS';
import BasketService from '@services/Basket.service';
import axios from '@utils/axiosInstance';
import getBasketDebounceHandler from '@utils/getBasketDebounceHandler';
import showToast from '@utils/showToast';

const duplicateBasketDiet = async ({
  data: { basketItemDiet },
  resolve,
  reject,
  clearStaleRejects,
}) => {
  clearStaleRejects();

  try {
    const response = await axios.post(
      ENDPOINT.BASKET_DUPLICATE_DIET,
      {
        basketItemDiet,
      },
      {
        errorHandle: false,
      }
    );

    const data = response?.data ?? {};
    const uiRows = BasketService.transformResponseBasketUI(data);

    resolve({ ...data, uiRows });
  } catch (error) {
    reject(error);
  }
};

const useDuplicateBasketDiet = ({ basketKey, options = {} } = {}) => {
  const queryClient = useQueryClient();

  const mutationKey = `${basketKey}DuplicateDiet`;

  return useMutation({
    mutationFn: ({ shouldDebounce, ...data }) => {
      return getBasketDebounceHandler({
        shouldDebounce,
        mutationKey,
        mutationFn: duplicateBasketDiet,
        data,
      });
    },
    mutationKey: [mutationKey],
    onSuccess: response => {
      queryClient.setQueryData([basketKey], response);
    },
    onError: err => {
      const error = err?.response?.data;
      const apiFieldErrors = transformApiErrors(error);

      showToast(apiFieldErrors?.day, { type: 'warning' });
    },
    retry: 0,
    ...options,
  });
};

export default useDuplicateBasketDiet;
