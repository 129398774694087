import { useSelector } from 'react-redux';
import { selectors as basketNewOrderSelectors } from '@store/basket/basketNewOrder.slice';
import { useQueryClient } from '@tanstack/react-query';
import orderBy from 'lodash/orderBy';

import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import DISCOUNT_TYPE from '@constants/discountType';
import { getIDfromIRI } from '@services/Api.service';

export const getBasketPrices = ({ dietElements, testMode, queryClient }) => {
  const {
    diets = [],
    additionalPrices,
    priceList: { indexedByKey: priceListByKey } = {},
    discounts = {},
  } = queryClient.getQueryData(['orderForm']);

  const getPriceAfterDiscount = (
    { price, dietLength },
    isSubscriptionPayment
  ) => {
    const DISCOUNT_DIET_LENGHT = discounts?.[DISCOUNT_TYPE.DIET_LENGTH] ?? [];
    const sortedDiscountDietLength = orderBy(
      DISCOUNT_DIET_LENGHT,
      ['minLength', 'maxLength'],
      ['asc', 'asc']
    );

    const discountDietLengthIndex =
      sortedDiscountDietLength.findIndex(
        ({ minLength, maxLength }) =>
          dietLength >= minLength && dietLength <= maxLength
      ) ?? -1;

    const hasDiscountDietLength = discountDietLengthIndex >= 0;

    if (isSubscriptionPayment) {
      return { price, discountParts: {} };
    }

    if (hasDiscountDietLength) {
      const { isPercentage, discount } =
        sortedDiscountDietLength[discountDietLengthIndex];

      if (isPercentage) {
        return {
          price: price * ((100 - discount) / 100),
          discountParts: { DIET_LENGTH: price * (discount / 100) * dietLength },
        };
      }

      return {
        price: price - discount,
        discountParts: { DIET_LENGTH: discount * dietLength },
      };
    }

    return { price, discountParts: {} };
  };

  const sumValues = obj => Object.values(obj).reduce((a, b) => a + b, 0);

  const getSuperVariantResignPrice = (
    currentVariant,
    currentCalorific,
    selectedMealTypes
  ) => {
    const superVariantResignPrice = (
      currentCalorific?.superVariantMealType ?? []
    )
      .map(({ mealType, resignPrice }) =>
        !(selectedMealTypes ?? []).includes(mealType) ? resignPrice ?? 0 : 0
      )
      .reduce((acc, item) => (acc += item), 0);

    return {
      minPrice: currentVariant?.minPrice,
      price: superVariantResignPrice,
    };
  };

  const basketPrices = dietElements.reduce(
    (acc, dietElement) => {
      const isSubscriptionPayment =
        dietElement?.paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;

      const dietId = getIDfromIRI(dietElement.dietId);
      const variantId = getIDfromIRI(dietElement.variantId);
      const calorificId = getIDfromIRI(dietElement.calorificId);

      const currentDiet = diets.find(
        ({ '@id': iri }) => iri === dietElement.dietId
      );
      const currentVariant = currentDiet?.variants?.find(
        ({ '@id': iri }) => iri === dietElement.variantId
      );

      const currentCalorific = currentVariant?.calories?.find(
        ({ '@id': iri }) => iri === dietElement.calorificId
      );

      const superVariantResignPrice = currentVariant?.superVariantBeta
        ? getSuperVariantResignPrice(
            currentVariant,
            currentCalorific,
            dietElement?.mealTypes
          )
        : {};

      const dietPriceKey = `${dietId}_${variantId}_${calorificId}`;
      const dietPrice = priceListByKey?.[dietPriceKey] ?? {};
      let pricePerDayByType = isSubscriptionPayment
        ? dietPrice.subscription
        : testMode
        ? dietPrice.test ?? 0
        : dietPrice.standard ?? 0;

      if (currentVariant?.superVariantBeta) {
        const pricePerDayByTypeMinusResignPrice =
          pricePerDayByType - superVariantResignPrice.price;

        pricePerDayByType =
          pricePerDayByTypeMinusResignPrice > superVariantResignPrice.minPrice
            ? pricePerDayByTypeMinusResignPrice
            : superVariantResignPrice.minPrice;
      }

      const past30DaysLowestPrice = dietPrice?.past30DaysLowestPrice ?? {};
      const past30DaysLowestPriceByType = isSubscriptionPayment
        ? past30DaysLowestPrice.subscription ?? 0
        : testMode
        ? past30DaysLowestPrice.test ?? 0
        : past30DaysLowestPrice.standard ?? 0;

      const dietLength = dietElement?.dietLength ?? 0;

      const ecoContainerPrice = dietElement?.useEcoContainers
        ? additionalPrices.ECO_CONTAINER.cost * currentVariant.mealTypes.length
        : 0;

      const priceFromDietOrCurrentVariant = currentVariant?.superVariantBeta
        ? dietElement?.mealTypes?.length || 0
        : currentVariant?.mealTypes?.length || 0;

      const containersPrices = additionalPrices?.CONTAINER?.cost
        ? additionalPrices?.CONTAINER?.cost * priceFromDietOrCurrentVariant
        : additionalPrices?.CONTAINER?.cost;

      const actualContainerPrices =
        (dietElement?.useEcoContainers
          ? ecoContainerPrice
          : containersPrices) || 0;

      const dayBeforeDiscount = pricePerDayByType + actualContainerPrices;

      const { price: dayAfterDiscount, discountParts } = getPriceAfterDiscount(
        {
          price: dayBeforeDiscount,
          dietLength,
        },
        isSubscriptionPayment
      );

      const mergedDiscountParts = Object.entries(discountParts).reduce(
        (acc, [key, value]) => {
          return {
            ...acc,
            [key]: (acc[key] ?? 0) + value,
          };
        },
        {
          ...acc.price.discountParts,
        }
      );

      const pricePerDay = {
        dietDayPrice: pricePerDayByType,
        dayBeforeDiscount,
        dayAfterDiscount,
        dietBeforeDiscount: pricePerDayByType,
        // addonsBeforeDiscount: 0,
        // deliveryBeforeDiscount: 1,
        optionPremium: dietElement?.optionChangeMenu
          ? additionalPrices.CHANGE_MENU.cost
          : null,
        optionEcoContainers: dietElement?.useEcoContainers
          ? ecoContainerPrice
          : null,
        optionContainers:
          additionalPrices?.CONTAINER?.cost !== null ? containersPrices : null,
      };

      const dietPrices = {
        beforeDiscount: pricePerDay.dayBeforeDiscount * dietLength,
        afterDiscount: pricePerDay.dayAfterDiscount * dietLength,
      };

      return {
        price: {
          beforeDiscount:
            acc.price.beforeDiscount +
            pricePerDay.dayBeforeDiscount * dietLength,
          afterDiscount:
            acc.price.afterDiscount + pricePerDay.dayAfterDiscount * dietLength,

          discountParts: mergedDiscountParts,
        },
        rowsPrices: [
          ...acc.rowsPrices,
          {
            dietPrices,
            pricePerDay,
            discountParts,
            priceDiscounts: sumValues(discountParts),
            past30DaysLowestPrice: { DIET: past30DaysLowestPriceByType },
          },
        ],
      };
    },
    {
      price: {
        beforeDiscount: 0,
        afterDiscount: 0,
        discountParts: {},
      },
      rowsPrices: [],
    }
  );
  return basketPrices;
};

const useBasketPrices = ({ dietElements }) => {
  const queryClient = useQueryClient();
  const { testMode } = useSelector(basketNewOrderSelectors.selectBasket);

  const basketPrices = getBasketPrices({ dietElements, testMode, queryClient });

  return basketPrices;
};

export default useBasketPrices;
