import type { ApiErrorMessages } from '@tsTypes/ApiErrorMessages';
import has from 'lodash/has';

type Violation = {
  propertyPath: string;
  message: string;
};
type TransformedApiErrors = ApiErrorMessages['apiFieldErrors'];

const isObjectWithViolationsArray = (
  error: unknown
): error is { violations: (Violation | object)[] } =>
  has(error, 'violations') && Array.isArray(error.violations);

export const transformApiErrors = (error: unknown): TransformedApiErrors => {
  if (isObjectWithViolationsArray(error)) {
    return error.violations.reduce<TransformedApiErrors>(
      (accumulator, obj) =>
        'propertyPath' in obj && 'message' in obj
          ? {
              ...accumulator,
              ...{ [obj.propertyPath]: obj.message },
            }
          : accumulator,
      {}
    );
  }

  return {};
};
