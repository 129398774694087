import { useQuery } from '@tanstack/react-query';
import type { Language } from '@tsTypes/api/Language';
import type { HydraCollection } from 'types/HydraCollection';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

export const getLanguages = async () => {
  const { data } = await axios.get<HydraCollection<Language>>(
    ENDPOINT.LANGUAGES,
    {
      withToken: false,
    }
  );

  return data['hydra:member'];
};

const useLanguages = (options: { enabled: boolean }) =>
  useQuery({ queryKey: ['languages'], queryFn: getLanguages, ...options });

export default useLanguages;
