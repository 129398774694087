const BASKET_ROWS_TYPES = {
  DIET_ITEM: 'BasketItemDiet',
  DISH_ITEM: 'BasketItemDish',
  ADDON_ITEM: 'BasketItemAddon',
  MEAL_TYPE_ITEM: 'BasketItemMealType',
  DAY_DELIVERY_ITEM: 'BasketItemDayDelivery',
  DAY_MODIFICATION: 'BasketItemDayModification',
};

export default BASKET_ROWS_TYPES;
