import { useQuery } from '@tanstack/react-query';
import type { OrderForm } from 'types/OrderForm';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

type Selector<T> = (data: OrderForm | Record<string, never>) => T;

export const getOrderForm = async () => {
  const { data } = await axios.get<OrderForm>(ENDPOINT.ORDER_FORM);

  return data;
};

const useOrderForm = (options = {}) => {
  return useQuery({
    queryKey: ['orderForm'],
    queryFn: getOrderForm,
    ...options,
  });
};

export const useOrderFormSelector = <T>(selector: Selector<T>): T => {
  const { data } = useOrderForm({ enabled: false });

  return selector(data || {});
};

export const selectOrderForm = (data: OrderForm) => data;
export const selectDiets = (data: OrderForm) => data.diets;

export default useOrderForm;
