import { addDays, eachDayOfInterval, getISODay } from 'date-fns';

import { format } from './Date.service';

const getDaysRangeByCountWithoutDisabled = (
  startDate = new Date(),
  countDays = 1,
  disabledDays = [],
  disabledDaysOfWeek = []
) => {
  const endDate = countDays > 1 ? addDays(startDate, countDays - 1) : startDate;

  let filteredDays = getDaysRangeWithoutDisabled(
    startDate,
    endDate,
    disabledDays,
    disabledDaysOfWeek
  );
  let filteredDaysLength = filteredDays.length;
  let diffLength = countDays - filteredDaysLength;
  let addDaysNextEndDate = countDays - 1 + diffLength;

  while (countDays > filteredDaysLength) {
    const nextEndDate = addDays(startDate, addDaysNextEndDate);

    filteredDays = getDaysRangeWithoutDisabled(
      startDate,
      nextEndDate,
      disabledDays,
      disabledDaysOfWeek
    );

    const nextFilteredDaysLength = filteredDays.length;
    const nextDiffLength = countDays - filteredDays.length;

    filteredDaysLength = nextFilteredDaysLength;
    diffLength = nextDiffLength;
    addDaysNextEndDate = addDaysNextEndDate + nextDiffLength;
  }

  return filteredDays;
};

const getDaysRangeWithoutDisabled = (
  startDate,
  endDate,
  disabledDays = [],
  disabledDaysOfWeek = []
) => {
  const rangeDays = eachDayOfInterval({
    start: startDate,
    end: endDate,
  });

  const filteredDays = rangeDays.filter(day => {
    const isoDay = getISODay(day);

    return (
      !disabledDays.includes(format(day)) &&
      !disabledDaysOfWeek.includes(isoDay)
    );
  });

  return filteredDays;
};

export default {
  getDaysRangeByCountWithoutDisabled,
  getDaysRangeWithoutDisabled,
};
