import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isNativeApp: false,
  disableTracking: false,
  useNativeBridge: false,
  isNativeBridgeDebugEnabled: false,
  disableThirdPartyLoginMethods: false,
};

const nativeAppConfigSlice = createSlice({
  name: 'nativeAppConfig',
  initialState,
  reducers: {
    setNativeAppConfig: (state, { payload }) => {
      state.isNativeApp = payload?.isNativeApp ?? state.isNativeApp;
      state.disableTracking = payload?.disableTracking ?? state.disableTracking;
      state.useNativeBridge = payload?.useNativeBridge ?? state.useNativeBridge;
      state.isNativeBridgeDebugEnabled =
        payload?.isNativeBridgeDebugEnabled ?? state.isNativeBridgeDebugEnabled;
      state.disableThirdPartyLoginMethods =
        payload?.disableThirdPartyLoginMethods ??
        state.disableThirdPartyLoginMethods;
    },
  },
});

export const selectNativeAppConfig = state => state.nativeAppConfig;
export const selectIsNativeApp = state => state.nativeAppConfig.isNativeApp;
export const selectDisableTracking = state =>
  state.nativeAppConfig.disableTracking;
export const selectUseNativeBridge = state =>
  state.nativeAppConfig.useNativeBridge;
export const selectIsNativeBridgeDebugEnabled = state =>
  state.nativeAppConfig.isNativeBridgeDebugEnabled;
export const selectDisableThirdPartyLoginMethods = state =>
  state.nativeAppConfig.disableThirdPartyLoginMethods;

export const { setNativeAppConfig } = nativeAppConfigSlice.actions;

export default nativeAppConfigSlice.reducer;
