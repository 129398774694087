import * as Yup from 'yup';

import INPUT_IDS from '@constants/inputs/userDataInputs';
import { useDefaultSchemaYup } from '@utils/yupMethods';

export const initialValues = {
  [INPUT_IDS.FIRST_NAME]: '',
  [INPUT_IDS.LAST_NAME]: '',
  [INPUT_IDS.EMAIL]: '',
  [INPUT_IDS.PHONE]: { number: '', countryCode: '' },
};

export const useValidationSchema = ({
  demandPhoneNumber,
  firstName,
  lastName,
  email,
}) => {
  const { stringRequired, phoneValid, emailRequired } = useDefaultSchemaYup();
  const validate = {};

  if (demandPhoneNumber) {
    validate[INPUT_IDS.PHONE] = phoneValid;
  }

  if (!firstName) {
    validate[INPUT_IDS.FIRST_NAME] = stringRequired;
  }

  if (!lastName) {
    validate[INPUT_IDS.LAST_NAME] = stringRequired;
  }

  if (!email) {
    validate[INPUT_IDS.EMAIL] = emailRequired;
  }

  return Yup.object(validate);
};
