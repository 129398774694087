import { withRouter } from 'next/router';
import { withTranslation } from 'next-i18next';

import { Component } from 'react';
import tw from 'twin.macro';

import ROUTE_URLS from '@constants/routeUrls';
import CustomErrorPage from '@views/ErrorPage/CustomErrorPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error) {
    // You can use your own error logging service here
    this.setState({ error });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI

      const containerProps = {
        ...(this.props.router.pathname.includes(ROUTE_URLS.NEW_ORDER_FORM)
          ? { css: tw`pt-8 pb-8 sm:pb-12 md:pb-16 lg:pb-24` }
          : {}),
      };

      return (
        <div {...containerProps}>
          <CustomErrorPage errorPayload={this.state.error?.stack ?? null} />
        </div>
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default withTranslation('common')(withRouter(ErrorBoundary));
