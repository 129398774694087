import { useTranslation } from 'next-i18next';

import type { ErrorObject } from 'serialize-error';

import ArrowsRotateIcon from '@assets/icons/ArrowsRotateIcon';
import HouseIcon from '@assets/icons/HouseIcon';

import Error404Page from './Error404Page';
import ErrorPage from './ErrorPage';

type Props = {
  errorPayload?: ErrorObject | null;
  statusCode?: number;
};

const CustomErrorPage = ({ statusCode, errorPayload }: Props) => {
  const { t } = useTranslation();
  const isServerError = statusCode !== undefined;

  if (statusCode === 404) {
    return <Error404Page />;
  }

  return (
    <ErrorPage
      isServerError={isServerError}
      pageName={
        isServerError
          ? t(
              '$*errorPage.serverError.pageName',
              'Wystąpił błąd po stronie serwera'
            )
          : t(
              '$*errorPage.clientError.pageName',
              'Wystąpił błąd po stronie klienta'
            )
      }
      renderTitle={() => (
        <h1 tw="text-4xl">
          {isServerError
            ? t(
                '$*errorPage.serverError.title',
                'Ups, wystąpił błąd po stronie serwera'
              )
            : t(
                '$*errorPage.clientError.title',
                'Ups, wystąpił błąd po stronie aplikacji'
              )}
        </h1>
      )}
      renderDescription={() =>
        isServerError ? (
          <p tw="text-xl">
            {t('$*errorPage.serverError.description', {
              defaultValue: '{{statusCode}} | Wystąpił błąd po stronie serwera',
              replace: { statusCode },
            })}
          </p>
        ) : (
          <div tw="mt-10">
            <div tw="flex">
              <div tw="mr-2">
                <ArrowsRotateIcon width="22" height="22" />
              </div>
              <p tw="text-left">
                {t(
                  '$*errorPage.clientError.description1',
                  'Spróbuj odświeżyć okno przeglądarki'
                )}
              </p>
            </div>
            <div tw="flex mt-2">
              <div tw="mr-2">
                <HouseIcon width="22" height="25" />
              </div>
              <p tw="text-left mt-0.5">
                {t(
                  '$*errorPage.clientError.description2',
                  'Jeśli nadal pojawia się problem, najlepszym wyjściem będzie powrót na stronę główną'
                )}
              </p>
            </div>
          </div>
        )
      }
      errorPayload={errorPayload}
    />
  );
};

export default CustomErrorPage;
