import isArray from 'lodash/isArray';

export const getFormattedDisabledDaysOfWeek = disabledDaysOfWeek => {
  if (!disabledDaysOfWeek || !isArray(disabledDaysOfWeek)) return [];

  const formattedArray = disabledDaysOfWeek.map(dayIndex => {
    if (dayIndex === 7) {
      return 0;
    }
    return dayIndex;
  });

  return [...new Set(formattedArray)] ?? [];
};
