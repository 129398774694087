import useAppConfig from '@hooks/useAppConfig';
import { pushEventToDataLayer } from '@utils/gtm';

import convertGtmItemIndexesByCategory from './utils/convertGtmItemIndexesByCategory';
import getCartValue from './utils/getCartValue';

const clearDataLayer = () => {
  pushEventToDataLayer({ ecommerce: null });
};

const usePushGtmCartEvents = () => {
  const {
    data: { multinational },
  } = useAppConfig();

  const currencyCode = multinational.defaultRegion.currencyCode;

  return ({ event, items, asSeparateEvents }) => {
    if (asSeparateEvents) {
      items.forEach(item => {
        clearDataLayer();

        pushEventToDataLayer({
          event,
          ecommerce: {
            currency: currencyCode,
            value: getCartValue([item]),
            items: convertGtmItemIndexesByCategory([item]),
          },
        });
      });
    } else {
      clearDataLayer();

      pushEventToDataLayer({
        event,
        ecommerce: {
          currency: currencyCode,
          value: getCartValue(items),
          items: convertGtmItemIndexesByCategory(items),
        },
      });
    }
  };
};

export default usePushGtmCartEvents;
