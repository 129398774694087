import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appVersion: '0.0.0',
  appConfig: {},
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setAppVersion: (state, { payload }) => {
      state.appVersion = payload;
    },
    setAppConfig: (state, { payload }) => {
      state.appConfig = payload;
    },
  },
});

export const selectAppVersion = state => state.config.appVersion;

export const { setAppVersion, setAppConfig } = configSlice.actions;

export default configSlice.reducer;
