const INPUT_IDS = {
  SHIPPING_ADDRESS_POST_CODE: 'addressPostCode',
  SHIPPING_ADDRESS_CITY: 'addressCity',
  SHIPPING_ADDRESS_STREET: 'addressStreet',
  SHIPPING_ADDRESS_BUILD_NUMBER: 'addressBuildNumber',
  SHIPPING_ADDRESS_PLACE_NUMBER: 'addressPlaceNumber',

  PAYPO_ADDRESS_POST_CODE: 'paypoAddressPostCode',
  PAYPO_ADDRESS_CITY: 'paypoAddressCity',
  PAYPO_ADDRESS_STREET: 'paypoAddressStreet',
  PAYPO_ADDRESS_BUILD_NUMBER: 'paypoAddressBuildNumber',
  PAYPO_ADDRESS_PLACE_NUMBER: 'paypoAddressPlaceNumber',

  ADDRESS_POST_CODE: 'postCode',
  ADDRESS_CITY: 'city',
  ADDRESS_STREET: 'street',
  ADDRESS_BUILD_NUMBER: 'buildNumber',
  ADDRESS_PLACE_NUMBER: 'placeNumber',
};

export default INPUT_IDS;
