import { useTranslation } from 'next-i18next';

import INPUT_IDS from '@constants/inputs/userDataInputs';

const useFields = ({
  phoneNumber,
  firstName,
  lastName,
  email,
  demandPhoneNumber,
}) => {
  const { t } = useTranslation();

  const fields = [];

  if (!firstName) {
    fields.push({
      id: INPUT_IDS.FIRST_NAME,
      type: 'text',
      label: t('$*input.firstName.label', 'Imię'),
      required: true,
      autoComplete: 'given-name',
    });
  }

  if (!lastName) {
    fields.push({
      id: INPUT_IDS.LAST_NAME,
      type: 'text',
      label: t('$*input.lastName.label', 'Nazwisko'),
      required: true,
      autoComplete: 'family-name',
    });
  }

  if (!phoneNumber) {
    fields.push({
      id: INPUT_IDS.PHONE,
      type: 'phone',
      label: t('$*input.phone.label', 'Telefon'),
      required: demandPhoneNumber,
      autoComplete: 'tel',
    });
  }

  if (!email) {
    fields.push({
      id: INPUT_IDS.EMAIL,
      type: 'email',
      label: t('$*input.email.label', 'Email'),
      required: true,
      autoComplete: 'email',
    });
  }

  return fields;
};

export default useFields;
