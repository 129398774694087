import { HYDRATE } from 'next-redux-wrapper';

import { createApi } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '@constants/endpoint';

import axiosBaseQuery from './axiosBaseQuery';

// initialize an empty api service that we'll inject endpoints into later as needed

const emptyApi = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL,
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  tagTypes: [],
  endpoints: () => ({}),
});

export default emptyApi;
