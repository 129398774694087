export const resetTagsReducer = state => {
  state.selectedTags = [];
};

export const toggleTagReducer = (state, { payload: tagIri }) => {
  const tagIndex = state.selectedTags.findIndex(tag => tag === tagIri);

  if (tagIndex !== -1) {
    state.selectedTags.splice(tagIndex, 1);
  } else {
    state.selectedTags.push(tagIri);
  }
};

export const resetMealtypeTagsReducer = (state, { payload: mealTypeIri }) => {
  state.selectedMealtypeTags[mealTypeIri] = [];
};

export const toggleMealtypeTagReducer = (
  state,
  { payload: { tagIri, mealTypeIri } }
) => {
  const mealTypeTags = state.selectedMealtypeTags[mealTypeIri];
  if (!mealTypeTags) {
    state.selectedMealtypeTags[mealTypeIri] = [];
  }

  const tagIndex = (mealTypeTags ?? []).findIndex(tag => tag === tagIri);

  if (tagIndex !== -1) {
    state.selectedMealtypeTags[mealTypeIri].splice(tagIndex, 1);
  } else {
    state.selectedMealtypeTags[mealTypeIri].push(tagIri);
  }
};

export const setDiet = (state, { payload: dietId }) => {
  state.diet = dietId;
};

export const setVariant = (state, { payload: variantId }) => {
  state.dietVaraint = variantId;
};
