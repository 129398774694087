import getConfig from 'next/config';
import { i18n } from 'next-i18next';

import { logout } from '@store/auth/auth.slice';
import { Mutex } from 'async-mutex';
import axios from 'axios';
import { getCookie, setCookie } from 'cookies-next';

import { ENDPOINT } from '@constants/endpoint';
import ROUTE_URLS from '@constants/routeUrls';
import TIME from '@constants/time';
import showToast from '@utils/showToast';

const { publicRuntimeConfig } = getConfig();
const hasXCKey = (publicRuntimeConfig.xcKey ??= false);

const handleError401Mutex = async (
  axiosQuery,
  args,
  api,
  extraOptions = {}
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  // checking whether the mutex is locked
  if (!mutex.isLocked()) {
    const release = await mutex.acquire();

    try {
      const refreshResult = await axiosQuery({
        url: ENDPOINT.REFRESH_LOGIN,
        method: 'PUT',
        data: {
          refreshToken: getCookie('refreshToken'),
        },
      });

      if (refreshResult.data) {
        // retry the initial query
        return await axiosQuery(args, api, {
          ...extraOptions,
          token: refreshResult.data.token,
        });
      } else {
        api.dispatch(logout());
      }
    } finally {
      // release must be called once the mutex should be released again.
      release();
    }
  } else {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    return await axiosQuery(args, api, extraOptions);
  }
};

const mutex = new Mutex();
const axiosBaseQuery = ({ baseUrl } = { baseUrl: '' }) => {
  let commonHeaders = {
    ...(hasXCKey
      ? { 'X-C-KEY': publicRuntimeConfig.xcKey }
      : {
          'X-DEV-FRONTEND-KEY': publicRuntimeConfig.devFrontendKey,
          'X-DEV-FRONTEND-ORIGIN': publicRuntimeConfig.devFrontendOrigin,
        }),
  };

  const axiosQuery = async (args, api, extraOptions) => {
    const { url, method, data, params, options = {} } = args;
    const mergedOptions = Object.assign({ withToken: true }, options);

    const token = getCookie('token') || null;
    const impersonateEmail = getCookie('impersonateEmail') || null;

    if (token && mergedOptions.withToken) {
      commonHeaders.Authorization = `Bearer ${token}`;
    }

    if (![undefined, 'undefined', null, 'null'].includes(impersonateEmail)) {
      commonHeaders['x-switch-user'] = impersonateEmail;
      commonHeaders.Authorization = `Bearer ${token}`;
    }

    const cookieLocale = getCookie('NEXT_LOCALE');
    let xLocale;

    if (mergedOptions.xLocale) {
      xLocale = mergedOptions.xLocale;
    } else if (cookieLocale) {
      xLocale = cookieLocale;
    } else {
      xLocale = i18n.language;
    }

    commonHeaders['X-LOCALE'] = xLocale;

    try {
      const response = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: { ...commonHeaders },
      });

      if (
        response.config.method === 'get' &&
        getCookie('appv') !== response.headers['x-cv']
      ) {
        setCookie('appv', response.headers['x-cv'], {
          maxAge: 2 * TIME.WEEK_IN_SECONDS,
        });
      }

      return { data: response.data };
    } catch (axiosError) {
      let error = axiosError;

      const { data, status } = error.response;
      const isError401 =
        status === 401 &&
        !window.location.href.includes(ROUTE_URLS.AUTH_LOGIN) &&
        ![ENDPOINT.REFRESH_LOGIN, ENDPOINT.LOGOUT, ENDPOINT.LOGIN].includes(
          error?.config.url
        );

      let errorObject = {
        error: {
          status: error.response?.status,
          data: error.response?.data || error.message,
        },
      };

      if (mergedOptions?.errorHandle === false) {
        if (isError401) {
          return await handleError401Mutex(axiosQuery, args, api, extraOptions);
        }

        return errorObject;
      }

      const collectErrorMessages400 = () => {
        const { 'hydra:description': description = '', violations = [] } = data;
        const allErrorMessages =
          violations.length > 0
            ? violations.reduce((acc, { message, propertyPath }) => {
                return [...acc, `${[propertyPath]}: ${message}`];
              }, []) || []
            : [description];
        const filteredAllErrorMessages = allErrorMessages.filter(Boolean);

        return ['Client: Bad Request', ...filteredAllErrorMessages];
      };

      const collectErrorMessages404 = () => {
        const message = data.detail
          ? `${data.detail}: ${data.title}`
          : `${data['hydra:description']}: ${data['hydra:title']}`;

        return message;
      };

      const toastMessages = {
        400: collectErrorMessages400(),
        403: `Authorization: ${data['hydra:description']}`,
        404: collectErrorMessages404(),
        422: collectErrorMessages400(),
        500: 'Server: Internal Server Error',
        undefined: 'Undefined Error',
      };

      const toastMessage = toastMessages[status] || toastMessages.undefined;

      if ([400, 403, 404, 500].includes(status)) {
        if (Array.isArray(toastMessage)) {
          toastMessage.map(message =>
            showToast(message, { toastId: `error${status}` })
          );
        } else {
          showToast(toastMessage, { toastId: `error${status}` });
        }
      } else if ([402, 409].includes(status)) {
        return errorObject;
      } else if (isError401) {
        return await handleError401Mutex(axiosQuery, args, api, extraOptions);
      } else {
        showToast(toastMessages.undefined, { toastId: 'errorUndefined' });
      }

      return errorObject;
    }
  };

  return axiosQuery;
};

export default axiosBaseQuery;
