import { useRouter } from 'next/router';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { getUserData, updateUserData } from '@store/auth/auth.actions';
import { logout } from '@store/auth/auth.slice';
import {
  selectIsAuthenticated,
  selectLastAction,
  selectUser,
} from '@store/auth/auth.slice';
import {
  actions as basketEditOrderActions,
  selectors as basketEditOrderSelectors,
} from '@store/basket/basketEditOrder.slice';
import {
  actions as basketNewOrderActions,
  selectors as basketNewOrderSelectors,
} from '@store/basket/basketNewOrder.slice';
import { selectAppVersion } from '@store/config/config.slice';
import { resetMyDiet } from '@store/myDiet/myDiet.slice';
import { selectDisableTracking } from '@store/nativeAppConfig/nativeAppConfig.slice';
import { resetOrderForm } from '@store/orderForm/orderForm.slice';
import { useQueryClient } from '@tanstack/react-query';
import { getCookie } from 'cookies-next';
import isEmpty from 'lodash/isEmpty';

import ROUTE_URLS from '@constants/routeUrls';
import { changeStepAndResetValid } from '@features/orderTabs/orderTabsSlice';
import useStaticBasketStore from '@hooks/basket/useStaticBasketStore';
import {
  selectModulePollsterTrack,
  useAppConfigSelector,
  useAppMode,
} from '@hooks/useAppConfigSelectors';
import useCreateBasket from '@hooks/useCreateBasket';
import useLocale from '@hooks/useLocale';
import { checkRouteIsProteced } from '@services/App.service';
import { pushPageViewGTMEvent } from '@utils/gtm';
import { triggerDeclarationEvent } from '@utils/gtmTs';
import { pushAuthenticationPollsterEvent } from '@utils/pollsterTrack';

let isFirstRender = true;

const AppListener = ({ basketKey = 'basketNewOrder' }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { isDiet, isOnlyShop } = useAppMode();

  const user = useSelector(selectUser);
  const lastAction = useSelector(selectLastAction);

  const appVersion = useSelector(selectAppVersion);
  const prevAppVersion = usePrevious(appVersion);

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const prevIsAuthenticated = usePrevious(isAuthenticated);

  const basketNewOrderStore = useSelector(basketNewOrderSelectors.selectBasket);
  const basketEditOrderStore = useSelector(
    basketEditOrderSelectors.selectBasket
  );
  const disableTracking = useSelector(selectDisableTracking);
  const { GID: pollsterTrackGID } = useAppConfigSelector(
    selectModulePollsterTrack
  );

  const { mutate: createBasketNewOrder } = useCreateBasket({
    basketKey,
  });

  const [getStaticBasketStore] = useStaticBasketStore();
  const noApiBasketStore = getStaticBasketStore();

  const { defaultLocale } = useLocale();

  const resetAppBasketNewOrder = () => {
    queryClient.setQueryData(['basketNewOrder'], {});

    dispatch(resetOrderForm());

    if (isOnlyShop) {
      dispatch(basketNewOrderActions.resetBasket());
      createBasketNewOrder();
    }

    dispatch(changeStepAndResetValid(0));
  };

  const resetAppBasketEditOrder = () => {
    queryClient.setQueryData(['basketEditOrder'], {});
    dispatch(basketEditOrderActions.resetBasket());
  };

  const resetReactQueryToInitialRequired = () => {
    const appConfig = queryClient.getQueryData(['appConfig']);
    const orderForm = queryClient.getQueryData(['orderForm']);
    const languages = queryClient.getQueryData(['languages']);
    let dietPage;

    // Keep diet CMS page data too, for Fitness cusomers coming through CMS after deploy
    if (router.pathname === '/diets/[slug]') {
      dietPage = queryClient.getQueryData(['dietPage', router?.query?.slug]);
    }

    queryClient.clear();
    queryClient.setQueryData(['appConfig'], appConfig);
    queryClient.setQueryData(['orderForm'], orderForm);
    queryClient.setQueryData(['languages'], languages);
    if (router.pathname === '/diets/[slug]') {
      queryClient.setQueryData(['dietPage', router?.query?.slug], dietPage);
    }
  };

  const resetAppToInititalRequired = () => {
    if (isDiet) {
      // TODO: remove basketId from url + abandon basket before logout
      dispatch(basketNewOrderActions.setStoreFromQuery(noApiBasketStore));
    } else {
      dispatch(basketNewOrderActions.resetBasket());
    }

    dispatch(basketEditOrderActions.resetBasket());
    dispatch(resetOrderForm());
    dispatch(resetMyDiet());

    // Basket is being used also on diets CMS page, create is needed ther too
    if (router.asPath.includes(ROUTE_URLS.NEW_ORDER_FORM)) {
      isOnlyShop && createBasketNewOrder();
      dispatch(changeStepAndResetValid(0));
    }
  };

  useEffect(() => {
    if (
      !isEmpty(appVersion) &&
      !isEmpty(prevAppVersion) &&
      prevAppVersion !== '0.0.0' &&
      appVersion !== prevAppVersion
    ) {
      dispatch({ type: 'app/resetToInitialWithToast' });
    }
  }, [appVersion]);

  useEffect(() => {
    if (lastAction === 'auth/refreshToken/fulfilled') {
      queryClient.invalidateQueries();
    }

    if (lastAction === 'app/resetToInitial') {
      resetReactQueryToInitialRequired();
      resetAppToInititalRequired();
    }

    if (lastAction === 'app/resetToInitialWithToast') {
      resetReactQueryToInitialRequired();
      resetAppToInititalRequired();
    }
  }, [lastAction]);

  useEffect(() => {
    // reset store if isPayed & external & noRedirecting or break redirect and stay on new-order
    if (
      (basketNewOrderStore.isPayActionSuccess &&
        basketNewOrderStore.isExternalPayment &&
        !basketNewOrderStore.isRedirectingToConfirm) ||
      (basketNewOrderStore.isRedirectingToConfirm &&
        router.pathname === ROUTE_URLS.NEW_ORDER_FORM)
    ) {
      resetAppBasketNewOrder();
    }

    if (
      basketEditOrderStore.isPayActionSuccess &&
      basketEditOrderStore.isExternalPayment
    ) {
      resetAppBasketEditOrder();
    }

    if (isAuthenticated && router.pathname !== ROUTE_URLS.LOGIN_AS_USER) {
      dispatch(getUserData());
    }
  }, []);

  useEffect(() => {
    if (!disableTracking) {
      const pathName = router?.asPath;

      pushPageViewGTMEvent(pathName);

      if (!isFirstRender) {
        triggerDeclarationEvent();
      } else {
        isFirstRender = false;
      }
    }

    // To cover case when we lose local storage user data, and token is still available in cookies, for now we log user out. It gonna happen only when such case is detected on protected route.
    const userToken = getCookie('token');

    if (!isAuthenticated && userToken) {
      dispatch(logout());

      const routeIsProtected = checkRouteIsProteced(router.route);

      if (routeIsProtected) {
        resetReactQueryToInitialRequired();
        resetAppToInititalRequired();

        router.push(ROUTE_URLS.AUTH_LOGIN);
      }
    }
  }, [router.pathname]);

  useEffect(() => {
    if (
      basketNewOrderStore.isPayActionSuccess &&
      !basketNewOrderStore.isExternalPayment &&
      !basketNewOrderStore.isRedirectingToConfirm
    ) {
      resetAppBasketNewOrder();
    }

    if (
      basketEditOrderStore.isPayActionSuccess &&
      !basketEditOrderStore.isExternalPayment
    ) {
      resetAppBasketEditOrder();
    }
  }, [
    basketNewOrderStore.isPayActionSuccess,
    basketNewOrderStore.isRedirectingToConfirm,
    basketEditOrderStore.isPayActionSuccess,
  ]);

  // on logout clear RQ && RTK
  useEffect(() => {
    if (isAuthenticated && user && !disableTracking) {
      pushAuthenticationPollsterEvent({
        gid: pollsterTrackGID,
        userId: user?.id,
      });
    }

    if (prevIsAuthenticated === true && isAuthenticated === false) {
      const routeIsProtected = checkRouteIsProteced(router.route);
      routeIsProtected && router.push(ROUTE_URLS.AUTH_LOGIN);

      resetReactQueryToInitialRequired();
      resetAppToInititalRequired();
    }

    // on after login
    if (prevIsAuthenticated === false && isAuthenticated === true) {
      const cookieNextLocale = getCookie('NEXT_LOCALE');
      const locale =
        cookieNextLocale !== undefined
          ? cookieNextLocale ?? defaultLocale
          : defaultLocale;

      dispatch(updateUserData({ language: locale }));
    }
  }, [isAuthenticated]);

  return null;
};

export default AppListener;
