import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
export const BASE_URL = publicRuntimeConfig.apiUrl;

export const ENDPOINT = {
  // BRANDS
  HOME_PAGE: '/v2/frontend/home-page',
  CONFIG: '/v2/frontend/configuration',
  COUNTRY_INFORMATIONS: '/frontend/countries',

  // AUTH
  LOGIN: '/login_check',
  FACEBOOK_LOGIN: '/frontend/auth/facebook',
  GOOGLE_LOGIN: '/frontend/auth/google',
  LOGOUT: '/logout',
  REFRESH_LOGIN: '/refresh_token',
  REGISTER: '/frontend/registration/form',
  CARDS: '/frontend/secure/cards',
  PAYMENT_CARDS: '/frontend/secure/payment-cards',

  // ORDER FORM
  PAYMENT_TYPES: '/frontend/payment-types',
  PAYU_PAYMENT_METHODS: '/v2/frontend/payu/payment-methods',
  DELIVERY_PICKUP_POINTS: '/frontend/pick-up-points',
  DELIVERY_ADDRESSES: '/frontend/secure/addresses',
  DELIVERY_HOURS: '/frontend/delivery-hours',
  ORDER_COST: '/frontend/order-cost',
  ADDONS: '/frontend/addons',
  VALIDATE_DISCOUNT_CODE: '/frontend/valid-discount-code',
  CREATE_ORDER: '/frontend/secure/order-create',
  PAYPO_LAST_ADDRESS: '/frontend/secure/paypo-last-used-address',

  // ORDERS
  ORDERS: '/frontend/secure/orders',
  ORDER_CANCEL: id => `/frontend/secure/orders/${id}/cancel`,
  ORDER_DETAILS: id => `/v2/frontend/secure/orders/${id}/details`,
  ORDER_LINKS: id => `/v2/frontend/secure/orders/${id}/calendar-links`,
  ORDER_PAYMENT_TYPES: id => `/frontend/secure/orders/${id}/payment-types`,
  ORDER_PAYMENT: id => `/frontend/secure/orders/${id}/payment`,
  ORDER_STATUS: id => `/v2/frontend/secure/orders/${id}`,
  MY_ORDERS: '/frontend/secure/my-orders',
  CANCEL_BAGS: id => `/v2/frontend/secure/calendar/${id}/cancel-bags
  `,

  // ACCOUNT SETTINGS
  CREATE_ADDRESS: '/frontend/secure/addresses/create',
  ADDRESS: id => `/frontend/secure/addresses/${id}`,
  USER_DATA: '/frontend/secure/my-data', // GET & PUT
  CHANGE_PASSWORD: '/frontend/secure/change-password',
  DELETE_ACCOUNT: '/frontend/secure/remove-account',
  GET_CLIENT_METRICS: '/frontend/secure/metrics',
  CREATE_CLIENT_METRICS: '/frontend/secure/metrics/create',

  // MONEYBOX
  MONEY_BOX_HISTORY: '/frontend/secure/my-money-box-histories',

  //MENU
  MENU_SUMMARY: '/v2/frontend/menu/summary',
  MENU_DIET: ({ date, dietId, variantId, calorificId }) =>
    `/v2/frontend/menu/diet/${date}/${dietId}/${variantId}/${calorificId}`,

  // DISH
  DISH: dishId => `/v2/frontend/dishes/${dishId}`,
  DISH_INGREDIENTS: '/v2/frontend/ingredients_by_dish_sizes/list',
  MENU: '/v2/frontend/menu',
  MENU_DISHES: '/v2/frontend/menu/dishes',

  // USER DIET
  USER_DIETS_LIST: '/frontend/secure/my-diets',
  GET_CALENDAR: ({ dietId, dateFrom, dateTo }) =>
    `/frontend/secure/calendar/${dietId}/${dateFrom}/${dateTo}`,
  EDIT_DIET: dietId => `/frontend/secure/ecommerce-diets/${dietId}`,
  EDIT_DIET_V2: id => `/v2/frontend/secure/calendar/${id}`,
  BAG_DETAILS: ({ id, date }) =>
    `/v2/frontend/secure/calendar/${id}/days/${date}`,
  DIET_DELIVERY_DAYS: ({ id }) =>
    `/v2/frontend/secure/calendar/${id}/delivery-dates`,
  BAG_ITEMS: ({ id, date }) =>
    `/v2/frontend/secure/calendar/${id}/days/${date}/items`,
  BAG_RATE: ({ dietId, date }) =>
    `/v2/frontend/secure/calendar/${dietId}/days/${date}/rate`,

  //SUBSCRIPTION MANAGEMENT
  USER_SUBSCRIPTION: ({ id }) => `/frontend/secure/subscriptions/${id}`,

  // MENU PLANNING
  CHANGE_MENU_OPTIONS_OLD: '/frontend/secure/change-menu-options',
  GET_BAG: id => `/frontend/secure/bags/${id}`,
  GET_INTENT_BAG: ({ dietId, date }) =>
    `/frontend/secure/ecommerce-diets/${dietId}/delivery-days/${date}`,
  CHANGE_MENU: ({ dietId, date }) =>
    `/v2/frontend/secure/calendar/${dietId}/days/${date}/change-menu`,
  RATE_MENU: id => `/frontend/secure/bags/${id}/rate`,
  CHANGE_BAG: id => `/frontend/secure/bags/${id}/change`,
  CHANGE_NEEDS_PAY: id => `/frontend/secure/bags/${id}/change-need-pay`,
  CHANGE_MENU_OPTIONS: id => `/frontend/secure/bags/${id}/change-menu-options`,
  ORDER_ADDONS: '/frontend/secure/order-addons',
  SUBSCRIPTION_CONFIG: id => `/frontend/secure/subscription/${id}`,
  CHANGE_SUBSCRIPTION_INTENT: dietId =>
    `/frontend/secure/ecommerce-diets/${dietId}/delivery-days`,

  // PASSWORD REMINDING
  SEND_PASSWORD_RESET_EMAIL: '/frontend/lost-password',
  RESET_PASSWORD: '/frontend/change-password',

  // MEDIA
  UPLOADED_MEDIA: '/uploaded-media/',

  // CMS
  PAGES: '/frontend/pages',
  BLOG_POSTS: '/frontend/blog',
  BLOG_POST: id => `/frontend/blog/${id}/blog-post`,

  // basket
  BASKET_ADD_DIET: '/v2/frontend/basket/add/diet',
  BASKET_DUPLICATE_DIET: '/v2/frontend/basket/add/diet-duplicate',
  BASKET_ADD_ADDON: '/v2/frontend/basket/add/addon',
  BASKET_ADD_DISH: '/v2/frontend/basket/add/dish',
  BASKET_ADD_MODIFY_DAY: '/v2/frontend/basket/add/modify-day',
  BASKET_ADD_MEAL_TYPE: '/v2/frontend/basket/add/meal-type',
  BASKET_ADD_MEAL_TYPE_RANGE: '/v2/frontend/basket/add/meal-type-range',
  BASKET_CREATE: '/v2/frontend/basket/create',
  BASKET: id => `/v2/frontend/baskets/${id}`, // GET
  BASKET_CREATE_ORDER: id => `/v2/frontend/baskets/${id}/create-order`,
  BASKET_MODIFY: id => `/v2/frontend/baskets/${id}/modify`,
  BASKET_CLEAN: id => `/v2/frontend/baskets/${id}/cleanup`,
  BASKET_CLEAR: id => `/v2/frontend/baskets/${id}/clear`,
  BASKET_VALIDATE: id => `/v2/frontend/baskets/${id}/validate`,
  BASKET_CHANGE_MENU: id => `/v2/frontend/baskets/${id}/select-menu`,

  // dishProduct
  DISH_PRODUCTS: '/v2/frontend/products/dishes',
  DISHES_MEAL_TYPES: 'v2/frontend/products/dishes/meal-types',
  DISH_TAGS: '/v2/frontend/products/dishes/tags',
  PRODUCT_RECIPE_PAGE: id => `/v2/frontend/products/dishes/${id}/recipe-page`,
  PRODUCT_DISH_PAGE: id => `/v2/frontend/products/dishes/${id}/product-page`,
  GET_DISH_PDF: id => `/v2/frontend/products/dishes/${id}/pdf`,

  //dishes
  DISHES: '/v2/frontend/dishes',

  // otherPages
  DIET_PAGE: slug => `/v2/frontend/diets/${slug}/diet-page`,
  ZONE_PAGE: id => `/v2/frontend/zones/${id}/zone-page`,
  ZONES_LIST_PAGE: '/v2/frontend/zones',
  ADDON_PAGE: id => `/v2/frontend/addons/${id}/addon-page`,

  // orderForm
  ORDER_FORM: '/v2/frontend/order-form',

  // Waiting lists
  ADDRESS_REQUEST: '/frontend/address-requests',

  // Address Form
  ALLOWED_CITIES: '/frontend/zone-post-codes/cities',
  ALLOWED_STREETS: '/frontend/zone-post-codes/cities/streets',
  ALLOWED_BUILDING_NUMBERS:
    '/frontend/zone-post-codes/cities/streets/buildings',

  // TRANSLATIONS
  LANGUAGES: '/v2/frontend/languages',
  TRANSLATIONS: '/v2/frontend/translations/frontend/default',

  // ANTIPREFERENCES
  ALLERGEN_ANTIPREFERENCES: '/frontend/secure/anti-preferences/allergens',
  INGREDIENT_ANTIPREFERENCES:
    '/frontend/secure/anti-preferences/ingredient-categories',
  TAG_ANTIPREFERENCES: '/frontend/secure/anti-preferences/client-tags',
  MEAL_TYPES: '/frontend/v2/meal-types?pagination=false&order[position]=ASC',
  ANTIPREFERENCES: '/frontend/secure/anti-preferences',

  IPRESSO_MONITORING: '/ipresso/monitoring-code',
};
