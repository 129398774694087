import { createSlice } from '@reduxjs/toolkit';

import BASKET_INITIAL_STATE from '@constants/basketInitialState';

import { reducers } from './basket.common';

const basketEditOrderSlice = createSlice({
  name: 'basketEditOrder',
  initialState: BASKET_INITIAL_STATE,
  reducers,
});

export const selectBasket = state => state.basketEditOrder;
export const selectCurrentDiet = ({ basketEditOrder }) => {
  return (
    basketEditOrder.items.dietElements?.find(
      row => row['@id'] === basketEditOrder.activeOrderDietIri
    ) ?? {}
  );
};

export const selectActiveOrderDietIri = ({ basketEditOrder }) =>
  basketEditOrder.activeOrderDietIri;

export const selectBasketId = ({ basketEditOrder }) => basketEditOrder.basketId;

export const selectors = {
  selectBasket,
  selectCurrentDiet,
  selectActiveOrderDietIri,
  selectBasketId,
};

export const {
  addDietToBasket,
  addItemToBasket,
  removeDiet,
  resetBasket,
  setBasketId,
  setBasketValidity,
  setBlikCode,
  setDiscountCode,
  setInvoice,
  setIsValid,
  setPaymentMethod,
  setPayStatus,
  setStatusBasket,
  setUseMoneyBox,
  toggleBasketDay,
  toggleBasketGroup,
} = basketEditOrderSlice.actions;

export const actions = basketEditOrderSlice.actions;

export default basketEditOrderSlice.reducer;
