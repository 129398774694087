import { useRouter } from 'next/router';

import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import first from 'lodash/first';
import isObject from 'lodash/isObject';
import orderBy from 'lodash/orderBy';

import BASKET_INITIAL_STATE from '@constants/basketInitialState';
import BASKET_MODES from '@constants/basketModes';
import useOrderForm from '@hooks/useOrderForm';
import { getIDfromIRI } from '@services/Api.service';

import useDefaultDietElement from './useDefaultDietElement';

const useStaticBasketStore = () => {
  const defaultDietElement = useDefaultDietElement();
  const {
    data: { diets = [], packages, packageLineFilters },
  } = useOrderForm();

  const {
    query: { diet: routerDietId },
  } = useRouter();

  const getStaticBasketStore = useCallback(
    initParams => {
      const initialDietElement = {
        dietIri: null,
        variantIri: null,
        calorificIri: null,
        mealTypes: [],
      };

      const paramDietIri = initParams?.diet
        ? `/diets/${initParams.diet}`
        : null;
      const paramDiet = diets.find(item => item['@id'] === paramDietIri);

      const routerDietIri = routerDietId ? `/diets/${routerDietId}` : null;
      const routerDiet = diets.find(item => item['@id'] === routerDietIri);

      if (initParams?.diet !== undefined && paramDiet) {
        initialDietElement.dietIri = paramDietIri;

        const paramVariantIri = initParams?.variant
          ? `/variants/${initParams.variant}`
          : null;
        const paramVariant = paramDiet.variants.find(
          item => item['@id'] === paramVariantIri
        );

        initialDietElement.mealTypes = paramVariant.mealTypes;
        if (initParams?.variant !== undefined && paramVariant) {
          initialDietElement.variantIri = paramVariantIri;

          const paramCalorificIri = initParams?.calorific
            ? `/calorifics/${initParams.calorific}`
            : null;
          const paramCalorific = paramVariant.calories.find(
            item => item['@id'] === paramCalorificIri
          );

          if (initParams?.calorific !== undefined && paramCalorific) {
            initialDietElement.calorificIri = paramCalorificIri;
          } else {
            const sortedCalorifics = orderBy(
              paramVariant.calories,
              ['position', 'name'],
              ['asc', 'desc']
            );
            const firstCalorific = first(sortedCalorifics);

            initialDietElement.calorificIri = firstCalorific['@id'];
          }
        } else {
          const sortedVariants = orderBy(
            paramDiet.variants,
            ['position', 'name'],
            ['asc', 'desc']
          );
          const firstVariant = first(sortedVariants);

          const sortedCalorifics = orderBy(
            firstVariant.calories,
            ['position', 'name'],
            ['asc', 'desc']
          );
          const firstCalorific = first(sortedCalorifics);

          initialDietElement.variantIri = firstVariant['@id'];
          initialDietElement.calorificIri = firstCalorific['@id'];
          initialDietElement.mealTypes = firstVariant.mealTypes;
        }
      } else {
        initialDietElement.dietIri = defaultDietElement.dietId;
        initialDietElement.variantIri = defaultDietElement.variantId;
        initialDietElement.calorificIri = defaultDietElement.calorificId;
        initialDietElement.mealTypes = defaultDietElement.mealTypes;
      }

      const activePackage =
        packages.find(({ activeDietsIds }) =>
          activeDietsIds.includes(getIDfromIRI(initialDietElement.dietIri))
        )?.['@id'] ?? null;

      const dietLineFilter = packageLineFilters?.find(filter => {
        return filter.activePackagesIds?.includes(
          parseInt(activePackage?.split('/').pop())
        );
      });

      const dietElement = {
        ...defaultDietElement,
        ...{
          '@id': '/v2/frontend/basket-item-diets/static-frontent-basket',
          '@type': 'BasketItemDiet',
          packageId: activePackage,
          packageLineFilters: dietLineFilter,
          dietId: initialDietElement.dietIri,
          ...(initialDietElement?.mealTypes
            ? {
                mealTypes: initialDietElement?.mealTypes?.map(el => {
                  if (isObject(el)) {
                    return el['@id'];
                  }
                  return el;
                }),
              }
            : []),
          variantId: initialDietElement.variantIri,
          calorificId: initialDietElement.calorificIri,
          useEcoContainers:
            initParams?.useEcoContainers ?? defaultDietElement.useEcoContainers,
          optionChangeMenu:
            initParams?.optionChangeMenu ?? defaultDietElement.optionChangeMenu,
          ...(!isEmpty(initParams) && {
            hasDietsToSelectMenu: diets.find(diet => {
              return routerDietId
                ? diet.id === parseInt(routerDietId)
                : diet.id ===
                    parseInt(defaultDietElement.dietId.split('/').pop());
            })?.hasDietsToSelectMenu,
          }),
        },
      };

      if (typeof routerDiet?.allowStepWithAdditionalMealTypes === 'boolean') {
        dietElement.allowStepWithAdditionalMealTypes =
          routerDiet?.allowStepWithAdditionalMealTypes;
      }

      const basketStore = Object.assign({}, BASKET_INITIAL_STATE, {
        basketMode: BASKET_MODES.STATIC,
        activeOrderDietIri:
          '/v2/frontend/basket-item-diets/static-frontent-basket',
        items: {
          dietElements: [dietElement],
        },
      });

      return basketStore;
    },
    [packages]
  );

  return [getStaticBasketStore];
};

export default useStaticBasketStore;
