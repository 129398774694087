import { createAsyncThunk } from '@reduxjs/toolkit';
import type { Client } from '@tsTypes/Client';
import type { CommonLoginPayload } from '@tsTypes/login';
import type { CommonRegisterPayload } from '@tsTypes/register';
import { isAxiosError } from 'axios';
import { setCookie } from 'cookies-next';
import decode from 'jwt-decode';

import TIME from '@constants/time';
import UserService from '@services/User.service';
import UserServiceTS from '@services/User.serviceTS';

type ImpersonatedRequestPayload = {
  token: string;
  refreshToken: string;
  email: string;
};

export const impersonateLoginWithUserData = createAsyncThunk(
  'auth/impersonateLoginWithUserData',
  async (requestPayload: ImpersonatedRequestPayload, { rejectWithValue }) => {
    try {
      setCookie('refreshToken', requestPayload.refreshToken, {
        maxAge: TIME.MONTH_IN_SECONDS,
      });
      setCookie('token', requestPayload.token, {
        maxAge: TIME.MONTH_IN_SECONDS,
      });
      setCookie('impersonateEmail', requestPayload.email, {
        maxAge: TIME.MONTH_IN_SECONDS,
      });
      setCookie('keepMeLoggedIn', false, { maxAge: TIME.MONTH_IN_SECONDS });

      const user = decode(requestPayload.token || '');
      const userData = await UserServiceTS.getData({
        headers: { Authorization: `Bearer ${requestPayload.token}` },
      });

      return { user, userData, email: requestPayload.email };
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      } else {
        return error;
      }
    }
  }
);

type LoginPayload = CommonLoginPayload & {
  pagePath?: string;
};

export const loginWithUserData = createAsyncThunk<
  { user: Awaited<ReturnType<typeof UserServiceTS.login>>; userData: Client },
  LoginPayload
>('auth/loginWithUserData', async (requestPayload, { rejectWithValue }) => {
  try {
    const user = await UserServiceTS.login(requestPayload);
    const userData = await UserServiceTS.getData({
      headers: { Authorization: `Bearer ${user.token}` },
    });

    return { user, userData };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const register = createAsyncThunk<Client, CommonRegisterPayload>(
  'auth/register',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await UserServiceTS.register(requestPayload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { rejectWithValue }) => {
    try {
      return await UserService.refreshToken();
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      } else {
        return error;
      }
    }
  }
);

export const getUserData = createAsyncThunk(
  'auth/getUserData',
  async (_, { rejectWithValue }) => {
    try {
      return await UserServiceTS.getData();
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      } else {
        return error;
      }
    }
  }
);

export const updateUserData = createAsyncThunk(
  'auth/updateUserData',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await UserService.updateData(requestPayload);
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      } else {
        return error;
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await UserService.changePassword(requestPayload);
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      } else {
        return error;
      }
    }
  }
);
