import { useQuery, useQueryClient } from '@tanstack/react-query';

const useBasket = ({ basketKey, options = {} } = {}) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [basketKey],
    queryFn: () => {
      return queryClient.getQueryData([basketKey]) ?? {};
    },
    ...options,
  });

  return query;
};

export default useBasket;
