import { useTranslation } from 'next-i18next';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';

import { ENDPOINT } from '@constants/endpoint';
import BasketService from '@services/Basket.service';
import axios from '@utils/axiosInstance';
import showToast from '@utils/showToast';

const basketClear = async ({
  iri = null,
  id,
  payload = { onlyDays: [] },
  disableToast = false,
}) => {
  const url = isEmpty(iri) ? ENDPOINT.BASKET_CLEAR(id) : iri;
  const response = await axios.put(url, payload);
  const data = response?.data ?? {};
  const uiRows = BasketService.transformResponseBasketUI(data);

  return { ...data, uiRows, disableToast };
};

const useBasketClear = ({ basketKey, options = {} } = {}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: basketClear,
    mutationKey: [`${basketKey}Clear`],
    onSuccess: response => {
      const { disableToast, ...restResponse } = response ?? {};

      queryClient.setQueryData([basketKey], () => restResponse);

      if (!disableToast) {
        showToast(
          t(
            '$*notification.basketClear.clear.success',
            'Koszyk został poprawnie wyczyszczony.'
          ),
          { type: 'success' }
        );
      }

      options?.onSuccess();
    },
  });

  return query;
};

export default useBasketClear;
