import { createAsyncThunk } from '@reduxjs/toolkit';

import DietService from '@services/Diet.service';

export const updateDiet = createAsyncThunk(
  'myDiet/updateDiet',
  async (requestPayload, { rejectWithValue }) => {
    try {
      const response = await DietService.updateDiet(requestPayload);

      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
