import { addDays, isAfter, isBefore, isDate, subMinutes } from 'date-fns';
import { format as fnsFormat } from 'date-fns';
import { enUS } from 'date-fns/locale';

import {
  selectDietWeekDays,
  selectDisabledDays,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import { selectOrderForm, useOrderFormSelector } from '@hooks/useOrderForm';
import { format } from '@services/Date.service';
import { isFalsify } from '@utils/helpers';

const useDietTimeSlots = () => {
  const orderFormData = useOrderFormSelector(selectOrderForm);
  const dietWeekDays = useAppConfigSelector(selectDietWeekDays);
  const appConfigDisabledDays = useAppConfigSelector(selectDisabledDays);

  const selectFirstPossibleOrderDayByWeekDays = weekDays => {
    let startDay = new Date();
    let firstEnabledDay = null;

    while (!isDate(firstEnabledDay)) {
      const day = fnsFormat(startDay, 'EEEE', { locale: enUS });
      const newOrderDay = weekDays?.[day.toLowerCase()]?.newOrder;
      const orderDayDeadline = subMinutes(
        new Date(`${format(startDay)} 00:00:00`),
        newOrderDay
      );

      if (isAfter(orderDayDeadline, new Date())) {
        firstEnabledDay = startDay;
      }

      startDay = addDays(startDay, 1);
    }

    return firstEnabledDay;
  };

  const getNearestWeekDisabledDays = weekDays => {
    const startDay = new Date();
    const disabledDays = [];

    // iterate one week
    for (let i = 0; i < 8; i++) {
      const nextDay = addDays(startDay, i);

      const day = fnsFormat(nextDay, 'EEEE', { locale: enUS });
      const newOrderDay = weekDays?.[day.toLowerCase()]?.newOrder;
      const orderDayDeadline = subMinutes(
        new Date(`${format(nextDay)} 00:00:00`),
        newOrderDay
      );

      if (isBefore(orderDayDeadline, new Date())) {
        disabledDays.push(format(nextDay));
      }
    }

    return disabledDays;
  };

  const getDietTimeSlots = dietId => {
    const customTimeSlots = dietWeekDays[dietId];

    if (!isFalsify(customTimeSlots)) {
      const nearestWeekDisabledDays =
        getNearestWeekDisabledDays(customTimeSlots);

      return {
        calendarText: orderFormData?.calendarText,
        customDietLengthEnabled: orderFormData?.customDietLengthEnabled,
        disabledDays: [
          ...(appConfigDisabledDays || []),
          ...nearestWeekDisabledDays,
        ],
        disabledDaysOfWeek: orderFormData?.disabledDaysOfWeek,
        firstPossibleOrderDay: format(
          selectFirstPossibleOrderDayByWeekDays(customTimeSlots)
        ),
        predefinedDietLengthEnabled: orderFormData?.predefinedDietLengthEnabled,
        standardDays: orderFormData?.days?.standardDays ?? {},
        testDays: orderFormData?.days?.testDays ?? {},
        testDaysEnabled: orderFormData?.days?.testDaysEnabled ?? false,
      };
    }

    return {
      calendarText: orderFormData?.calendarText,
      customDietLengthEnabled: orderFormData?.customDietLengthEnabled,
      disabledDays: orderFormData?.disabledDays,
      disabledDaysOfWeek: orderFormData?.disabledDaysOfWeek,
      firstPossibleOrderDay: orderFormData?.firstPossibleOrderDay,
      predefinedDietLengthEnabled: orderFormData?.predefinedDietLengthEnabled,
      standardDays: orderFormData?.days?.standardDays ?? {},
      testDays: orderFormData?.days?.testDays ?? {},
      testDaysEnabled: orderFormData?.days?.testDaysEnabled ?? false,
    };
  };

  return [getDietTimeSlots];
};

export default useDietTimeSlots;
