import { useRouter } from 'next/router';

const useLocale = () => {
  const router = useRouter();

  const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'pl';
  const locale = router.locale || defaultLocale;

  return { defaultLocale, locale };
};

export default useLocale;
