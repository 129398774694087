import { createSlice } from '@reduxjs/toolkit';
import {
  getUserData,
  impersonateLoginWithUserData,
  loginWithUserData,
  refreshToken,
  updateUserData,
} from '@store/auth/auth.actions';
import { deleteCookie, setCookie } from 'cookies-next';

import TIME from '@constants/time';
import { triggerDeclarationEvent } from '@utils/gtmTs';

const initialState = {
  isAuthenticated: false,
  impersonateEmail: null,
  user: {
    addresses: [],
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      deleteCookie('refreshToken');
      deleteCookie('token');
      deleteCookie('keepMeLoggedIn');
      deleteCookie('impersonateEmail');
      triggerDeclarationEvent({});

      return initialState;
    },
  },
  extraReducers: {
    [loginWithUserData.fulfilled]: (state, { payload: { user, userData } }) => {
      setCookie('refreshToken', user.refreshToken, {
        maxAge: TIME.MONTH_IN_SECONDS,
      });
      setCookie('token', user.token, { maxAge: TIME.MONTH_IN_SECONDS });
      setCookie('keepMeLoggedIn', user.keepMeLoggedIn, {
        maxAge: TIME.MONTH_IN_SECONDS,
      });

      state.isAuthenticated = true;
      state.user = { ...initialState.user, ...user.user, ...userData };
    },
    [impersonateLoginWithUserData.fulfilled]: (state, { payload }) => {
      state.isAuthenticated = true;
      state.impersonateEmail = payload.email;
      state.user = {
        ...initialState.user,
        ...payload.user,
        ...payload.userData,
      };
    },
    [refreshToken.fulfilled]: (state, { payload }) => {
      setCookie('refreshToken', payload.refreshToken, {
        maxAge: TIME.MONTH_IN_SECONDS,
      });
      setCookie('token', payload.token, { maxAge: TIME.MONTH_IN_SECONDS });

      state.user = { ...state.user, ...payload.user };
    },
    [updateUserData.fulfilled]: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
    },
    [getUserData.fulfilled]: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
    },
  },
});

export const selectAuth = state => state.auth;
export const selectLastAction = state => state.lastAction;
export const selectIsAuthenticated = ({ auth }) => auth.isAuthenticated;
export const selectUser = ({ auth }) => auth.user;

export const { logout } = authSlice.actions;

export default authSlice.reducer;
