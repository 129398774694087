import ADDON_DELIVERY_MODES from '@constants/addonDeliveryModes';

import GTM_ITEM_CATEGORIES from '../constants/gtmItemCategories';
import GTM_ITEM_XML_CATEGORIES from '../constants/gtmItemXmlCategories';

const convertDietAddonToGtmItem = ({
  brandName,
  dietDaysLength,
  dietAddon: { addon, type, quantity },
}) => {
  return {
    item_id: `${addon.id}`,
    item_xml_id: `${GTM_ITEM_XML_CATEGORIES.addon}_${addon.id}`,
    item_name: addon.name,
    coupon: '',
    discount: 0,
    index: 0,
    item_brand: brandName,
    item_category: GTM_ITEM_CATEGORIES.addon,
    item_category2: '',
    item_category3: '',
    item_category4: '',
    item_category5: '',
    item_category6: false,
    item_category7: 0,
    item_category8: '',
    item_category9: '',
    item_category10: '',
    item_category11: '',
    item_category12: type,
    price: addon.clientCost,
    quantity: Math.abs(quantity),
    duration: type === ADDON_DELIVERY_MODES.FOR_FIRST_BAG ? 1 : dietDaysLength,
  };
};

export default convertDietAddonToGtmItem;
