import { createWrapper } from 'next-redux-wrapper';

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  createTransform,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';

import apiMiddleware from './apiMiddleware';
import listenerMiddleware from './listenerMiddleware';
import rootReducer from './rootReducer';
import storage from './syncStorage';

const makeStore = () => {
  const persistTransform = createTransform((inboundState, key) => {
    if (key === 'orderForm') {
      return {
        ...inboundState,
        userProceed: false,
      };
    }

    return inboundState;
  });

  const persistConfig = {
    key: 'nextjs',
    version: 1,
    whitelist: [
      'auth',
      'basketEditOrder',
      'basketInitialData',
      'basketNewOrder',
      'config',
      'nativeAppConfig',
      'orderForm',
      'orderTabs',
    ], // only array reducers will be persisted, add other reducers if needed
    storage, // if needed, use a safer storage
    transforms: [persistTransform],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer); // Create a new reducer with our existing reducer

  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).prepend(listenerMiddleware.middleware),
      ...apiMiddleware,
    ],
    devTools: { trace: true, traceLimit: 25 },
  }); // Creating the store again

  store.__persistor = persistStore(store); // This creates a persistor object & push that persisted object to .__persistor, so that we can avail the persistability feature
  setupListeners(store.dispatch);

  return store;
};

// Export the wrapper & wrap the pages/_app.js with this wrapper only
export const wrapper = createWrapper(makeStore);
