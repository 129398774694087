import produce from 'immer';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _mapKeys from 'lodash/mapKeys';

import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import BASKET_ROWS_TYPES from '@constants/basketRowsTypes';

import { format } from './Date.service';

const convertInvoice = invoice => {
  return {
    companyName: invoice?.invoiceCompany ?? null,
    vatNumber: invoice?.invoiceVatNumber ?? null,
    type: invoice?.invoiceType ? 'DefaultCompany' : 'Other',
    address: {
      postCode: invoice?.invoiceAddressPostCode ?? null,
      city: invoice?.invoiceAddressCity ?? null,
      street: invoice?.invoiceAddressStreet ?? null,
      buildNumber: invoice?.invoiceAddressBuildNumber ?? null,
      placeNumber: invoice?.invoiceAddressPlaceNumber ?? null,
    },
  };
};

/***** TRANSFORM RESPONSE BASKET *****/

const transformResponseBasketUI = response => {
  const dietElements = _filter(response.rows, [
    '@type',
    BASKET_ROWS_TYPES.DIET_ITEM,
  ]);

  const modifications = _filter(response.rows, [
    '@type',
    BASKET_ROWS_TYPES.DAY_MODIFICATION,
  ]);

  const shopElements = _filter(
    response.rows,
    item =>
      ![
        BASKET_ROWS_TYPES.DIET_ITEM,
        BASKET_ROWS_TYPES.DAY_MODIFICATION,
      ].includes(item['@type'])
  );

  const connectedElementsByDietsIri = shopElements.reduce(
    (acc, shopElement) => {
      const nextState = produce(acc, draftState => {
        const { connectedTo, day, price, clientDiet } = shopElement;
        const connectedToIri = connectedTo?.['@id'] ?? 'notConnected';
        const clientDietIri = clientDiet ?? 'noDiet';
        const formattedDay = format(new Date(day));
        const priceDeliveryRowTypeBasketItem = response.rows.find(
          row =>
            connectedToIri === row.connectedTo?.['@id'] &&
            row['@type'] === BASKET_ROWS_TYPES.DAY_DELIVERY_ITEM
        );
        const shopElementPrice = shopElements?.find(
          row => row['@type'] === BASKET_ROWS_TYPES.DAY_DELIVERY_ITEM
        );

        draftState[connectedToIri] ??= {};
        draftState[connectedToIri][clientDietIri] ??= {};
        // additional info for day
        draftState[connectedToIri][clientDietIri][formattedDay] ??= {
          dishes: {},
          mealTypes: {},
          addons: [],
          delivery: {
            address: response.defaultAddress,
            pickUpPoint: response.defaultPickUpPoint,
            price:
              priceDeliveryRowTypeBasketItem?.price ||
              shopElementPrice?.price ||
              {},
          },
          price: {
            afterDiscount: 0,
            beforeDiscount: 0,
            discount: 0,
          },
        };

        const dietDay = draftState[connectedToIri][clientDietIri][formattedDay];

        if (shopElement['@type'] === BASKET_ROWS_TYPES.ADDON_ITEM) {
          const { addon, quantity } = shopElement;

          dietDay.addons.push({ ...addon, quantity, price });
        }

        if (shopElement['@type'] === BASKET_ROWS_TYPES.DAY_DELIVERY_ITEM) {
          dietDay.delivery = {
            address: shopElement.address,
            pickUpPoint: shopElement.pickUpPoint,
            price: shopElement.price,
          };
        }

        if (shopElement['@type'] === BASKET_ROWS_TYPES.DISH_ITEM) {
          const { dish, size, ...restDishSize } = shopElement.dishSize;

          const singleSize = {
            '@id': size?.['@id'] || null,
            name: size?.name || null,
            price: shopElement.price,
            quantity: shopElement.quantity,
            dishSize: restDishSize,
          };

          if (!_isEmpty(dietDay.dishes[dish['@id']])) {
            // if dish exist add new size to array
            dietDay.dishes[dish['@id']].sizes.push(singleSize);
          } else {
            // if dish doesn't exist create new object with dish data && singleSize
            dietDay.dishes[dish['@id']] ??= {
              dish,
              sizes: [singleSize],
            };
          }
        }

        if (shopElement['@type'] === BASKET_ROWS_TYPES.MEAL_TYPE_ITEM) {
          const { quantity, calories, mealType, price } = shopElement;

          const additionalMealType = {
            mealTypeIri: mealType?.['@id'],
            name: mealType?.name,
            calories,
            quantity,
            price,
          };

          dietDay.mealTypes[mealType['@id']] ??= additionalMealType;
        }

        // summ day price from items
        if (shopElement['@type'] !== BASKET_ROWS_TYPES.DAY_DELIVERY_ITEM) {
          dietDay.price.afterDiscount += price.afterDiscount;
          dietDay.price.beforeDiscount += price.beforeDiscount;
          dietDay.price.discount += price.discount;
        }

        if (
          _isEmpty(dietDay.mealTypes) &&
          _isEmpty(dietDay.dishes) &&
          _isEmpty(dietDay.addons)
        ) {
          delete draftState[connectedToIri][clientDietIri][formattedDay];
        }
      });

      return nextState;
    },
    {}
  );

  const uiRows = Object.entries(connectedElementsByDietsIri).reduce(
    (acc, [connectedToIri, clientDietItems]) =>
      produce(acc, draftState => {
        const connectedDietIndex = draftState.diets.findIndex(
          diet => diet['@id'] === connectedToIri
        );

        if (connectedDietIndex !== -1) {
          draftState.diets[connectedDietIndex].connectedElements =
            clientDietItems || {};
        } else {
          draftState.others = clientDietItems || {};
        }
      }),
    { diets: dietElements, others: {} }
  );

  return { ...uiRows, modifications };
};

export const transformNumberPaymentModeToBasketFormat = number => {
  switch (number?.toString()) {
    case '1':
      return BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;
    case '2':
      return BASKET_PAYMENT_MODES.SINGLE_PAYMENT;
    default:
      return BASKET_PAYMENT_MODES.SINGLE_PAYMENT;
  }
};

export const pickRenameKeysInitialDiet = object => {
  const newObj = _mapKeys(object, (_, key) => {
    const newKey =
      {
        calorificId: 'calorific',
        dietId: 'diet',
        packageId: 'package',
        variantId: 'variant',
      }[key] || key;

    return newKey;
  });

  return newObj;
};

const getAdditionalMealTypesByCurrentDietIri = (data, iri) => {
  const result = {};

  if (!data) {
    return result;
  }

  const mealTypeItems = data.filter(
    item =>
      item['@type'] === 'BasketItemMealType' && item.connectedTo['@id'] === iri
  );

  mealTypeItems.forEach(item => {
    const mealType = item.mealType['@id'];
    const mealTypeName = item.mealType.name;
    const quantity = item.quantity;
    const day = item.day;

    if (!result[mealType]) {
      result[mealType] = {
        mealTypeName: mealTypeName,
        mealTypePrice: item.price.beforeDiscount / quantity,
        deliveryDates: {},
      };
    }

    result[mealType].deliveryDates[day] = quantity;
  });

  for (const mealType in result) {
    result[mealType].deliveryDates = Object.fromEntries(
      Object.entries(result[mealType].deliveryDates).sort((a, b) =>
        a[0].localeCompare(b[0])
      )
    );
  }

  return result;
};

export default {
  convertInvoice,
  transformNumberPaymentModeToBasketFormat,
  transformResponseBasketUI,
  getAdditionalMealTypesByCurrentDietIri,
};
