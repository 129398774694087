const ArrowsRotateIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    viewBox="0 0 28 28"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M27.125 10.9375C27.125 11.6648 26.5398 12.25 25.8125 12.25H19.6875H17.9375C17.2102 12.25 16.625 11.6648 16.625 10.9375C16.625 10.2102 17.2102 9.625 17.9375 9.625H19.6875H22.6461L19.7969 6.77578C18.2547 5.23906 16.1711 4.375 14 4.375C10.0242 4.375 6.60625 6.78672 5.14062 10.232C4.85625 10.8992 4.08516 11.2109 3.41797 10.9266C2.75078 10.6422 2.43906 9.87109 2.72344 9.20391C4.58828 4.82344 8.93594 1.75 14 1.75C16.8711 1.75 19.6219 2.8875 21.6508 4.91641L24.5 7.77109V4.8125V4.79062V3.0625C24.5 2.33516 25.0852 1.75 25.8125 1.75C26.5398 1.75 27.125 2.33516 27.125 3.0625V10.9375ZM2.1875 15.75H8.3125C9.03984 15.75 9.625 16.3352 9.625 17.0625C9.625 17.7898 9.03984 18.375 8.3125 18.375H5.35391L8.20312 21.2242C9.74531 22.7609 11.8289 23.625 14 23.625C17.9703 23.625 21.3828 21.2242 22.8539 17.7844C23.1383 17.1172 23.9094 16.8109 24.5766 17.0953C25.2437 17.3797 25.55 18.1508 25.2656 18.818C23.3953 23.1875 19.0586 26.25 14 26.25C11.1289 26.25 8.37812 25.1125 6.34922 23.0836L3.5 20.2289V23.1875C3.5 23.9148 2.91484 24.5 2.1875 24.5C1.46016 24.5 0.875 23.9148 0.875 23.1875V17.0625C0.875 16.3352 1.46016 15.75 2.1875 15.75Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowsRotateIcon;
