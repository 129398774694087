import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import { transformApiErrors } from '@services/Api.serviceTS';
import BasketService from '@services/Basket.service';
import axios from '@utils/axiosInstance';
import showToast from '@utils/showToast';

const updateAdditionalMealTypes = async additionalMealTypeConfig => {
  const { days, shouldForceRange } = additionalMealTypeConfig ?? {};
  const isRange = shouldForceRange || days?.length > 1;

  const response = await axios.post(
    isRange
      ? ENDPOINT.BASKET_ADD_MEAL_TYPE_RANGE
      : ENDPOINT.BASKET_ADD_MEAL_TYPE,
    isRange
      ? additionalMealTypeConfig
      : { ...additionalMealTypeConfig, day: days[0], days: undefined },
    {
      errorHandle: false,
    }
  );

  const data = response?.data ?? {};
  const uiRows = BasketService.transformResponseBasketUI(data);

  return { ...data, uiRows };
};

const useUpdateAdditionalMealTypes = ({ basketKey, options = {} } = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: updateAdditionalMealTypes,
    mutationKey: [`${basketKey}UpdateAdditionalMealTypes`],
    onSuccess: response => {
      queryClient.setQueryData([basketKey], response);
    },
    onError: err => {
      const error = err?.response?.data;
      const apiFieldErrors = transformApiErrors(error);

      showToast(apiFieldErrors?.day, { type: 'warning' });
    },
    retry: 0,
    ...options,
  });

  return query;
};

export default useUpdateAdditionalMealTypes;
