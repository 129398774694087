const INPUT_IDS = {
  ADDRESS_ID: 'id',
  STREET: 'street',
  STREET_OPTION: 'streetOption',
  HOUSE_NUMBER: 'buildNumber',
  HOUSE_NUMBER_OPTION: 'buildNumberOption',
  HOUSE_UNIT_NUMBER: 'placeNumber',
  POSTAL_CODE: 'postCode',
  CITY: 'city',
  CITY_OPTION: 'cityOption',
  FLOOR: 'floor',
  STAIRCASE: 'gate',
  INTERCOM_CODE: 'keyToIntercom',
  DELIVERY_HOURS: 'selectedDeliveryHour',
  ADDITIONAL_INFO: 'comment',
  SET_AS_DEFAULT: 'isDefault',
  ADDRESS_LINE1: 'addressLine1',
  ADDRESS_LINE2: 'addressLine2',
};

export default INPUT_IDS;
