import getRoundedPrice from '../utils/getRoundedPrice';

const getCartValue = gtmItems => {
  return getRoundedPrice(
    gtmItems.reduce((acc, gtmItem) => {
      return acc + gtmItem.price * gtmItem.quantity * gtmItem.duration;
    }, 0)
  );
};

export default getCartValue;
