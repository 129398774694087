import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

const getConfig = async () => {
  const response = await axios.get(ENDPOINT.CONFIG, {
    withToken: false,
  });

  return response?.data ?? {};
};

export const checkRouteIsProteced = route => route.includes('/my-account');

export default {
  getConfig,
  checkRouteIsProteced,
};
