import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import { transformApiErrors } from '@services/Api.serviceTS';
import BasketService from '@services/Basket.service';
import axios from '@utils/axiosInstance';
import getBasketDebounceHandler from '@utils/getBasketDebounceHandler';
import showToast from '@utils/showToast';

const createBasketDiet = ({
  data: diet,
  resolve,
  reject,
  clearStaleRejects,
}) => {
  clearStaleRejects();

  axios
    .post(ENDPOINT.BASKET_ADD_DIET, diet, {
      errorHandle: false,
    })
    .then(response => {
      const data = response?.data ?? {};
      const uiRows = BasketService.transformResponseBasketUI(data);

      resolve({ ...data, uiRows });
    })
    .catch(error => {
      reject(error);
    });
};

const useCreateBasketDiet = ({ basketKey, options = {} } = {}) => {
  const queryClient = useQueryClient();

  const mutationKey = `${basketKey}AddDiet`;

  return useMutation({
    mutationFn: ({ shouldDebounce, ...data }) => {
      return getBasketDebounceHandler({
        shouldDebounce,
        mutationKey,
        mutationFn: createBasketDiet,
        data,
      });
    },
    mutationKey: [mutationKey],
    onSuccess: response => {
      queryClient.setQueryData([basketKey], response);
    },
    onError: err => {
      const error = err?.response?.data;
      const apiFieldErrors = transformApiErrors(error);

      showToast(apiFieldErrors?.day, { type: 'warning' });
    },
    retry: 0,
    ...options,
  });
};

export default useCreateBasketDiet;
