const getBasketItemsDiff = (prevBasketItems, currentBasketItems) => {
  return {
    itemsRemoved: prevBasketItems.filter(prevBasketItem => {
      return currentBasketItems.every(currentBasketItem => {
        return currentBasketItem.id !== prevBasketItem.id;
      });
    }),
    itemsAdded: currentBasketItems.filter(currentBasketItem => {
      return prevBasketItems.every(prevBasketItem => {
        return prevBasketItem.id !== currentBasketItem.id;
      });
    }),
    prevItemsPresentBefore: prevBasketItems.filter(prevBasketItem => {
      return currentBasketItems.some(currentBasketItem => {
        return currentBasketItem.id === prevBasketItem.id;
      });
    }),
    currentItemsPresentBefore: currentBasketItems.filter(currentBasketItem => {
      return prevBasketItems.some(prevBasketItem => {
        return prevBasketItem.id === currentBasketItem.id;
      });
    }),
  };
};

export default getBasketItemsDiff;
