import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import { transformApiErrors } from '@services/Api.serviceTS';
import BasketService from '@services/Basket.service';
import axios from '@utils/axiosInstance';
import showToast from '@utils/showToast';

const createBasketAdditionalMealType = async dish => {
  const response = await axios.post(ENDPOINT.BASKET_ADD_MEAL_TYPE, dish, {
    errorHandle: false,
  });
  const data = response?.data ?? {};
  const uiRows = BasketService.transformResponseBasketUI(data);

  return { ...data, uiRows };
};

const useCreateBasketAdditionalMealType = ({
  basketKey,
  options = {},
} = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: createBasketAdditionalMealType,
    mutationKey: [`${basketKey}AddDish`],
    onSuccess: response => {
      queryClient.setQueryData([basketKey], response);
    },
    onError: err => {
      const error = err?.response?.data;
      const apiFieldErrors = transformApiErrors(error);

      showToast(apiFieldErrors?.day, { type: 'warning' });
    },
    ...options,
  });

  return query;
};

export default useCreateBasketAdditionalMealType;
