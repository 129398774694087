import type { Client } from '@tsTypes/Client';
import type { CommonLoginPayload } from '@tsTypes/login';
import type { CommonRegisterPayload } from '@tsTypes/register';
import decode from 'jwt-decode';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

export type UserResponse = {
  iat: number;
  exp: number;
  roles: unknown[];
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  brand: string;
  id: string;
  humanReadableId: number;
  phoneCountryCode: string;
  phone: string;
  brands: unknown[];
  lastModifyingActivityAt: number;
  basket: string;
  hasWaitingForPaymentOrders: boolean;
  salesManagoId: string;
};

type LoginResponse = {
  token: string;
  refreshToken: string;
};

const login = async (requestPayload: CommonLoginPayload) => {
  const { type, keepMeLoggedIn, ...credentials } = requestPayload;

  let user: Partial<UserResponse> = {};
  let data: Partial<LoginResponse> = {};

  if (type === 'apple') {
    const { token = '', refreshToken } = requestPayload;

    user = decode(token);
    data = { token, refreshToken };
  } else {
    let endpointUrl = '';
    if (type === 'facebook') {
      endpointUrl = ENDPOINT.FACEBOOK_LOGIN;
    } else if (type === 'google') {
      endpointUrl = ENDPOINT.GOOGLE_LOGIN;
    } else {
      endpointUrl = ENDPOINT.LOGIN;
    }

    const response = await axios.post<LoginResponse>(endpointUrl, credentials, {
      errorHandle: false,
    });

    data = response.data;

    if ('token' in data && typeof data.token === 'string') {
      user = decode(data.token);
    }
  }

  return {
    ...data,
    user,
    keepMeLoggedIn,
  };
};

const getData = async (requestPayload?: {
  id?: string;
  headers: { Authorization: string };
}) => {
  const response = await axios.get<Client>(ENDPOINT.USER_DATA, requestPayload);

  return response.data;
};

const register = async (requestPayload: CommonRegisterPayload) => {
  const { data } = await axios.post<Client>(ENDPOINT.REGISTER, requestPayload, {
    errorHandle: false,
  });

  return data;
};

export default {
  getData,
  login,
  register,
};
