import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  diet: {},
};

const basketInitialData = createSlice({
  name: 'basketInitialData',
  initialState,
  reducers: {
    setInitialDiet: (state, { payload }) => {
      state.diet = payload;
    },
    resetState: () => initialState,
  },
});

export const selectInitialDiet = state => state.basketInitialData.diet;

export const { setInitialDiet, resetState } = basketInitialData.actions;

export default basketInitialData.reducer;
