import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import BasketService from '@services/Basket.service';
import axios from '@utils/axiosInstance';
import showToast from '@utils/showToast';

const addBasketModifyDay = async ({ payload }) => {
  const response = await axios.post(
    ENDPOINT.BASKET_ADD_MODIFY_DAY,
    {
      ...payload,
    },
    { errorHandle: false }
  );

  const data = response?.data ?? {};
  const uiRows = BasketService.transformResponseBasketUI(data);

  return { ...data, uiRows };
};

const useBasketModifyDay = ({ basketKey, options = {} } = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: addBasketModifyDay,
    mutationKey: [`${basketKey}AddModifyDay`],
    onSuccess: response => {
      queryClient.setQueryData([basketKey], response);
    },
    onError: err => {
      const error = err?.response?.data;

      showToast(error, { type: 'error' });
    },
    retry: 3,
    ...options,
  });

  return query;
};

export default useBasketModifyDay;
