import { useRouter } from 'next/router';

import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import useBasketPrices from '@components/modules/Basket/BasketStatic/useBasketPrices';
import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import DIET_LENGTH_MODE from '@constants/dietLengtMode';
import useDietTimeSlots from '@hooks/basket/useDietTimeSlots';
import useSelectDaysByRange from '@hooks/newOrder/useSelectDaysByRange';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
  useAppMode,
} from '@hooks/useAppConfigSelectors';
import useBlockDelivery from '@hooks/useBlockDelivery';
import { selectOrderForm, useOrderFormSelector } from '@hooks/useOrderForm';
import { getIDfromIRI } from '@services/Api.service';
import { transformNumberPaymentModeToBasketFormat } from '@services/Basket.service';
import { format, formatArray } from '@services/Date.service';
import { orderByPositionName } from '@utils/helpers';

import useDefaultPaymentMode from './useDefaultPaymentMode';
import useWeekInclude from './useWeekInclude';

const useDefaultDietElement = () => {
  const { isOnlyShop } = useAppMode();
  if (isOnlyShop) return {};

  const {
    customDietLengthEnabled,
    days: { standardDays = {} } = {},
    diets = [],
    predefinedDietLengthEnabled,
    packages,
    packageLineFilters,
  } = useOrderFormSelector(selectOrderForm);

  const {
    deliveryOptions: {
      pickUpPoint: isPickUpPointEnabled,
      address: isAddressEnabled,
    } = {},
  } = useAppConfigSelector(selectModuleConfigClientPanel);

  const { getBlockDays } = useBlockDelivery();

  const weekInclude = useWeekInclude();
  const defaultPaymentModeNumber = useDefaultPaymentMode();
  const [getDietTimeSlots] = useDietTimeSlots();
  const [selectDaysByRange] = useSelectDaysByRange();

  const { query } = useRouter();

  const packagesWithActiveDiets =
    packages?.filter(({ activeDietsIds }) => !isEmpty(activeDietsIds)) ?? [];
  const sortedPackages = orderBy(
    packagesWithActiveDiets,
    ['position', 'name'],
    ['asc', 'desc']
  );
  const firstPackage = first(sortedPackages);

  const getInitialDietByType = () => {
    if (!isEmpty(packages)) {
      const { activeDietsIds } = firstPackage;
      const packageDiets = diets.filter(diet =>
        activeDietsIds.includes(diet.id)
      );

      const dietsWithPricing = packageDiets; // getActivePackageDiets(packageDiets);
      const sortedDiets = orderByPositionName(dietsWithPricing);
      const firstDiet = first(sortedDiets);

      return firstDiet;
    }

    if (!isEmpty(diets)) {
      const firstDiet = first(diets);

      return firstDiet;
    }
  };

  const firstDiet = getInitialDietByType();
  const sortedVariants = orderByPositionName(firstDiet?.variants);
  const firstVariant = first(sortedVariants);

  const sortedCalorifics = orderByPositionName(firstVariant?.calories);
  const firstCalorific = first(sortedCalorifics);
  const dietTimeSlots = getDietTimeSlots(getIDfromIRI(firstDiet?.['@id']));

  const defaultPaymentMode = transformNumberPaymentModeToBasketFormat(
    defaultPaymentModeNumber
  );
  const isSubscription =
    defaultPaymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;
  const queryDietLength = parseInt(query.dietLength);

  const dietLength = isSubscription
    ? getBlockDays(new Date(dietTimeSlots?.firstPossibleOrderDay)).length
    : predefinedDietLengthEnabled && !customDietLengthEnabled
    ? (standardDays?.options ?? [])?.[0]
    : (queryDietLength || standardDays?.default) ?? 5;

  const newDeliveryDates = formatArray(
    selectDaysByRange(
      new Date(dietTimeSlots?.firstPossibleOrderDay),
      dietLength,
      {
        disabledDays: dietTimeSlots?.disabledDays,
        disabledDaysOfWeek: dietTimeSlots?.disabledDaysOfWeek,
      }
    )
  );

  const dietLineFilter = packageLineFilters.find(filter => {
    return filter.activePackagesIds.includes(parseInt(firstPackage?.id));
  });

  const values = {
    id: firstDiet?.id,
    addons: [],
    address: {},
    calorificId: firstCalorific?.['@id'],
    calorificName: firstCalorific?.name,
    deliveryDates: newDeliveryDates,
    deliveryType: isAddressEnabled
      ? 'ADDRESS'
      : isPickUpPointEnabled
      ? 'PICKUP_POINT'
      : null,
    dietLength,
    firstDeliveryDay:
      first(newDeliveryDates) ??
      format(new Date(dietTimeSlots?.firstPossibleOrderDay)),
    optionChangeMenu: false,
    packageId: firstPackage?.['@id'] ?? null,
    packageLineFilters: dietLineFilter,
    paymentMode: defaultPaymentMode,
    pickUpPoint: {},
    useEcoContainers: false,
    dietId: firstDiet?.['@id'],
    variantId: firstVariant?.['@id'],
    ...(firstVariant?.superVariantBeta
      ? {
          mealTypes: (firstVariant?.mealTypes || []).map(
            mealType => mealType?.['@id']
          ),
        }
      : {}),
    variantName: firstVariant?.name,
    dietLengthMode:
      predefinedDietLengthEnabled && !customDietLengthEnabled
        ? DIET_LENGTH_MODE.PREDEFINED
        : DIET_LENGTH_MODE.CUSTOM,
    ...weekInclude,
    allowStepWithAdditionalMealTypes:
      firstDiet.allowStepWithAdditionalMealTypes,
    hasDietsToSelectMenu: firstDiet.hasDietsToSelectMenu,
  };

  const basketPrices = useBasketPrices({
    dietElements: [values],
  });

  return { ...values, price: basketPrices.price };
};

export default useDefaultDietElement;
