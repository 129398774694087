import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

const useFormikErrors = id => {
  const { errors, touched, status } = useFormikContext();
  const hasError = !!(errors?.[id] && touched?.[id]);
  const apiFieldError = status?.apiFieldErrors?.[id] || '';
  const hasApiFieldError = !isEmpty(apiFieldError);

  return { hasError, apiFieldError, hasApiFieldError };
};

export default useFormikErrors;
