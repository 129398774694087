const convertGtmItemIndexesByCategory = gtmItems => {
  const indexesByCategories = {};
  const updatedGtmItems = [];

  gtmItems.forEach(item => {
    const category = item.item_category;
    const currentIndex =
      category in indexesByCategories ? indexesByCategories[category] + 1 : 0;

    indexesByCategories[category] = currentIndex;

    updatedGtmItems.push({
      ...item,
      index: currentIndex,
    });
  });

  return updatedGtmItems;
};

export default convertGtmItemIndexesByCategory;
