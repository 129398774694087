import { format as fnsFormat, isValid } from 'date-fns';

export const format = (
  date = '',
  formatStr = 'yyyy-MM-dd',
  defaultDate = null
) => {
  const dateObject = new Date(date);

  if (isValid(dateObject)) {
    return fnsFormat(dateObject, formatStr);
  }

  return defaultDate;
};

export const formatArray = (array, formatStr = 'yyyy-MM-dd') => {
  if (!Array.isArray(array)) return [];

  return array.map(date => format(date, formatStr));
};

export const isSameDate = (first, second) => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};
