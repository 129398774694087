import { i18n } from 'next-i18next';

import { unwrapResult } from '@reduxjs/toolkit';
import { refreshToken } from '@store/auth/auth.actions';
import { logout } from '@store/auth/auth.slice';
import { setAppVersion } from '@store/config/config.slice';
import { getCookie, setCookie } from 'cookies-next';
import isEmpty from 'lodash/isEmpty';

import { ENDPOINT } from '@constants/endpoint';
import ROUTE_URLS from '@constants/routeUrls';
import TIME from '@constants/time';

import axiosInstance from './axiosInstance';
import { isServer, log } from './helpers';
import showToast from './showToast';

const setUpInterceptor = store => {
  const logoutFn = () => {
    store.dispatch(logout());

    return null;
  };

  const handelError401 = async error => {
    const originalRequest = error.config;
    const keepMeLoggedIn =
      getCookie('keepMeLoggedIn') === true ||
      getCookie('keepMeLoggedIn') === 'true';

    if (!originalRequest._retry && keepMeLoggedIn) {
      originalRequest._retry = true;

      return store
        .dispatch(refreshToken())
        .then(unwrapResult)
        .then(({ token }) => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`;

          return axiosInstance(originalRequest);
        })
        .catch(() => {
          return logoutFn();
        });
    } else {
      return logoutFn();
    }
  };

  axiosInstance.interceptors.request.use(
    async request => {
      isServer && log('REQUEST API: ', request?.url);
      const token = getCookie('token') || null;
      const impersonateEmail = getCookie('impersonateEmail') || null;

      if (token && request.withToken) {
        request.headers.Authorization = `Bearer ${token}`;
      }

      if (![undefined, 'undefined', null, 'null'].includes(impersonateEmail)) {
        request.headers['x-switch-user'] = impersonateEmail;
        request.headers.Authorization = `Bearer ${token}`;
      }

      // logic locale for config server request
      const cookieLocale = getCookie('NEXT_LOCALE');
      let xLocale;

      if (request.xLocale) {
        xLocale = request.xLocale;
      } else if (cookieLocale) {
        xLocale = cookieLocale;
      } else {
        xLocale = i18n.language;
      }

      request.headers['X-LOCALE'] = xLocale;

      return request;
    },
    error => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    response => {
      isServer &&
        log('RESPONSE API SUCCESS: ', response?.config?.url ?? 'NO_URL');

      // the code below checks version of app and refreshes without cache if app version under key x-cv is different
      if (
        response?.config.method === 'get' &&
        getCookie('appv') !== response.headers['x-cv'] // version api
      ) {
        !isEmpty(getCookie('appv')) &&
          store.dispatch({ type: 'app/resetToInitialWithToast' });

        setCookie('appv', response.headers['x-cv'], {
          maxAge: 2 * TIME.WEEK_IN_SECONDS,
        });
      }

      // set to store and run clear when is persisted store but are new changes in store
      if (
        response?.config.method === 'get' &&
        store.getState().config.appVersion !== response.headers['x-cv']
      ) {
        store.dispatch(setAppVersion(response.headers['x-cv']));
      }

      return response;
    },
    error => {
      isServer &&
        log('RESPONSE API ERROR: ', error?.config?.url ?? 'APP_ERROR');

      if (error.message === 'canceled') {
        return;
      }

      const { data, status } = error.response;
      const errorConfigUrl = error?.config?.url ?? '';
      const isError401 =
        status === 401 &&
        !window.location.href.includes(ROUTE_URLS.AUTH_LOGIN) &&
        ![ENDPOINT.REFRESH_LOGIN, ENDPOINT.LOGOUT, ENDPOINT.LOGIN].includes(
          error?.config.url
        );
      const isError401OnAuthLoginRoute =
        status === 401 && window.location.pathname === ROUTE_URLS.AUTH_LOGIN;

      // Handling situation when user got stuck with basket connected to account, being logged out at the same moment. When 401 with cleanup or clear action on basket we resetToInitial to get new clean cart
      if (
        (isError401 && errorConfigUrl.includes('cleanup')) ||
        errorConfigUrl.includes('clear')
      ) {
        store.dispatch({ type: 'app/resetToInitial' });

        return Promise.resolve(error);
      }

      if (error?.config?.errorHandle === false) {
        if (isError401) {
          handelError401(error);
        }

        return Promise.reject(error);
      }

      const collectErrorMessages400 = () => {
        const { 'hydra:description': description = '', violations = [] } = data;
        const allErrorMessages =
          violations.length > 0
            ? violations.reduce((acc, { message, propertyPath }) => {
                return [...acc, `${[propertyPath]}: ${message}`];
              }, []) || []
            : [description];
        const filteredAllErrorMessages = allErrorMessages.filter(Boolean);

        return filteredAllErrorMessages
          ? filteredAllErrorMessages
          : ['Client: Bad Request', ...filteredAllErrorMessages];
      };

      const collectErrorMessages404 = () => {
        const message = data.detail
          ? `${data.detail}: ${data.title}`
          : `${data['hydra:description']}: ${data['hydra:title']}`;

        return message;
      };

      const toastMessages = {
        400: collectErrorMessages400(),
        403: `Authorization: ${data['hydra:description']}`,
        404: collectErrorMessages404(),
        422: collectErrorMessages400(),
        500: 'Server: Internal Server Error',
        undefined: 'Undefined Error',
      };

      const toastMessage = toastMessages[status] || toastMessages.undefined;

      if ([400, 403, 404, 500].includes(status)) {
        if (Array.isArray(toastMessage)) {
          toastMessage.map(message =>
            showToast(message, { toastId: `error${status}` })
          );
        } else {
          showToast(toastMessage, { toastId: `error${status}` });
        }
      } else if ([402, 409].includes(status)) {
        return Promise.reject(error);
      } else if (isError401) {
        handelError401(error);
      } else if (!isError401OnAuthLoginRoute) {
        showToast(toastMessages.undefined, { toastId: 'errorUndefined' });
      }

      return Promise.reject(error);
    }
  );
};

export default setUpInterceptor;
