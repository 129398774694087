import { useQuery } from '@tanstack/react-query';

import getAppConfig from '@utils/getAppConfig';

const useAppConfig = () => {
  const query = useQuery({
    queryKey: ['appConfig'],
    queryFn: getAppConfig,
    enabled: false,
  });

  return {
    ...query,
    data: query.data as NonNullable<typeof query.data>, // data is always available because query is prefetched in pages/_app.js
  };
};

export default useAppConfig;
