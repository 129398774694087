import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';

import GTM_ITEM_CATEGORIES from '../constants/gtmItemCategories';
import GTM_ITEM_XML_CATEGORIES from '../constants/gtmItemXmlCategories';
import getRoundedPrice from '../utils/getRoundedPrice';

const convertDietToGtmItem = ({
  brandName,
  basketDiscountCode,
  dietItem: {
    diet,
    variant,
    calorific,
    paymentMode,
    options,
    days,
    price,
    quantity,
  },
}) => {
  const isSubscription =
    paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;
  const addonPrice = price.priceParts?.ADDON || 0;

  return {
    item_id: `${diet.id}`,
    item_xml_id: `${GTM_ITEM_XML_CATEGORIES.diet}_${diet.id}_${variant.id}_${calorific.id}`,
    item_name: diet.name,
    coupon: basketDiscountCode,
    discount: price.discount,
    index: 0,
    item_brand: brandName,
    item_category: GTM_ITEM_CATEGORIES.diet,
    item_category2: diet.name,
    item_category3: variant.name,
    item_category4: calorific.name,
    item_category5: '',
    item_category6: isSubscription,
    item_category7: 0,
    item_category8: options.saturdayInclude,
    item_category9: options.sundayInclude,
    item_category10: days[0],
    item_category11: isSubscription ? '' : days[days.length - 1],
    item_category12: '',
    price: getRoundedPrice((price.afterDiscount - addonPrice) / days.length),
    quantity: Math.abs(quantity),
    duration: days.length,
  };
};

export default convertDietToGtmItem;
