import { addDays, getDay, isAfter } from 'date-fns';

import {
  selectLastPossibleOrderDay,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useBlockDelivery from '@hooks/useBlockDelivery';
import { getFormattedDisabledDaysOfWeek } from '@services/Calendar.service';
import { format, formatArray } from '@services/Date.service';
import { isDate } from '@utils/helpers';

const useSelectDaysByRange = () => {
  const lastPossibleOrderDay = useAppConfigSelector(selectLastPossibleOrderDay);
  const {
    getBlockDays,
    checkIfDayIsDeliveredInBlock,
    getCurrentlyAvailableBlockSizes,
  } = useBlockDelivery();

  const isDateValid = (date, validitySettings = {}) => {
    const { disabledDays = [], disabledDaysOfWeek = [] } =
      validitySettings ?? {};

    const formattedDisabledDaysOfWeek =
      getFormattedDisabledDaysOfWeek(disabledDaysOfWeek);

    const turnedOffDaysOfWeek =
      [
        disabledDaysOfWeek.includes(6) ? 6 : null,
        disabledDaysOfWeek.includes(7) ? 0 : null,
      ].filter(Number.isInteger) ?? [];

    return (
      !disabledDays.includes(format(date)) &&
      !turnedOffDaysOfWeek.includes(getDay(date)) &&
      !formattedDisabledDaysOfWeek.includes(getDay(date))
    );
  };

  const isDateAfterLastPossibleDate = date => {
    return isAfter(date, lastPossibleOrderDay);
  };

  const selectDaysByRange = (startDate, range, validitySettings = {}) => {
    let selectedDays = [];
    let currentDate = startDate;
    let securityCounter = 0;

    // SecurityCounter is added to avoid infinite loop when block delivery config is improperly configured
    while (selectedDays.length < range && securityCounter < 300) {
      if (
        isDateValid(currentDate, validitySettings) &&
        !formatArray(selectedDays).includes(format(currentDate))
      ) {
        const isCurrentDayDeliveredInBlock = checkIfDayIsDeliveredInBlock(
          new Date(currentDate)
        );

        if (isCurrentDayDeliveredInBlock) {
          const daysLeftToSelect = range - selectedDays.length;
          const currentlyAvailableBlockSizes =
            getCurrentlyAvailableBlockSizes(daysLeftToSelect);
          const blockDays = getBlockDays(new Date(currentDate));

          if (currentlyAvailableBlockSizes.includes(blockDays?.length))
            selectedDays = [...selectedDays, ...blockDays];
        } else {
          selectedDays.push(currentDate);
        }
      }
      currentDate = addDays(new Date(currentDate), 1);
      securityCounter++;
    }

    if (isDate(lastPossibleOrderDay)) {
      selectedDays = selectedDays.filter(
        date => !isDateAfterLastPossibleDate(date)
      );
    }

    return formatArray(selectedDays);
  };

  return [selectDaysByRange];
};

export default useSelectDaysByRange;
