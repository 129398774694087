import { cloneElement } from 'react';

import { isChildTypeDisplayNameEqualTo } from '@utils/helpers';

const mapPropsToChild = (child, { onClose, disableClose, size }) => {
  if (isChildTypeDisplayNameEqualTo(child, 'Header')) {
    return cloneElement(child, { onClose, size, disableClose });
  }

  return cloneElement(child, { size });
};

export default mapPropsToChild;
