import { forwardRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tw from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

const InputText = forwardRef((inputProps, ref) => {
  const {
    type = 'text',
    styles = {},
    mask,
    disabled = false,
    customDisabledStyles,
    ...restInputProps
  } = inputProps;
  const [eyeType, setEyeType] = useState('password');

  const twStyle = cssMerge({
    defaultCss: {
      ...tw`w-full px-5 py-2.5 placeholder-gray-2 border outline-none rounded-md focus-visible:(border-transparent ring-2 ring-primary)`,
      ...(disabled
        ? customDisabledStyles ??
          tw`text-gray-3 bg-gray-1 border-gray-1 cursor-not-allowed`
        : tw`text-gray-4 bg-white border-gray-1`),
      ...(restInputProps.readOnly &&
        tw`text-gray-3 border-gray-1 cursor-default focus-visible:(border-gray-1 ring-0)`),
    },
    ...styles,
  });

  if (type === 'password') {
    const isPassword = eyeType === 'password';
    return (
      <div tw="relative">
        <input
          css={twStyle}
          disabled={disabled}
          ref={ref}
          type={eyeType}
          {...restInputProps}
        />
        {inputProps.value.length > 0 && (
          <button
            tw="absolute top-1/2 right-5 transform -translate-y-1/2 cursor-pointer"
            type="button"
            data-cy="input-password__toggle-visibility"
            onClick={() => setEyeType(isPassword ? 'text' : 'password')}
          >
            <FontAwesomeIcon icon={isPassword ? faEyeSlash : faEye} />
          </button>
        )}
      </div>
    );
  }

  if (mask && !disabled) {
    return (
      <InputMask
        css={twStyle}
        mask={mask}
        disabled={disabled}
        maskChar={null}
        {...restInputProps}
      >
        {inputProps => <input type={type} ref={ref} {...inputProps} />}
      </InputMask>
    );
  }

  if (type === 'number') {
    const handleKeyPress = event => {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    };

    return (
      <input
        css={twStyle}
        disabled={disabled}
        onKeyPress={handleKeyPress}
        ref={ref}
        type={type}
        {...restInputProps}
      />
    );
  }

  return (
    <input
      css={twStyle}
      disabled={disabled}
      ref={ref}
      type={type}
      {...restInputProps}
    />
  );
});

InputText.displayName = 'InputText';

export default InputText;
