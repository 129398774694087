import { addDays, isAfter, isDate, subMinutes } from 'date-fns';
import { format as fnsFormat } from 'date-fns';
import { enUS } from 'date-fns/locale';
import deepmerge from 'deepmerge';
import isNumber from 'lodash/isNumber';

import useAppConfig from '@hooks/useAppConfig';
import { format } from '@services/Date.service';

export const useAppConfigSelector = selector => {
  const { data } = useAppConfig();

  return selector(data);
};

const initialState = {
  additionalPrices: {
    CHANGE_MENU: {},
    ECO_CONTAINER: {},
  },
  authMethods: {},
  branding: {
    name: 'Panel',
    favicon: null,
    backgroundColor: '#F7F7F7',
    backgroundImage: null,
    primaryColor: '#03388C',
    secondaryColor: '#B58A78',
    headTags: '',
    bodyTags: '',
    customScripts: '',
  },
  dictionaries: {},
  dietWeekDays: {},
  modules: {
    Addons: {},
    AppMode: {},
    AuthServices: {},
    BlueMedia: {},
    Blog: {},
    ClickOnMetrics: {},
    CodeReferences: {},
    ConfigClientPanel: {
      referralProgramDescription: '',
    },
    FacebookPixel: {},
    Fakturownia: { allowInvoicesForClient: false },
    Fonts: {
      clientTitle: {
        libraryParams: [],
        font: 'Nunito Sans',
        library: 'google',
      },
      clientText: { libraryParams: [], font: 'Nunito Sans', library: 'google' },
    },
    GoogleAnalytics: {},
    GoogleTagManager: {},
    HotJar: {},
    Hover: {},
    ItemPaymentTypes: {},
    Links: {},
    MenuPage: {},
    MoneyBox: {},
    MyLead: {},
    MenuSelectionAssistant: {},
    PaymentModesCopy: {},
    PayU: {},
    PollsterTrack: {},
    PowerBI: {},
    Restaurant: {},
    SalesManago: {},
    Seo: {},
    SeoAddon: {},
    SeoDiet: {},
    SeoDish: {},
    SeoZone: {},
    Shop: {},
    Stripe: {},
    SeoCmsAddonsSide: {},
    SeoCmsClientPanelLogin: {},
    SeoCmsClientPanelMyAccount: {},
    SeoCmsDietSide: {},
    SeoCmsMainSite: {},
    SeoCmsMenuSubpage: {},
    SeoCmsZonesSubpage: {},
    SeoCmsDishSide: {},
    SeoCmsClientPanelRegister: {},
  },
  multinational: {
    clientAllowedToChangeLanguage: true,
  },
  pages: [],
  panelOptions: {
    seo: { metaTitle: 'Panel' },
    showReferrerCode: false,
  },
  weekDays: {},
  appMode: {
    isDiet: false,
    isDietFull: false,
    isDietMini: false,
    isDietShop: false,
    isOnlyDiet: false,
    isOnlyShop: false,
    isRestaurant: false,
    isShop: false,
  },
};

export const useAppMode = () => {
  const selectAppMode = data =>
    deepmerge(initialState.appMode, data?.appMode ?? {});

  return useAppConfigSelector(selectAppMode);
};

export const selectConfig = data => deepmerge(initialState, data ?? {});

export const selectAdditionalPrices = data =>
  deepmerge(initialState.additionalPrices, data?.additionalPrices ?? {});

export const selectAuthMethods = data =>
  deepmerge(initialState.authMethods, data?.authMethods ?? {});

export const selectBranding = data =>
  deepmerge(initialState.branding, data?.branding ?? {});

export const selectDictionaries = data =>
  deepmerge(initialState.dictionaries, data?.dictionaries ?? {});

export const selectModules = data =>
  deepmerge(initialState.modules, data?.modules ?? {});

export const selectModuleAddons = data =>
  deepmerge(initialState.modules.Addons, data?.modules?.Addons ?? {});

export const selectModuleClickOnMetrics = data =>
  deepmerge(
    initialState.modules.ClickOnMetrics,
    data?.modules?.ClickOnMetrics ?? {}
  );

export const selectModuleCodeReferences = data =>
  deepmerge(
    initialState.modules.CodeReferences,
    data?.modules?.CodeReferences ?? {}
  );

export const selectModuleConfigClientPanel = data =>
  deepmerge(
    initialState.modules.ConfigClientPanel,
    data?.modules?.ConfigClientPanel ?? {}
  );

export const selectModuleFakturownia = data =>
  deepmerge(initialState.modules.Fakturownia, data?.modules?.Fakturownia ?? {});

export const selectModuleGoogleTagManager = data =>
  deepmerge(
    initialState.modules.GoogleTagManager,
    data?.modules?.GoogleTagManager ?? {}
  );

export const selectModuleHover = data =>
  deepmerge(initialState.modules.Hover, data?.modules?.Hover ?? {});

export const selectModuleItemPaymentTypes = data =>
  deepmerge(
    initialState.modules.ItemPaymentTypes,
    data?.modules?.ItemPaymentTypes ?? {}
  );

export const selectModuleLinks = data =>
  deepmerge(initialState.modules.Links, data?.modules?.Links ?? {});

export const selectModuleMenuPage = data =>
  deepmerge(initialState.modules.MenuPage, data?.modules?.MenuPage ?? {});

export const selectModuleMoneyBox = data =>
  deepmerge(initialState.modules.MoneyBox, data?.modules?.MoneyBox ?? {});

export const selectModuleMyLead = data =>
  deepmerge(initialState.modules.MyLead, data?.modules?.MyLead ?? {});

export const selectModulePayU = data =>
  deepmerge(initialState.modules.PayU, data?.modules?.PayU ?? {});

export const selectModulePollsterTrack = data =>
  deepmerge(
    initialState.modules.PollsterTrack ?? {},
    data?.modules?.PollsterTrack ?? {}
  );

export const selectModuleSeo = data =>
  deepmerge(initialState.modules.Seo, data?.modules?.Seo ?? {});

export const selectModuleSeoAddon = data =>
  deepmerge(initialState.modules.SeoAddon, data?.modules?.SeoAddon ?? {});

export const selectModuleSeoDiet = data =>
  deepmerge(initialState.modules.SeoDiet, data?.modules?.SeoDiet ?? {});

export const selectModuleSeoDish = data =>
  deepmerge(initialState.modules.SeoDish, data?.modules?.SeoDish ?? {});

export const selectModuleSeoZone = data =>
  deepmerge(initialState.modules.SeoZone, data?.modules?.SeoZone ?? {});

export const selectModuleBlog = data =>
  deepmerge(initialState.modules.Blog, data?.modules?.Blog ?? {});

export const selectModuleSeoCms = data =>
  deepmerge(initialState.modules, {
    SeoCmsAddonsSide: data?.modules?.SeoCmsAddonsSide ?? {},
    SeoCmsClientPanelLogin: data?.modules?.SeoCmsClientPanelLogin ?? {},
    SeoCmsClientPanelMyAccount: data?.modules?.SeoCmsClientPanelMyAccount ?? {},
    SeoCmsDietSide: data?.modules?.SeoCmsDietSide ?? {},
    SeoCmsMainSite: data?.modules?.SeoCmsMainSite ?? {},
    SeoCmsMenuSubpage: data?.modules?.SeoCmsMenuSubpage ?? {},
    SeoCmsZonesSubpage: data?.modules?.SeoCmsZonesSubpage ?? {},
    SeoCmsDishSide: data?.modules?.SeoCmsDishSide ?? {},
    SeoCmsClientPanelRegister: data?.modules?.SeoCmsClientPanelRegister ?? {},
    Blog: data?.modules?.Blog ?? {},
  });

export const selectModuleShop = data =>
  deepmerge(initialState.modules.Shop, data?.modules?.Shop ?? {});

export const selectModuleStripe = data =>
  deepmerge(initialState.modules.Stripe, data?.modules?.Stripe ?? {});

export const selectMultinational = data =>
  deepmerge(initialState.multinational, data?.multinational ?? {});

export const selectWeekDays = data =>
  deepmerge(initialState.weekDays, data?.weekDays ?? {});

export const selectDietWeekDays = data =>
  deepmerge(initialState.dietWeekDays, data?.dietWeekDays ?? {});

export const selectPanelOptions = data =>
  deepmerge(initialState.panelOptions, data?.panelOptions ?? {});

export const selectPages = data =>
  deepmerge(initialState.pages, data?.pages ?? {});

export const selectDisabledDays = data =>
  deepmerge([], data?.disabledDays ?? []);

export const selectMenuSelectionAssistant = data => {
  return deepmerge(
    initialState.modules.MenuSelectionAssistant,
    data?.modules?.MenuSelectionAssistant ?? {}
  );
};

export const selectLastPossibleOrderDay = data => {
  const maximumDaysAllowedToOrderInFuture =
    data?.modules?.ConfigClientPanel?.maximumDaysAllowedToOrderInFuture;

  if (isNumber(maximumDaysAllowedToOrderInFuture)) {
    return addDays(new Date(), maximumDaysAllowedToOrderInFuture);
  }

  return null;
};

export const selectFirstPossibleOrderDayByWeekDays = data => {
  let startDay = new Date();
  let firstEnabledDay = null;

  while (!isDate(firstEnabledDay)) {
    const day = fnsFormat(startDay, 'EEEE', { locale: enUS });
    const newOrderDay = data?.weekDays?.[day.toLowerCase()]?.newOrder;
    const orderDayDeadline = subMinutes(
      new Date(`${format(startDay)} 00:00:00`),
      newOrderDay
    );

    if (isAfter(orderDayDeadline, new Date())) {
      firstEnabledDay = startDay;
    }

    startDay = addDays(startDay, 1);
  }

  return firstEnabledDay;
};

export const selectIsBlockDeliveryEnabled = data => {
  const days = [
    { name: 'monday', isoWeek: 1 },
    { name: 'tuesday', isoWeek: 2 },
    { name: 'wednesday', isoWeek: 3 },
    { name: 'thursday', isoWeek: 4 },
    { name: 'friday', isoWeek: 5 },
    { name: 'saturday', isoWeek: 6 },
    { name: 'sunday', isoWeek: 7 },
  ];

  const isAnyDayDeliveredInBlock = days.find(day => {
    const shouldBeDeliveredInBlock =
      data?.weekDays?.[day.name]?.shouldBeDeliveredInBlock;

    return (
      shouldBeDeliveredInBlock > 0 && shouldBeDeliveredInBlock !== day?.isoWeek
    );
  });

  return !!isAnyDayDeliveredInBlock;
};

export const selectDisabledDaysOfWeek = data => {
  const days = [
    { name: 'monday', isoWeek: 1 },
    { name: 'tuesday', isoWeek: 2 },
    { name: 'wednesday', isoWeek: 3 },
    { name: 'thursday', isoWeek: 4 },
    { name: 'friday', isoWeek: 5 },
    { name: 'saturday', isoWeek: 6 },
    { name: 'sunday', isoWeek: 7 },
  ];
  const disabledDaysOfWeek = [];

  days.map(day => {
    if (data?.weekDays?.[day.name]?.shouldBeDeliveredIn === 0) {
      disabledDaysOfWeek.push(day.isoWeek);
    }
  });

  return disabledDaysOfWeek;
};
