import first from 'lodash/first';
import last from 'lodash/last';

import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import GTM_ITEM_XML_CATEGORIES from '@contexts/hooks/constants/gtmItemXmlCategories';
import { format } from '@services/Date.service';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

export const pushEventToDataLayer = event => {
  if (!window?.dataLayer) return;
  window.dataLayer.push({ ...event });
};

const getGtmItems = ({ items, brandName, discountCode }) => {
  return items.reduce((acc, item, index) => {
    return [
      ...acc,
      ...convertToGtmItem({
        index,
        item,
        brandName,
        discountCode,
      }),
    ];
  }, []);
};

export const pushLogoGTMEvent = pagePath => {
  pushEventToDataLayer({
    event: 'logo',
    pagePath,
  });
};

export const pushLoginGTMEvent = ({ provider, ctaType, pagePath, userId }) => {
  pushEventToDataLayer({
    event: 'login',
    method: provider,
    action: ctaType,
    pagePath,
    userId,
  });
};

export const pushRegisterGTMEvent = ({
  provider,
  ctaType,
  pagePath,
  userId,
}) => {
  pushEventToDataLayer({
    event: 'register',
    method: provider,
    action: ctaType,
    pagePath,
    userId,
  });
};

export const pushCheckoutStepGTMEvent = ({ step }) => {
  pushEventToDataLayer({
    event: 'checkoutStep',
    step,
  });
};

export const pushPageViewGTMEvent = linkToPage => {
  pushEventToDataLayer({
    event: 'pageView',
    link: linkToPage,
  });
};

export const pushClickMenuGTMEvent = ({
  text,
  action = 'click',
  level = 'sub0',
}) => {
  pushEventToDataLayer({
    event: 'menu',
    text,
    action, // click | dropdown menu
    level, // sub0 | sub1 | sub2
  });
};

const convertAddonToGtmItem = ({ brandName, index, item }) => {
  return {
    item_id: item?.addon?.id?.toString() || '',
    item_xml_id: item?.addon?.id
      ? `${GTM_ITEM_XML_CATEGORIES.addon}_${item.addon.id}`
      : '',
    item_name: item?.addon?.name || '',
    coupon: '',
    discount: item?.price?.discount || 0,
    index,
    item_brand: brandName,
    item_category: 'Dodatek',
    item_category2: '',
    item_category3: '',
    item_category4: '',
    item_category5: '',
    item_category6: false,
    item_category7: 0,
    item_category8: '',
    item_category9: '',
    item_category10: '',
    item_category11: '',
    item_category12: item?.type, // typ dostawy dodatku
    price: parseFloat(getIntegerIfIntegerOrFixed(item?.addon?.clientCost || 0)), // TODO: OrderItemDiet - API
    quantity: item?.quantity || 0,
  };
};

const convertToGtmItem = ({ brandName, discountCode, index, item }) => {
  switch (item['@type']) {
    case 'BasketItemDiet': {
      const isSubscriptionPayment =
        item?.paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;

      const addons = (item?.addons || []).reduce((acc, item, index) => {
        return [
          ...acc,
          convertAddonToGtmItem({
            brandName,
            index,
            item,
          }),
        ];
      }, []);

      return [
        {
          item_id: (item?.diet?.id || item?.id)?.toString() || '',
          item_xml_id:
            item?.diet?.id && item?.variant?.id && item?.calorific?.id
              ? `${GTM_ITEM_XML_CATEGORIES.diet}_${item.diet.id}_${item.variant.id}_${item.calorific.id}`
              : '',
          item_name: item?.diet?.name || item?.name || '',
          coupon: discountCode || '',
          discount: item?.price?.discount || 0,
          index,
          item_brand: brandName,
          item_category: 'Dieta', // typ elementu Dieta | Danie
          item_category2: item?.diet?.name || item?.name || '', // nazwa diety - Wege
          item_category3: item?.variant?.name || item?.variantName || '', // wariant diety - 3 posiłki
          item_category4: item?.calorific?.name || item?.calorificName || '', // kaloryczność - ok. 5kg - 400kcal
          item_category5: '', // Wielkość dania
          item_category6: isSubscriptionPayment,
          item_category7: parseFloat(
            getIntegerIfIntegerOrFixed(
              (item.price.priceParts?.DIET ?? item.price.afterDiscount) /
                item.dietLength
            )
          ), // cena za dzień
          item_category8:
            item?.options?.saturdayInclude || item?.saturdayInclude || '',
          item_category9:
            item?.options?.sundayInclude || item?.sundayInclude || '',
          item_category10: (first(item?.days) ?? item?.firstDeliveryDay) || '',
          item_category11:
            (isSubscriptionPayment
              ? ''
              : last(item?.days ?? item?.deliveryDates)) || '',
          item_category12: '', // typ dostawy dodatku
          price: parseFloat(
            getIntegerIfIntegerOrFixed(item?.price?.afterDiscount || 0)
          ),
          quantity: item?.quantity || 0,
          duration: item?.dietLength || 0,
        },
        ...addons,
      ];
    }

    case 'BasketItemDish':
      return [
        {
          item_id: item?.dishSize?.id?.toString() || '',
          item_xml_id: item?.dishSize?.id
            ? `${GTM_ITEM_XML_CATEGORIES.dish}_${item.dishSize.id}`
            : '',
          item_name: item?.dishSize?.dish?.nameForClient || '',
          coupon: discountCode || '',
          discount: item?.price?.discount || 0,
          index,
          item_brand: brandName,
          item_category: 'Danie', // typ elementu Dieta | Danie
          item_category2: '', // nazwa diety - Wege
          item_category3: '', // wariant diety - 3 posiłki
          item_category4: '', // kaloryczność - ok. 5kg - 400kcal
          item_category5: item?.dishSize?.size?.nameForClient || '', // Wielkość dania
          item_category6: false,
          item_category7: 0, // cena za dzień
          item_category8: '',
          item_category9: '',
          item_category10: item?.day,
          item_category11: item?.day,
          item_category12: '',
          price: parseFloat(
            getIntegerIfIntegerOrFixed(item?.price?.afterDiscount || 0)
          ),
          quantity: item?.quantity || 0,
        },
      ];

    case 'OrderItemDayDish': {
      return [
        {
          item_id: item?.id?.toString() || '',
          // TODO: API
          // item_name: item?.dishSize?.dish?.nameForClient || '',
          item_xml_id: item?.id
            ? `${GTM_ITEM_XML_CATEGORIES.dish}_${item.id}`
            : '',
          coupon: discountCode || '',
          discount: item?.priceV2?.discount || 0,
          index,
          item_brand: brandName,
          item_category: 'Danie', // typ elementu Dieta | Danie
          item_category2: '', // nazwa diety - Wege
          item_category3: '', // wariant diety - 3 posiłki
          item_category4: '', // kaloryczność - ok. 5kg - 400kcal
          // TODO: API
          // item_category5: item?.dishSize?.size?.nameForClient || '', // Wielkość dania
          item_category6: false,
          item_category7: 0, // cena za dzień
          item_category8: '',
          item_category9: '',
          item_category10: format(new Date(item?.day)),
          item_category11: format(new Date(item?.day)),
          item_category12: '',
          price: parseFloat(
            getIntegerIfIntegerOrFixed(item?.priceV2?.afterDiscount || 0)
          ),
          quantity: item?.quantity || 0,
        },
      ];
    }

    case 'BasketItemAddon':
      return [
        {
          item_id: item?.addon?.id?.toString() || '',
          item_xml_id: item?.addon?.id
            ? `${GTM_ITEM_XML_CATEGORIES.addon}_${item.addon.id}`
            : '',
          item_name: item?.addon?.name || '',
          coupon: discountCode || '',
          discount: item?.price?.discount || 0,
          index,
          item_brand: brandName,
          item_category: 'Dodatek', // typ elementu Dieta | Danie
          item_category2: '', // nazwa diety - Wege
          item_category3: '', // wariant diety - 3 posiłki
          item_category4: '', // kaloryczność - ok. 5kg - 400kcal
          item_category5: '', // Wielkość dania
          item_category6: false,
          item_category7: 0, // cena za dzień
          item_category8: '',
          item_category9: '',
          item_category10: item?.day,
          item_category11: item?.day,
          item_category12: item?.addon?.type, // typ dostawy dodatku
          price: parseFloat(
            getIntegerIfIntegerOrFixed(item?.price?.afterDiscount || 0)
          ),
          quantity: item?.quantity || 0,
        },
      ];

    case 'OrderItemDiet': {
      const discountPrice = item?.priceV2?.discount || 0;
      const isSubscriptionPayment =
        item?.paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;

      const addons = (item?.addons || []).reduce((acc, item, index) => {
        return [
          ...acc,
          convertAddonToGtmItem({
            brandName,
            index,
            item,
          }),
        ];
      }, []);

      return [
        {
          item_id: item?.offerDiet?.id?.toString() || '',
          item_xml_id:
            item?.offerDiet?.id && item?.variant?.id && item?.calorific?.id
              ? `${GTM_ITEM_XML_CATEGORIES.diet}_${item.offerDiet.id}_${item.variant.id}_${item.calorific.id}`
              : '',
          item_name: item?.offerDiet?.name || '',
          coupon: discountCode || '',
          discount: discountPrice,
          index,
          item_brand: brandName,
          item_category: 'Dieta', // typ elementu Dieta | Danie
          item_category2: item?.offerDiet?.name || '', // nazwa diety - Wege
          item_category3: item?.variant?.name || '', // wariant diety - 3 posiłki
          item_category4: item?.calorific?.name || '', // kaloryczność - ok. 5kg - 400kcal
          item_category5: '', // Wielkość dania
          item_category6: isSubscriptionPayment,
          item_category7: parseFloat(
            getIntegerIfIntegerOrFixed(
              item?.priceV2?.afterDiscount / (item?.days?.length || 0)
            )
          ), // cena za dzień
          item_category8: item?.dietOptions?.saturdayInclude || '',
          item_category9: item?.dietOptions?.sundayInclude || '',
          item_category10: first(item?.days),
          item_category11: isSubscriptionPayment ? '' : last(item?.days),
          item_category12: '', // typ dostawy dodatku
          price: parseFloat(
            getIntegerIfIntegerOrFixed(item?.priceV2?.afterDiscount || 0)
          ),
          quantity: 1,
          duration: item?.days?.length || 0,
        },
        ...addons,
      ];
    }

    default: {
      return [];
    }
  }
};

export const pushViewItemGTMEvent = ({
  brandName,
  currencyCode,
  discountCode = '',
  items = [],
  price = 0,
}) => {
  pushEventToDataLayer({
    event: 'view_item',
    ecommerce: {
      currency: currencyCode,
      value: price,
      items: getGtmItems({ items, brandName, discountCode }),
    },
  });
};

export const pushAddShippingInfoGTMEvent = ({
  brandName,
  currencyCode,
  discountCode = '',
  items = [],
  price = 0,
  shipping,
}) => {
  pushEventToDataLayer({
    event: 'add_shipping_info',
    ecommerce: {
      currency: currencyCode,
      value: price,
      coupon: discountCode,
      shipping_tier: shipping,
      items: getGtmItems({ items, brandName, discountCode }),
    },
  });
};

export const pushViewCartGTMEvent = ({
  brandName,
  currencyCode,
  discountCode = '',
  items = [],
  price = 0,
}) => {
  pushEventToDataLayer({
    event: 'view_cart',
    ecommerce: {
      currency: currencyCode,
      value: price,
      items: getGtmItems({ items, brandName, discountCode }),
    },
  });
};

export const pushViewCartNewGTMEvent = ({
  brandName,
  currencyCode,
  discountCode = '',
  items = [],
  price = 0,
}) => {
  pushEventToDataLayer({
    event: 'view_cart_new',
    ecommerce: {
      currency: currencyCode,
      value: price,
      items: getGtmItems({ items, brandName, discountCode }),
    },
  });
};

export const pushAddPaymentInfoGTMEvent = ({
  brandName,
  currencyCode,
  discountCode = '',
  items = [],
  paymentType,
  price = 0,
}) => {
  pushEventToDataLayer({
    event: 'add_payment_info',
    ecommerce: {
      currency: currencyCode,
      value: price,
      coupon: discountCode,
      payment_type: paymentType,
      items: getGtmItems({ items, brandName, discountCode }),
    },
  });
};

export const pushBeginCheckoutGTMEvent = ({
  brandName,
  currencyCode,
  discountCode = '',
  items = [],
  price = 0,
}) => {
  pushEventToDataLayer({
    event: 'begin_checkout',
    ecommerce: {
      currency: currencyCode,
      value: price,
      coupon: discountCode,
      items: getGtmItems({ items, brandName, discountCode }),
    },
  });
};

export const pushBeginPurchaseGTMEvent = ({
  brandName,
  currencyCode,
  discountCode = '',
  items = [],
  price = 0,
  paymentType = '',
  shippingCost = 0,
  transactionId = '',
}) => {
  const isSubscription = items.some(
    item => item.paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT
  );

  pushEventToDataLayer({
    event: 'begin_purchase',
    ecommerce: {
      currency: currencyCode,
      value: price,
      coupon: discountCode,
      payment_type: isSubscription ? `${paymentType}_SUB` : paymentType || '',
      shipping: shippingCost,
      transaction_id: transactionId.toString(),
      items: getGtmItems({ items, brandName, discountCode }),
    },
  });
};

export const pushPurchaseGTMEvent = ({
  brandName,
  currencyCode,
  orderDetails,
  eventName = 'purchase',
}) => {
  const {
    id,
    items,
    discountCode,
    paymentType,
    priceLeftToPay,
    priceAfterDiscount,
    pricePayed,
    isTest,
  } = orderDetails;
  let value = 0;

  if (eventName === 'prePurchase') {
    value = priceAfterDiscount;
  } else if (paymentType === 'STRIPE_CARD') {
    value =
      priceLeftToPay === 0 && pricePayed !== 0 ? pricePayed : priceLeftToPay;
  } else if (paymentType === 'BANK_WIRE' || paymentType === 'CASH') {
    value = priceLeftToPay;
  } else {
    value = pricePayed;
  }

  const shippingCost = items.reduce(
    (acc, { priceV2 }) => (acc += priceV2?.priceParts?.DELIVERY || 0),
    0
  );

  const isBagModification = items.some(item => item.type === 'BagModification');

  const isSubscription = items.some(
    item => item.paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT
  );

  const event = {
    event: eventName,
    ecommerce: {
      currency: currencyCode, // REQUIRED
      transaction_id: id?.toString(), // REQUIRED
      value, // REQUIRED
      coupon: discountCode?.code || '',
      shipping: shippingCost,
      // tax: 0,
      payment_type: isSubscription ? `${paymentType}_SUB` : paymentType || '',
      items: !isBagModification
        ? items.reduce((acc, item, index) => {
            return [
              ...acc,
              ...convertToGtmItem({
                index,
                item,
                brandName,
                discountCode: discountCode?.code || '',
              }),
            ];
          }, [])
        : [], //'Bag/s Modification',
      testMode: isTest,
    },
  };

  pushEventToDataLayer(event);
};

export const pushPrePurchaseGTMEvent = ({
  brandName,
  currencyCode,
  orderDetails,
}) => {
  pushPurchaseGTMEvent({
    brandName,
    currencyCode,
    orderDetails,
    eventName: 'prePurchase',
  });
};

export const pushDiscountCodeUpdateGTMEvent = ({
  discountCode = null,
  items = [],
  brandName = '',
  currencyCode,
  price = 0,
} = {}) => {
  pushEventToDataLayer({
    event: 'update_discount_code',
    discountCode,
    ecommerce: {
      currency: currencyCode,
      value: price,
      items: getGtmItems({ items, brandName, discountCode }),
    },
  });
};

export const pushUIDGTMEvent = ({ userId, email }) => {
  pushEventToDataLayer({
    event: 'UID',
    email,
    userId,
  });
};
