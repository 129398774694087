import Head from 'next/head';

import produce from 'immer';
import _isEmpty from 'lodash/isEmpty';

import { BASE_URL } from '@constants/endpoint';
import { slugify } from '@utils/helpers';

const FontLink = ({ fonts }) => {
  if (!fonts) return null;

  const { clientText, clientTitle } = fonts;
  const { components: headLinks } = [clientText, clientTitle].reduce(
    (acc, { font, library, libraryParams }) => {
      const nextState = produce(acc, draftState => {
        if (_isEmpty(font)) {
          return;
        }

        if (library === 'google') {
          if (!draftState.hasGoogleFont) {
            draftState.hasGoogleFont = true;
            draftState.components.push(
              <link
                rel="preconnect"
                href="https://fonts.googleapis.com"
                key="preconnect-googleapi"
              />,
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                key="preconnect-gstatic"
                crossOrigin="true"
              />
            );
          }

          draftState.components.push(
            <link
              key={`gfont-${font}`}
              href={`https://fonts.googleapis.com/css2?family=${font}:wght@400;500;600;700&display=swap`}
              rel="stylesheet"
              type="text/css"
            />
          );
        }

        if (library === 'typekit') {
          draftState.components.push(
            <link
              href={`https://use.typekit.net/${libraryParams.id}.css`}
              rel="stylesheet"
              type="text/css"
            />
          );
        }

        if (library === 'custom') {
          const slug = slugify(font);
          const url = `${BASE_URL}/static/fonts/${slug}/${slug}.css`;

          draftState.components.push(
            <link href={url} rel="stylesheet" type="text/css" />
          );
        }
      });

      return nextState;
    },
    { components: [], hasGoogleFont: false }
  );

  return <Head>{headLinks}</Head>;
};

export default FontLink;
