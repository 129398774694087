import { useEffect } from 'react';

const useOverflowBody = (dependencies = [], conditional = true) => {
  useEffect(() => {
    if (conditional) {
      document.body.style.setProperty('overflow', 'hidden');
    } else {
      document.body.style.removeProperty('overflow');
    }

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, [...dependencies]);
};

export default useOverflowBody;
