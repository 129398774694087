import type { CustomStyles } from '@tsTypes/customStyles';
import { css, theme } from 'twin.macro';

export const screen = Object.entries(theme`screens`).reduce(
  (acc, [key, value]) => {
    return {
      ...acc,
      [key]: (...args: TemplateStringsArray[]) => {
        return css`
          @media (min-width: ${value}) {
            ${css(...args)}
          }
        `;
      },
    };
  },
  {}
);

const flatCss = (styles: CustomStyles) => {
  return Array.isArray(styles)
    ? styles.flat().reduce((acc, item) => ({ ...acc, ...item }), {})
    : styles;
};

export const cssMerge = ({
  mode = 'merge',
  defaultCss = null,
  css = null,
}: {
  mode?: 'merge' | 'replace';
  defaultCss?: CustomStyles;
  css?: CustomStyles;
}) => {
  if (mode === 'replace') {
    return css === null ? null : css;
  }

  return defaultCss === null && css === null
    ? null
    : { ...flatCss(defaultCss), ...css };
};
