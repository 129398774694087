import dynamic from 'next/dynamic';

import { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';

import ErrorBoundary from '@components/elements/ErrorBoundary';
import MissingDataModal from '@components/elements/MissingDataModal/MissingDataModal.js';
import BasketMethodsProvider from '@contexts/BasketMethods.context';

import AppListener from './AppListener.js';

const ApiConnectionProblem = dynamic(() =>
  import('@components/elements/ApiConnectionProblem')
);
const DefaultLayout = dynamic(() =>
  import('@components/layouts/DefaultLayout')
);

const AppOrCrash = ({ Component, pageProps }) => {
  const Layout = Component.Layout || DefaultLayout;

  if (pageProps.configError) {
    return <ApiConnectionProblem />;
  }

  return (
    <Fragment>
      <AppListener {...pageProps} />
      <BasketMethodsProvider {...pageProps}>
        <Layout>
          <MissingDataModal />
          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>
          <ToastContainer />
        </Layout>
      </BasketMethodsProvider>
    </Fragment>
  );
};

export default AppOrCrash;
