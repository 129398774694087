import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import BasketService from '@services/Basket.service';
import axios from '@utils/axiosInstance';

const updateBasketModify = async ({ id, payload = {} }) => {
  const response = await axios.put(ENDPOINT.BASKET_MODIFY(id), payload, {
    errorHandle: false,
  });

  const data = response?.data ?? {};
  const uiRows = BasketService.transformResponseBasketUI(data);

  return { ...data, uiRows };
};

const useUpdateBasketModify = ({ basketKey, options = {} } = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: updateBasketModify,
    mutationKey: [`${basketKey}UpdateModify`],
    onSuccess: (response, request) => {
      if (request?.payload?.abandoned !== true) {
        queryClient.setQueryData([basketKey], () => response);
      }
    },
    ...options,
  });

  return query;
};

export default useUpdateBasketModify;
