import { getCookie } from 'cookies-next';
import produce from 'immer';
import decode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';

import { ENDPOINT } from '@constants/endpoint';
import ADDRESS_INPUT_IDS from '@constants/inputs/addressInputs';
import axios from '@utils/axiosInstance';

const resetPassword = async email => {
  const response = await axios.post(
    ENDPOINT.SEND_PASSWORD_RESET_EMAIL,
    { email },
    {
      errorHandle: false,
    }
  );

  return response?.data ?? {};
};

const setNewPassword = async requestPayload => {
  const response = await axios.put(ENDPOINT.RESET_PASSWORD, requestPayload);

  return response?.data ?? {};
};

const refreshToken = async () => {
  const response = await axios.put(ENDPOINT.REFRESH_LOGIN, {
    refreshToken: getCookie('refreshToken'),
  });

  const user = response?.data?.token ? decode(response?.data?.token) : {};

  return {
    ...(response?.data ?? {}),
    user: {
      ...user,
      id: user?.humanReadableId,
      phone: { number: user?.phone, countryCode: user?.phoneCountryCode },
    },
  };
};

const updateData = async requestPayload => {
  const response = await axios.put(ENDPOINT.USER_DATA, requestPayload);

  return response?.data ?? {};
};

const changePassword = async requestPayload => {
  const response = await axios.put(ENDPOINT.CHANGE_PASSWORD, requestPayload);

  return response?.data ?? {};
};

const transformAddressFormData = ({
  formData,
  allowedFieldsSettings,
  useAddressesWithLessFields,
}) => {
  const newState = produce(formData, draftState => {
    if (!isEmpty(draftState[ADDRESS_INPUT_IDS.DELIVERY_HOURS]?.value)) {
      const selectedDeliveryHour = JSON.parse(
        draftState[ADDRESS_INPUT_IDS.DELIVERY_HOURS].value
      );
      const { hourFrom, hourTo } = selectedDeliveryHour;
      draftState.deliveryHourFrom = hourFrom;
      draftState.deliveryHourTo = hourTo;
      draftState[ADDRESS_INPUT_IDS.DELIVERY_HOURS] = selectedDeliveryHour;
    } else {
      draftState.deliveryHourFrom = '';
      draftState.deliveryHourTo = '';
    }

    if (useAddressesWithLessFields) {
      const addressLine1 = draftState[ADDRESS_INPUT_IDS.ADDRESS_LINE1];
      const addressLine2 = draftState[ADDRESS_INPUT_IDS.ADDRESS_LINE2];

      draftState[ADDRESS_INPUT_IDS.STREET] = `${addressLine1}; ${addressLine2}`;
      draftState[ADDRESS_INPUT_IDS.HOUSE_NUMBER] = '0';

      delete draftState[ADDRESS_INPUT_IDS.ADDRESS_LINE1];
      delete draftState[ADDRESS_INPUT_IDS.ADDRESS_LINE2];
    } else {
      draftState[ADDRESS_INPUT_IDS.FLOOR] = parseInt(draftState.floor);
    }

    if (allowedFieldsSettings?.hasAllowedCities) {
      const city = draftState[ADDRESS_INPUT_IDS.CITY_OPTION];

      draftState[ADDRESS_INPUT_IDS.CITY] = city?.value;

      delete draftState[ADDRESS_INPUT_IDS.CITY_OPTION];
    }

    if (allowedFieldsSettings?.hasAllowedStreets) {
      const street = draftState[ADDRESS_INPUT_IDS.STREET_OPTION];

      draftState[ADDRESS_INPUT_IDS.STREET] = street?.value;

      delete draftState[ADDRESS_INPUT_IDS.STREET_OPTION];
    }

    if (allowedFieldsSettings?.hasAllowedBuildingNumbers) {
      const buildNumber = draftState[ADDRESS_INPUT_IDS.HOUSE_NUMBER_OPTION];

      draftState[ADDRESS_INPUT_IDS.HOUSE_NUMBER] = buildNumber?.value;

      delete draftState[ADDRESS_INPUT_IDS.HOUSE_NUMBER_OPTION];
    }

    delete draftState[ADDRESS_INPUT_IDS.DELIVERY_HOURS];
  });

  const newFormData = Object.entries(newState).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: typeof value === 'string' && isEmpty(value) ? null : value,
    };
  }, {});

  return newFormData;
};

const sortAddressesByDefaultFirst = array => {
  const newArr = [
    ...array.filter(item => item.isDefault === true),
    ...array.filter(item => item.isDefault === false),
  ];

  return newArr;
};

export default {
  changePassword,
  refreshToken,
  resetPassword,
  setNewPassword,
  updateData,
  transformAddressFormData,
  sortAddressesByDefaultFirst,
};
