const TimesIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 261 262"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M130.461 107.854L233.686 4.686c6.249-6.248 16.379-6.248 22.627 0 6.249 6.249 6.249 16.379 0 22.631L153.09 130.484l103.203 103.202c6.249 6.249 6.249 16.379 0 22.628-3.136 3.116-7.228 4.694-11.302 4.694-4.096 0-8.189-1.558-11.305-4.694L130.467 153.107 27.317 256.314a16.042 16.042 0 01-11.325 4.694c-4.097 0-8.193-1.558-11.306-4.694-6.248-6.249-6.248-16.379 0-22.628l103.15-103.207L4.686 27.317c-6.248-6.252-6.248-16.382 0-22.631 6.248-6.248 16.379-6.248 22.631 0l103.144 103.168z"
      fillRule="nonzero"
    />
  </svg>
);

export default TimesIcon;
