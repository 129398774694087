import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import BasketService from '@services/Basket.service';
import axios from '@utils/axiosInstance';
import showToast from '@utils/showToast';

const getCreateBasket = async ({
  basketId,
  ecommerceDiet,
  withDefaultDiet,
  createFromLatestDiet,
} = {}) => {
  const response = await axios.post(ENDPOINT.BASKET_CREATE, {
    withDefaultDiet,
    createFromLatestDiet,
    ...(basketId ? { basket: `/v2/frontend/baskets/${basketId}` } : {}),
    ...(ecommerceDiet ? { ecommerceDiet } : {}),
  });

  const data = response?.data ?? {};
  const uiRows = BasketService.transformResponseBasketUI(data);

  return { ...data, uiRows };
};

const useCreateBasket = ({ basketKey, options = {} } = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: getCreateBasket,
    mutationKey: [`${basketKey}Create`],
    onSuccess: response => {
      queryClient.setQueryData([basketKey], response);
    },
    onError: err => {
      const error = err?.response?.data;

      showToast(error, { type: 'error' });
    },
    retry: 3,
    ...options,
  });

  return query;
};

export default useCreateBasket;
