import { getCookie, setCookie } from 'cookies-next';

import TIME from '@constants/time';

export const setMyLeadTrackingParams = ({ clickId, userId }) => {
  if (clickId) {
    setCookie('clickId', clickId, { maxAge: TIME.MONTH_IN_SECONDS });
  }

  if (userId) {
    setCookie('userId', userId, { maxAge: TIME.MONTH_IN_SECONDS });
  }
};

export const getMyLeadTrackingParams = () => {
  const userId = getCookie('userId');
  const clickId = getCookie('clickId');

  return { clickId, userId };
};
