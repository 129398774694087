import isEmpty from 'lodash/isEmpty';

import { isFalsify } from '@utils/helpers';

import { format } from './Date.service';

export const getIRI = ({ '@id': iri, id, resource }) => {
  if (!isEmpty(iri)) {
    return iri;
  }

  if (!isEmpty(id)) {
    return `/${resource}/${id}`;
  }

  return null;
};

export const getIDfromIRI = (iri = '') => {
  if (isFalsify(iri)) return iri;

  const arraySplitted = iri.split('/');
  const lastElement = arraySplitted.pop();

  return parseInt(lastElement);
};

export const getImageUrlFromResources = (media = null, resources = {}) => {
  if (isEmpty(media)) {
    return null;
  }

  return resources[media];
};

export const transformDeliveryHourToString = ({
  hour = {},
  translations = {
    bothHours: { from: '', connector: '', to: '' },
    singularHour: { from: '', to: '' },
  },
  useDefaultTranslations = false,
  t,
}) => {
  const hasBothDeliveryHours = hour?.hourFrom && hour?.hourTo;
  const defaultTranslations = {
    bothHours: {
      from: null,
      connector: '-',
      to: null,
    },
    singularHour: { from: t('$*common.from'), to: t('$*common.to') },
  };
  const translationsText = useDefaultTranslations
    ? defaultTranslations
    : translations;

  return [
    hour?.hourFrom &&
      [
        ...(hasBothDeliveryHours
          ? [translationsText?.bothHours?.from]
          : [translationsText?.singularHour?.from]),
        format(new Date(hour.hourFrom), 'HH:mm'),
      ].join(' '),
    hasBothDeliveryHours ? translationsText?.bothHours?.connector : '',
    hour?.hourTo &&
      [
        ...(hasBothDeliveryHours
          ? [translationsText?.bothHours?.to]
          : [translationsText?.singularHour?.to]),
        format(new Date(hour.hourTo), 'HH:mm'),
      ].join(' '),
  ]
    .join(' ')
    .replace(/  +/g, ' ')
    ?.trim();
};

export const transformDeliveryHourToOption = (hour = {}, t) => {
  return {
    default: hour?.default ?? false,
    value: JSON.stringify({ hourFrom: hour?.hourFrom, hourTo: hour?.hourTo }),
    label: transformDeliveryHourToString({
      hour,
      useDefaultTranslations: true,
      t,
    }),
  };
};

export const transformDeliveryHoursToArrayOptions = (hours = [], t) =>
  hours?.map(hour => transformDeliveryHourToOption(hour, t)) ?? [];

export default {
  getIDfromIRI,
  getImageUrlFromResources,
  getIRI,
  transformDeliveryHoursToArrayOptions,
  transformDeliveryHourToOption,
  transformDeliveryHourToString,
};

export const transformAllowedCitiesToArrayOptions = (allowedCities = {}) => {
  if (allowedCities?.allowCustom) return [];

  const cities = allowedCities?.cities?.['hydra:member'] ?? [];

  return cities.map(city => ({
    value: city,
    label: city,
  }));
};

export const transformAllowedStreetsToArrayOptions = (allowedStreets = {}) => {
  if (allowedStreets?.allowCustom) return [];

  const streets = allowedStreets?.streets?.['hydra:member'] ?? [];

  return streets.map(street => ({
    value: street,
    label: street,
  }));
};

export const transformAllowedBuildingNumbersToArrayOptions = (
  allowedBuildingNumbers = {}
) => {
  if (allowedBuildingNumbers?.allowCustom) return [];

  const buildingNumbers =
    allowedBuildingNumbers?.buildings?.['hydra:member'] ?? [];

  return buildingNumbers.map(buildingNumber => ({
    value: buildingNumber,
    label: buildingNumber,
  }));
};
