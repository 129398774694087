import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';
import type { ErrorObject } from 'serialize-error';
import tw from 'twin.macro';

import Box from '@components/elements/Box';
import Button from '@components/elements/Button';
import Seo from '@components/modules/Seo/Seo';
import ROUTE_URLS from '@constants/routeUrls';

type Props = {
  pageName: string;
  renderTitle: () => JSX.Element;
  renderDescription: () => JSX.Element;
  isServerError: boolean;
  errorPayload?: ErrorObject | null;
};

const ErrorPage = ({
  pageName,
  renderTitle,
  renderDescription,
  errorPayload,
  isServerError,
}: Props) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <>
      <Seo pageName={pageName} />
      <div className="container">
        <Box styles={{ css: tw`max-w-2xl mx-auto text-center` }}>
          {renderTitle()}
          {renderDescription()}
          {!isServerError && <div tw="my-7 border-b border-primary" />}
          <Button
            onClick={() => {
              router.push(ROUTE_URLS.HOME);
            }}
            styles={{ button: tw`mx-auto mb-5` }}
          >
            {t('$*errorPage.backToHomePage')}
          </Button>
          {!isEmpty(errorPayload) && (
            <div tw="bg-gray-0 border border-gray-1 rounded-sm p-2 break-words">
              {JSON.stringify(errorPayload).substring(0, 400)}
            </div>
          )}
        </Box>
      </div>
    </>
  );
};

export default ErrorPage;
