import GTM_ITEM_CATEGORIES from '../constants/gtmItemCategories';
import GTM_ITEM_XML_CATEGORIES from '../constants/gtmItemXmlCategories';

import getRoundedPrice from './getRoundedPrice';

const convertDishToGtmItem = ({
  brandName,
  basketDiscountCode,
  dish: { dishSize, day, price, quantity },
  totalQuantityInBasketRow,
}) => {
  return {
    item_id: `${dishSize.id}`,
    item_xml_id: `${GTM_ITEM_XML_CATEGORIES.dish}_${dishSize.id}`,
    item_name: dishSize.name,
    coupon: basketDiscountCode,
    discount: price.discount,
    index: 0,
    item_brand: brandName,
    item_category: GTM_ITEM_CATEGORIES.dish,
    item_category2: '',
    item_category3: '',
    item_category4: '',
    item_category5: dishSize.size.name,
    item_category6: false,
    item_category7: 0,
    item_category8: '',
    item_category9: '',
    item_category10: day,
    item_category11: day,
    item_category12: '',
    price: getRoundedPrice(price.afterDiscount / totalQuantityInBasketRow),
    quantity: Math.abs(quantity),
    duration: 1,
  };
};

export default convertDishToGtmItem;
