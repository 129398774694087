/* eslint-disable @typescript-eslint/no-var-requires */
const axios = require('axios');
const HttpBackend = require('i18next-http-backend/cjs');

const translationsUrl = `${process.env.API_ENDPOINT}/v2/frontend/translations/frontend/default`;

/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  debug: false,
  serializeConfig: false,
  i18n: {
    defaultLocale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'pl',
    locales: ['cs', 'de', 'en', 'nl', 'no', 'pl'],
    localeDetection: false,
  },
  use: [HttpBackend],
  backend: {
    loadPath: '{{lng}}', // {{lng}} will be replaced by the current locale in the request
    request: async (options, lng, payload, callback) => {
      try {
        const { data } = await axios.get(translationsUrl, {
          headers: {
            'X-C-KEY': process.env.NEXT_PUBLIC_X_C_KEY,
            'X-LOCALE': lng,
          },
        });

        callback(null, {
          data,
          status: 200,
        });
      } catch (error) {
        callback(error);
      }
    },
    reloadInterval: typeof window === 'undefined' && 60_000,
  },
};
