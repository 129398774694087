const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const DAYS_IN_MONTH = 30;
const DAYS_IN_WEEK = 7;

const DAY_IN_SECONDS = SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY;

const TIME = {
  DAY_IN_SECONDS,
  WEEK_IN_SECONDS: DAY_IN_SECONDS * DAYS_IN_WEEK,
  MONTH_IN_SECONDS: DAY_IN_SECONDS * DAYS_IN_MONTH,
};

export default TIME;
