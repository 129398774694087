const HouseIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    viewBox="0 0 28 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M14.7523 0.277083C14.3148 -0.0923611 13.678 -0.0923611 13.2405 0.277083L0.407126 11.166C-0.0838462 11.584 -0.14218 12.3181 0.271015 12.809C0.684209 13.3 1.4231 13.3583 1.91407 12.9451L3.1099 11.934V21C3.1099 23.1486 4.85018 24.8889 6.99879 24.8889H20.9988C23.1474 24.8889 24.8877 23.1486 24.8877 21V11.934L26.0787 12.9451C26.5696 13.3632 27.3085 13.3 27.7217 12.809C28.1349 12.3181 28.0766 11.5792 27.5856 11.166L14.7523 0.277083ZM5.44324 21V9.95556L13.9988 2.69792L22.5543 9.95556V21C22.5543 21.8604 21.8592 22.5556 20.9988 22.5556H18.6655V15.1667C18.6655 14.0924 17.7953 13.2222 16.721 13.2222H11.2766C10.2023 13.2222 9.33213 14.0924 9.33213 15.1667V22.5556H6.99879C6.13838 22.5556 5.44324 21.8604 5.44324 21ZM11.6655 22.5556V15.5556H16.3321V22.5556H11.6655Z"
      fill="currentColor"
    />
  </svg>
);

export default HouseIcon;
