import { useMutation, useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';

import { ENDPOINT } from '@constants/endpoint';
import BasketService from '@services/Basket.service';
import axios from '@utils/axiosInstance';

const basketClean = async ({ iri = null, id, payload = {} }) => {
  const url = isEmpty(iri) ? ENDPOINT.BASKET_CLEAN(id) : iri;
  const response = await axios.put(url, payload);

  const data = response?.data ?? {};
  const uiRows = BasketService.transformResponseBasketUI(data);

  return { ...data, uiRows };
};

const useBasketClean = ({ basketKey, options = {} } = {}) => {
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: basketClean,
    mutationKey: [`${basketKey}Clean`],
    onSuccess: response => {
      queryClient.setQueryData([basketKey], () => response);
    },
    ...options,
  });

  return query;
};

export default useBasketClean;
