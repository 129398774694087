import tw from 'twin.macro';

const headerSizeStyle = {
  sm: tw`py-4 pl-6 pr-12`,
  lg: tw`py-6 pl-8 pr-16`,
};

const contentSizeStyle = {
  sm: tw`mt-4 mr-6 mb-6 ml-6`,
  lg: tw`mt-6 mr-8 mb-8 ml-8`,
};

const footerSizeStyle = {
  sm: tw`px-6 py-4`,
  lg: tw`px-8 py-6`,
};

const styles = {
  header: ({ noBorder, size, styles }) => [
    tw`sticky top-0 bg-white z-50`,
    !noBorder && tw`border-b`,
    headerSizeStyle[size],
    styles,
  ],
  content: ({ size }) => [contentSizeStyle[size]],
  footer: ({ size }) => [
    tw`bg-gray-0 flex justify-between border-t border-gray-1`,
    footerSizeStyle[size],
  ],
};

export default styles;
