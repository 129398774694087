import getConfig from 'next/config';

import axios from 'axios';

import { BASE_URL } from '@constants/endpoint';

axios.defaults.withCredentials = true;
axios.defaults.withToken = true;

const { publicRuntimeConfig } = getConfig();
const hasXCKey = (publicRuntimeConfig.xcKey ??= false);

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    ...(hasXCKey
      ? { 'X-C-KEY': publicRuntimeConfig.xcKey }
      : {
          ...(publicRuntimeConfig.devFrontendKey
            ? { 'X-DEV-FRONTEND-KEY': publicRuntimeConfig.devFrontendKey }
            : {}),
          ...(publicRuntimeConfig.devFrontendOrigin
            ? { 'X-DEV-FRONTEND-ORIGIN': publicRuntimeConfig.devFrontendOrigin }
            : {}),
        }),
  },
});

export default instance;
