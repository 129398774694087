import debounce from 'lodash/debounce';

import BASKET_ACTIONS_DEBOUNCE_TIMEOUT from '@constants/basketActionsDebounceTimeout';
import ERROR_TYPES from '@constants/errorTypes';

const mutations = {};

const clearStaleRejects = mutationKey => {
  const mutationStaleRejects = mutations[mutationKey]?.staleRejects || [];

  if (mutationStaleRejects.length > 0) {
    mutationStaleRejects.pop();
    mutationStaleRejects.forEach(reject => {
      reject(ERROR_TYPES.STALE_PROMISE);
    });
    mutations[mutationKey].staleRejects = [];
  }
};

const getBasketDebounceHandler = ({
  shouldDebounce,
  mutationKey,
  mutationFn,
  data,
}) => {
  return new Promise((resolve, reject) => {
    if (shouldDebounce) {
      if (!(mutationKey in mutations)) {
        mutations[mutationKey] = {
          staleRejects: [],
          debouncedFn: debounce(mutationFn, BASKET_ACTIONS_DEBOUNCE_TIMEOUT),
        };
      }

      mutations[mutationKey].staleRejects.push(reject);
    }

    const mutationFnToInvoke = shouldDebounce
      ? mutations[mutationKey].debouncedFn
      : mutationFn;

    mutationFnToInvoke({
      data,
      resolve,
      reject,
      clearStaleRejects: () => {
        clearStaleRejects(mutationKey);
      },
    });
  });
};

export default getBasketDebounceHandler;
