import dynamic from 'next/dynamic';

import {
  type ChangeEvent,
  type ComponentType,
  type LabelHTMLAttributes,
  type ReactNode,
  type Ref,
  useMemo,
} from 'react';
import {
  type FieldInputProps,
  type FormikProps,
  ErrorMessage,
  Field,
} from 'formik';
import kebabCase from 'lodash/kebabCase';
import { type TwStyle } from 'twin.macro';

import ADDRESS_INPUT_FIELDS from '@constants/inputs/addressInputs';
import INVOICE_FIELDS from '@constants/inputs/invoiceInputs';
import INPUT_IDS from '@constants/inputs/paypoInputs';
import useFormikErrors from '@hooks/useFormikErrors';
import useLocale from '@hooks/useLocale';

import localeToCountryPhoneAbbr from './PhoneInput/localeToCountryPhoneAbbr';
import TextInput from './InputText';

const DefaultComponent = () => null;

const BtnGroupInput = dynamic(() => import('./BtnGroup'));
const CheckboxInput = dynamic(() => import('./Checkbox'));
const EmailInput = dynamic(() => import('./InputText'));
const FormGroup = dynamic(() => import('./FormGroup'));
const HelperText = dynamic(() => import('./HelperText'));
const HiddenInput = dynamic(() => import('./InputText'));
const Label = dynamic(() => import('./Label'));
const NumberInput = dynamic(() => import('./InputText'));
const PasswordInput = dynamic(() => import('./InputText'));
const PhoneInput = dynamic(() => import('./PhoneInput/PhoneInput'));
const RatingStarInput = dynamic(() => import('./RatingStar'));
const RatingThumbInput = dynamic(() => import('./RatingThumb'));
const SelectInput = dynamic(() => import('./Select'));
const SwitchInput = dynamic(() => import('./Switch'));
const TextareaInput = dynamic(() => import('./Textarea'));
const TextEmojiInput = dynamic(() => import('./InputEmoji/InputEmoji'));
const ToggleInput = dynamic(() => import('./Toggle'));
const InputDate = dynamic(() => import('./InputDate'));

const INPUTS: { [key in InputTypes]: ComponentType<any> } = {
  btnGroup: BtnGroupInput,
  checkbox: CheckboxInput,
  email: EmailInput,
  hidden: HiddenInput,
  number: NumberInput,
  password: PasswordInput,
  phone: PhoneInput,
  ratingStar: RatingStarInput,
  ratingThumb: RatingThumbInput,
  select: SelectInput,
  switch: SwitchInput,
  text: TextInput,
  textarea: TextareaInput,
  textEmoji: TextEmojiInput,
  toggle: ToggleInput,
  customDate: InputDate,
  custom: DefaultComponent,
  enum: DefaultComponent,
};

const isPayPo = [
  INPUT_IDS.PAYPO_ADDRESS_CITY,
  INPUT_IDS.PAYPO_ADDRESS_STREET,
  INPUT_IDS.PAYPO_ADDRESS_BUILD_NUMBER,
  INPUT_IDS.PAYPO_ADDRESS_PLACE_NUMBER,
];

type RestInputProps = {
  autoComplete?: string;
  autoFocus?: boolean;
  defaultCountry?: string;
  disabled?: boolean;
  customDisabledStyles?: TwStyle;
  hide?: boolean;
  isSearchable?: boolean;
  maxLength?: number;
  mask?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  options?: { label: string; value: string }[];
  placeholder?: string;
  readOnly?: boolean;
  setFieldValue?: (field: string, value: unknown) => void;
  tooltip?: string;
  values?: { [key: string]: unknown };
};

type StylesObject = {
  styles?: { css: TwStyle; mode?: string };
};

type InputsIndexProps = {
  id?: string;
  btnProps?: TwStyle;
  default?: unknown;
  Component?: ReactNode;
  description?: string;
  formGroupProps?: StylesObject;
  label?: ReactNode & LabelHTMLAttributes<HTMLLabelElement>;
  labelProps?: StylesObject;
  name?: string;
  innerRef?: Ref<HTMLDivElement>;
  isFieldHidden?: boolean;
  required?: boolean;
  type?: InputTypes;
} & RestInputProps;

type InputTypes =
  | 'btnGroup'
  | 'checkbox'
  | 'email'
  | 'hidden'
  | 'number'
  | 'password'
  | 'phone'
  | 'ratingStar'
  | 'ratingThumb'
  | 'select'
  | 'switch'
  | 'text'
  | 'textarea'
  | 'textEmoji'
  | 'toggle'
  | 'customDate'
  | 'custom'
  | 'enum';

const InputsIndex = ({
  id,
  name,
  type = 'text',
  label = '',
  innerRef = undefined,
  required = false,
  Component = null,
  labelProps = {},
  description = '',
  formGroupProps = {},
  isFieldHidden = false,
  ...restInputProps
}: InputsIndexProps) => {
  name ??= id;

  const { locale } = useLocale();

  const showLabel = !(type === 'checkbox' || type === 'toggle');

  const { hasError, apiFieldError, hasApiFieldError } = useFormikErrors(id);

  const inputProps = useMemo(() => {
    let inputProps: RestInputProps &
      Pick<
        InputsIndexProps,
        'label' | 'required' | 'default' | 'type' | 'id' | 'btnProps'
      > = {};

    const {
      tooltip,
      disabled,
      options = [],
      placeholder = '',
    }: RestInputProps = restInputProps;

    switch (type) {
      case 'toggle':
      case 'checkbox': {
        inputProps = {
          label,
          required,
          disabled,
          tooltip,
        };
        break;
      }
      case 'switch': {
        inputProps = {
          label,
          disabled,
          options,
          default: restInputProps.default,
        };
        break;
      }
      case 'hidden':
      case 'password':
      case 'number': {
        inputProps = { type, ...restInputProps };
        break;
      }
      case 'select': {
        inputProps = { options, placeholder, id, ...restInputProps };
        break;
      }
      case 'enum': {
        inputProps = {
          options,
        };
        break;
      }
      case 'phone': {
        const defaultCountry =
          locale in localeToCountryPhoneAbbr
            ? localeToCountryPhoneAbbr[
                locale as keyof typeof localeToCountryPhoneAbbr
              ]
            : locale;

        inputProps = {
          defaultCountry: defaultCountry.toUpperCase(),
        };
        break;
      }
      case 'btnGroup': {
        inputProps = {
          options,
          btnProps: restInputProps.btnProps,
        };
        break;
      }

      default: {
        inputProps = { ...restInputProps };
      }
    }

    return inputProps;
  }, [label, type, restInputProps]);

  const InputComponent =
    INPUTS[type] || dynamic(() => import('./UnknownInput'));

  return (
    <>
      <FormGroup isInvalid={hasApiFieldError || hasError} {...formGroupProps}>
        {label && showLabel && (
          <Label htmlFor={id} {...labelProps}>
            {label} {required && '*'}
          </Label>
        )}
        {!isFieldHidden && (
          <>
            {type === 'custom' ? (
              Component
            ) : (
              <Field name={name}>
                {({
                  field,
                  form,
                }: {
                  field: FieldInputProps<unknown>;
                  form: FormikProps<unknown>;
                }) => {
                  let additionalInputProps = {};

                  if (type === 'select') {
                    additionalInputProps = {
                      value: field.value,
                      onChange: (option: {
                        default?: boolean;
                        label: string;
                        value: string;
                      }) => {
                        form.setFieldValue(field.name, option);
                      },
                      onBlur: () => {
                        form.validateField(field.name);
                        form.setFieldTouched(field.name, true, false);
                      },
                    };
                  }

                  if (['btnGroup', 'ratingThumb'].includes(type)) {
                    additionalInputProps = {
                      value: field.value,
                      onChange: (option: string | undefined) => {
                        form.setFieldValue(field.name, option);
                      },
                    };
                  }

                  if (['phone', 'textEmoji', 'ratingStar'].includes(type)) {
                    additionalInputProps = {
                      isInvalid: hasApiFieldError || hasError,
                      onChange: (item: {
                        '@type': string;
                        '@id': string;
                        countryCode: string;
                        number: string;
                      }) => {
                        form.setFieldValue(field.name, item);
                      },
                      onBlur: () => {
                        form.setFieldTouched(field.name, true);
                      },
                    };
                  }

                  if (
                    name === INVOICE_FIELDS.ADDRESS_POST_CODE ||
                    name === ADDRESS_INPUT_FIELDS.POSTAL_CODE
                  ) {
                    additionalInputProps = {
                      onChange: (e: ChangeEvent<HTMLInputElement>) => {
                        form.setFieldValue(
                          field.name,
                          e.target.value.toUpperCase()
                        );
                      },
                    };
                  }

                  if (['customDate'].includes(type)) {
                    additionalInputProps = {
                      isInvalid: hasApiFieldError || hasError,
                      onChange: (e: ChangeEvent<HTMLInputElement>) => {
                        const currentYear = new Date().getFullYear();

                        const [year = '', month = '', day = ''] =
                          e.target.value.split('-');

                        const parsedYear = parseInt(year);
                        const parsedMonth = parseInt(month);
                        const parsedDay = parseInt(day);
                        if (
                          parsedYear > currentYear ||
                          parsedMonth > 12 ||
                          parsedDay > 31
                        ) {
                          return;
                        }

                        form.setFieldValue(field.name, e.target.value);
                      },
                      onBlur: () => {
                        form.setFieldTouched(field.name, true);
                      },
                    };
                  }

                  if (isPayPo.some(el => el === field.name)) {
                    additionalInputProps = {
                      isInvalid: hasApiFieldError || hasError,
                      onChange: (item: ChangeEvent<HTMLInputElement>) => {
                        if (
                          (item.target.value.length === 1 &&
                            item.target.value == '0') ||
                          item.target.value == ' '
                        ) {
                          form.setFieldValue(field.name, null);
                          return;
                        }
                        form.setFieldValue(field.name, item.target.value);
                      },
                      onBlur: () => {
                        form.setFieldTouched(field.name, true);
                      },
                    };
                  }

                  return (
                    <InputComponent
                      {...{
                        ...field,
                        ...inputProps,
                        ...additionalInputProps,
                        'data-cy': `input-${type}--${kebabCase(id)}`,
                      }}
                      ref={innerRef}
                    />
                  );
                }}
              </Field>
            )}
            {description && <HelperText>{description}</HelperText>}
            {hasApiFieldError && <HelperText>{apiFieldError}</HelperText>}
            {name && <ErrorMessage name={name} component={HelperText} />}
          </>
        )}
      </FormGroup>
    </>
  );
};

InputsIndex.displayName = 'InputsIndex';

export default InputsIndex;
