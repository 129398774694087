import { useCallback } from 'react';
import produce from 'immer';
import _filter from 'lodash/filter';
import _first from 'lodash/first';
import _isEmpty from 'lodash/isEmpty';

import BASKET_MODES from '@constants/basketModes';
import BASKET_ROWS_TYPES from '@constants/basketRowsTypes';
import DIET_LENGTH_MODE from '@constants/dietLengtMode';
import useOrderForm from '@hooks/useOrderForm';
import { format } from '@services/Date.service';

const useTransformResponseBasketRedux = () => {
  const {
    data: {
      customDietLengthEnabled,
      packages,
      packageLineFilters,
      predefinedDietLengthEnabled,
      diets,
    },
  } = useOrderForm();

  const dietLengthMode =
    predefinedDietLengthEnabled && !customDietLengthEnabled
      ? DIET_LENGTH_MODE.PREDEFINED
      : DIET_LENGTH_MODE.CUSTOM;

  const transformResponseDietAddon = useCallback(
    ({ addon, quantity, type }) => {
      return {
        addon: addon['@id'],
        type,
        quantity,
      };
    },
    []
  );

  const transformResponseDietRow = useCallback(
    dietRow => {
      const {
        '@id': iri,
        '@type': typeIri,
        days = [],
        options = {},
        ...diet
      } = dietRow;

      const isEmptyAddress = _isEmpty(diet?.address);
      const isEmptyPickUpPoint = _isEmpty(diet?.pickUpPoint);

      const activePackageIndex = packages.findIndex(
        pack => pack['@id'] === diet?.package
      );

      const activePackage =
        activePackageIndex !== -1
          ? diet?.package
          : packages.find(({ activeDietsIds }) =>
              activeDietsIds.includes(diet?.diet?.id)
            )?.['@id'];

      const packageLineFilter = packageLineFilters?.find(filter =>
        filter.activePackagesIds.includes(
          parseInt(activePackage?.split('/').pop())
        )
      );
      return {
        '@id': iri,
        '@type': typeIri,
        id: diet?.diet.id,
        addons: diet?.addons?.map(transformResponseDietAddon) ?? [], // fix
        address: !isEmptyAddress ? diet.address : {},
        calorificId: diet?.calorific?.['@id'] ?? null,
        connectedElements: {},
        deliveryDates: Array.isArray(days)
          ? days ?? []
          : [...Object.values(days)],
        deliveryType: !isEmptyAddress
          ? 'ADDRESS'
          : !isEmptyPickUpPoint
          ? 'PICKUP_POINT'
          : null,
        mealTypes: diet?.mealTypes
          ? (diet?.mealTypes ?? []).map(mealType => mealType?.['@id'])
          : undefined,
        dietId: diet?.diet?.['@id'] ?? null,
        dietLength: diet?.dietLength,
        dietLengthMode: diet?.dietLengthMode ?? dietLengthMode,
        firstDeliveryDay: days?.[0],
        optionChangeMenu: options?.optionChangeMenu,
        packageId: activePackage ?? null, // checkpackage exist
        packageLineFilters: packageLineFilter ?? null,
        paymentMode: diet?.paymentMode,
        pickUpPoint: !isEmptyPickUpPoint ? diet.pickUpPoint : {},
        saturdayInclude: options?.saturdayInclude,
        sundayInclude: options?.sundayInclude,
        useEcoContainers: options?.useEcoContainers,
        variantId: diet?.variant?.['@id'] ?? null,
        allowStepWithAdditionalMealTypes: diets.find(
          ({ id }) => id === diet?.diet.id
        ).allowStepWithAdditionalMealTypes,
        hasDietsToSelectMenu: diets.find(({ id }) => id === diet?.diet.id)
          .hasDietsToSelectMenu,
        price: diet?.price,
      };
    },
    [packages, dietLengthMode]
  );

  const transformResponseBasketRedux = useCallback(
    (response, basketItems = []) => {
      const currentReduxDietElements = basketItems?.dietElements;
      let dietElements = _filter(response.rows, [
        '@type',
        BASKET_ROWS_TYPES.DIET_ITEM,
      ]);

      // Attemp to keep dietLengthMode from redux store
      if (
        !_isEmpty(currentReduxDietElements) &&
        currentReduxDietElements?.length === dietElements?.length
      ) {
        dietElements = dietElements.map((dietElement, index) => ({
          ...dietElement,
          dietLengthMode: currentReduxDietElements?.[index]?.dietLengthMode,
        }));
      }

      const shopElements = _filter(response.rows, item =>
        [BASKET_ROWS_TYPES.ADDON_ITEM, BASKET_ROWS_TYPES.DISH_ITEM].includes(
          item['@type']
        )
      );

      const connectedElementsByDietsIri = shopElements.reduce((acc, item) => {
        const nextState = produce(acc, draftState => {
          const { connectedTo, day, clientDiet } = item;
          const connectedToIri = connectedTo?.['@id'] ?? 'notConnected';
          const clientDietIri = clientDiet ?? 'noDiet';
          const formattedDay = format(new Date(day));

          draftState[connectedToIri] ??= {};
          draftState[connectedToIri][clientDietIri] ??= {};
          draftState[connectedToIri][clientDietIri][formattedDay] ??= {};

          const dietDay =
            draftState[connectedToIri][clientDietIri][formattedDay];
          dietDay[item.dishSize['@id']] = item.quantity;
        });

        return nextState;
      }, {});
      const convertedDietElements = dietElements.map(transformResponseDietRow);

      const items = Object.entries(connectedElementsByDietsIri).reduce(
        (acc, [connectedToIri, clientDietItems]) =>
          produce(acc, draftState => {
            const connectedDietIndex = draftState.dietElements.findIndex(
              diet => diet['@id'] === connectedToIri
            );

            if (connectedDietIndex !== -1) {
              draftState.dietElements[connectedDietIndex].connectedElements =
                clientDietItems || {};
            } else {
              draftState.others = clientDietItems || {};
            }
          }),
        { dietElements: convertedDietElements, others: {} }
      );

      const noDietCollapsedDays = shopElements.reduce((acc, item) => {
        const formattedDate = format(new Date(item.day));

        return {
          ...acc,
          ...(item.connectedTo?.['@id']
            ? { [formattedDate]: { isOpen: false } }
            : {}),
        };
      }, {});

      return {
        activeOrderDietIri: _first(dietElements)?.['@id'] ?? null,
        items: {
          dietElements: items.dietElements,
          ...items.others,
        },
        collapsed: {
          noDiet: { isOpen: true, days: noDietCollapsedDays },
        },
        useDiscountCode: !_isEmpty(response?.discountCode?.['@id']),
        discountCode: {
          '@id': response?.discountCode?.['@id'] ?? null,
          value: response?.discountCode?.code ?? '',
        },
        useMoneyBox: response?.useMoneybox ?? false,
        paymentMethod: response?.paymentType ?? null,
        isRedirectingToConfirm: false,
        basketMode: BASKET_MODES.NORMAL,
      };
    },
    [packages]
  );

  return [transformResponseBasketRedux];
};

export default useTransformResponseBasketRedux;
