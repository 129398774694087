import { useTranslation } from 'next-i18next';

import { useCallback } from 'react';
import { isAfter, isSameDay } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import {
  selectModuleShop,
  selectMultinational,
  useAppConfigSelector,
  useAppMode,
} from '@hooks/useAppConfigSelectors';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

const useValidateBasket = () => {
  const { t } = useTranslation();
  const {
    minimumDailyOrderCost,
    maximumDailyOrderCost,
    minimumDailyDishes,
    maximumDailyDishes,
  } = useAppConfigSelector(selectModuleShop);
  const { isShop, isOnlyShop } = useAppMode();
  const { currencySymbol } = useAppConfigSelector(selectMultinational);

  const validateBasketDayRequirements = (dayPrice, dayDishesCount = 0) => {
    if (
      minimumDailyOrderCost &&
      dayPrice.beforeDiscount < minimumDailyOrderCost
    ) {
      return {
        isValid: false,
        errorMessage: t('$*components.basketDay.error.minimumDailyOrderCost', {
          defaultValue:
            'Minimalna dzienna wartość zamówienia wynosi {{value}} {{currencySymbol}}. Dodaj do koszyka coś jeszcze lub zwiększ rozmiar porcji.',
          replace: {
            value: getIntegerIfIntegerOrFixed(minimumDailyOrderCost),
            currencySymbol,
          },
        }),
      };
    }

    if (
      maximumDailyOrderCost &&
      dayPrice.beforeDiscount > maximumDailyOrderCost
    ) {
      return {
        isValid: false,
        errorMessage: t('$*components.basketDay.error.maximumDailyOrderCost', {
          defaultValue:
            'Maksymalna dzienna wartość zamówienia wynosi {{value}} {{currencySymbol}}. Usuń coś z koszyka lub zmniejsz rozmiar porcji.',
          replace: {
            value: getIntegerIfIntegerOrFixed(maximumDailyOrderCost),
            currencySymbol,
          },
        }),
      };
    }

    if (minimumDailyDishes && dayDishesCount < minimumDailyDishes) {
      return {
        isValid: false,
        errorMessage: t('$*components.basketDay.error.minimumDailyDishes', {
          defaultValue:
            'Minimalna dzienna ilość dań wynosi {{count}}. Dodaj do koszyka któreś z dań.',
          replace: { count: minimumDailyDishes },
        }),
      };
    }

    if (maximumDailyDishes && dayDishesCount > maximumDailyDishes) {
      return {
        isValid: false,
        errorMessage: t('$*components.basketDay.error.maximumDailyDishes', {
          defaultValue:
            'Maksymalnalna dzienna ilość dań wynosi {{count}}. Usuń z koszyka któreś z dań.',
          replace: { count: maximumDailyDishes },
        }),
      };
    }

    return {
      isValid: true,
      errorMessage: null,
    };
  };

  const checkIsValid = useCallback((rows = {}) => {
    let allBasketErrors = {};
    let isValidBasket = [];

    const getDayDishesCount = dishes => {
      const dayDishesCount = Object.values(dishes).reduce((acc, { sizes }) => {
        const sizesQuantity = sizes.reduce(
          (acc, { quantity }) => acc + quantity,
          0
        );

        return acc + sizesQuantity;
      }, 0);

      return dayDishesCount;
    };

    if (isOnlyShop) {
      allBasketErrors = isShop
        ? Object.entries(rows.others).reduce(
            (acc, [dietIri, days]) => {
              const dietErrors = Object.entries(days).reduce(
                (acc, [date, { price, dishes }]) => {
                  const dayDishesCount = getDayDishesCount(dishes);
                  const errorObj = validateBasketDayRequirements(
                    price,
                    dayDishesCount
                  );

                  isValidBasket.push(errorObj.isValid);

                  return {
                    ...acc,
                    [date]: errorObj,
                  };
                },
                {}
              );

              return {
                ...acc,
                notConnected: { ...acc.notConnected, [dietIri]: dietErrors },
              };
            },
            { notConnected: {} }
          )
        : {};
    } else {
      allBasketErrors = rows.diets.reduce(
        (
          acc,
          {
            '@id': dietIri,
            connectedElements = {},
            days: dietDays = [],
            paymentMode,
          }
        ) => {
          const isSubscription =
            paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;

          const dietConnectedElementErrors = Object.entries(
            connectedElements
          ).reduce((acc, [dietIri, days]) => {
            const dietErrors = Object.entries(days).reduce(
              (acc, [date, { price, dishes }]) => {
                const dayDishesCount = getDayDishesCount(dishes);
                const formattedDay = new Date(date);
                const formattedFirstDeliveryDay = new Date(dietDays[0]);
                const dayIsInSubscriptionBag =
                  isSubscription &&
                  (isSameDay(formattedDay, formattedFirstDeliveryDay) ||
                    isAfter(formattedDay, formattedFirstDeliveryDay));
                const dayIsInBag = dietDays.some(day =>
                  isSameDay(new Date(day), formattedDay)
                );

                const errorObj =
                  dayIsInSubscriptionBag || dayIsInBag
                    ? {
                        isValid: true,
                        errorMessage: null,
                      }
                    : validateBasketDayRequirements(price, dayDishesCount);

                isValidBasket.push(errorObj.isValid);

                return {
                  ...acc,
                  [date]: errorObj,
                };
              },
              {}
            );

            return {
              ...acc,
              [dietIri]: dietErrors,
            };
          }, {});

          return {
            ...acc,
            ...(!isEmpty(dietConnectedElementErrors)
              ? { [dietIri]: dietConnectedElementErrors }
              : {}),
          };
        },
        {}
      );
    }

    return {
      errors: allBasketErrors,
      isValid: isValidBasket.every(isValid => isValid),
    };
  }, []);

  return [checkIsValid];
};

export default useValidateBasket;
