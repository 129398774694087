import { isAfter, isBefore } from 'date-fns';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';

export const setActiveCurrentAndCollapseRest = ({ state, dietIri, day }) => {
  if (isEmpty(state.collapsed[dietIri])) {
    state.collapsed[dietIri] = { isOpen: true, days: {} };
  }

  if (isEmpty(state.collapsed[dietIri].days[day])) {
    state.collapsed[dietIri].days[day] = { isOpen: true };
  }

  Object.entries(state.collapsed).map(([dietIriKey, dietObj]) => {
    dietObj.isOpen = dietIriKey === dietIri;

    Object.entries(dietObj.days).map(([dateKey, dateObj]) => {
      dateObj.isOpen = dateKey === day;
    });
  });
};

const setOrderDetails = state => {
  const { dietElements = [], ...shopElements } = state.items;

  const {
    allDishesCount,
    allAddonsCount,
    allDaysCount,
    firstDeliveryDay: shopFirstDeliveryDay,
    lastDeliveryDay: shopLastDeliveryDay,
  } = Object.entries(shopElements).reduce(
    (acc, [, days]) => {
      const { dietDishesCount, dietAddonsCount } = Object.entries(days).reduce(
        (acc, [, dayItems]) => {
          const { dayDishesCount, dayAddonsCount } = Object.entries(
            dayItems
          ).reduce(
            (acc, [key, quantity]) => {
              return {
                dayDishesCount: key.includes('dish-sizes')
                  ? acc.dayDishesCount + quantity
                  : acc.dayDishesCount,
                dayAddonsCount: key.includes('addons')
                  ? acc.dayAddonsCount + quantity
                  : acc.dayAddonsCount,
              };
            },
            { dayDishesCount: 0, dayAddonsCount: 0 }
          );

          return {
            dietDishesCount: acc.dietDishesCount + dayDishesCount,
            dietAddonsCount: acc.dietAddonsCount + dayAddonsCount,
          };
        },
        { dietDishesCount: 0, dietAddonsCount: 0 }
      );

      const sortedDays = [...Object.keys(days)].sort(
        (a, b) => new Date(a) - new Date(b)
      );

      const firstDeliveryDay = first(sortedDays);
      const lastDeliveryDay = last(sortedDays);

      return {
        allAddonsCount: acc.allAddonsCount + dietAddonsCount,
        allDaysCount: acc.allDaysCount + Object.keys(days).length,
        allDishesCount: acc.allDishesCount + dietDishesCount,
        firstDeliveryDay:
          acc.firstDeliveryDay === null
            ? firstDeliveryDay
            : isBefore(
                new Date(firstDeliveryDay),
                new Date(acc.firstDeliveryDay)
              )
            ? firstDeliveryDay
            : acc.firstDeliveryDay,
        lastDeliveryDay:
          acc.lastDeliveryDay === null
            ? lastDeliveryDay
            : isAfter(new Date(lastDeliveryDay), new Date(acc.lastDeliveryDay))
            ? lastDeliveryDay
            : acc.lastDeliveryDay,
      };
    },
    {
      allAddonsCount: 0,
      allDaysCount: 0,
      allDishesCount: 0,
      firstDeliveryDay: null,
      lastDeliveryDay: null,
    }
  );

  const hasDiets = !isEmpty(dietElements);
  const dietDelivery = dietElements.reduce(
    (acc, { deliveryDates = [] }) => {
      const sortedDays = [...deliveryDates].sort(
        (a, b) => new Date(a) - new Date(b)
      );

      const firstDeliveryDay = first(sortedDays);
      const lastDeliveryDay = last(sortedDays);

      return {
        firstDeliveryDay:
          acc.firstDeliveryDay === null
            ? firstDeliveryDay
            : isBefore(
                new Date(firstDeliveryDay),
                new Date(acc.firstDeliveryDay)
              )
            ? firstDeliveryDay
            : acc.firstDeliveryDay,
        lastDeliveryDay:
          acc.lastDeliveryDay === null
            ? lastDeliveryDay
            : isAfter(new Date(lastDeliveryDay), new Date(acc.lastDeliveryDay))
            ? lastDeliveryDay
            : acc.lastDeliveryDay,
      };
    },
    { firstDeliveryDay: null, lastDeliveryDay: null }
  );

  state.orderDetails.summary.diets = dietElements.length;
  state.orderDetails.summary.days = allDaysCount;
  state.orderDetails.summary.dishes = allDishesCount;
  state.orderDetails.summary.addons = allAddonsCount;

  state.orderDetails.summary.firstDeliveryDay =
    [shopFirstDeliveryDay, dietDelivery.firstDeliveryDay]
      .filter(Boolean)
      .sort((a, b) => new Date(a) - new Date(b))?.[0] ?? null;

  state.orderDetails.summary.lastDeliveryDay =
    [shopLastDeliveryDay, dietDelivery.lastDeliveryDay]
      .filter(Boolean)
      .sort((a, b) => new Date(a) - new Date(b))?.[0] ?? null;

  state.isEmpty = !hasDiets && allDishesCount === 0 && allAddonsCount === 0;
};

export const toggleBasketDayReducer = (state, { payload }) => {
  const { dietIri = 'noDiet', date } = payload;
  state.collapsed[dietIri].days[date].isOpen =
    !state.collapsed[dietIri].days[date].isOpen;
};

export const toggleBasketGroupReducer = (
  state,
  { payload: dietIri = 'noDiet' }
) => {
  state.collapsed[dietIri].isOpen = !state.collapsed[dietIri].isOpen;
};

export const setRevalidateBasketReducer = (_, { payload }) => payload;

export const addDietReducer = (state, { payload }) => {
  state.activeOrderDietIri = payload?.['@id'] ?? null;
  const newPayload = {
    ...payload,
    deliveryType: 'ADDRESS',
    address: {},
    pickUpPoint: {},
  };

  state.items.dietElements.push(newPayload);

  setOrderDetails(state);
};

export const removeDietReducer = (state, { payload: dietIri }) => {
  const currentDietIndex = state.items.dietElements.findIndex(
    diet => diet['@id'] === dietIri
  );

  if (currentDietIndex !== -1) {
    state.items.dietElements.splice(currentDietIndex, 1);

    if (state.activeOrderDietIri === dietIri) {
      state.activeOrderDietIri = state.items.dietElements?.[0]?.['@id'] ?? null;
    }
  }

  setOrderDetails(state);
};

export const updateDietReducer = (state, { payload }) => {
  const { existingItem, ...restPayload } = payload;
  const dietIri = existingItem ?? state?.activeOrderDietIri;

  const currentDietIndex = state?.items?.dietElements?.findIndex(
    row => row['@id'] === dietIri
  );

  if (!isEmpty(restPayload['@id']) && restPayload['@id'] !== dietIri) {
    state.activeOrderDietIri = restPayload['@id'];
  }

  state.items.dietElements[currentDietIndex] = {
    ...state.items.dietElements[currentDietIndex],
    ...restPayload,
  };

  setOrderDetails(state);
};

export const updateBasketWithCurrentDietReducer = (state, { payload }) => {
  const { currentDiet, ...restBasket } = payload;
  const { existingItem, ...restPayload } = currentDiet;

  const dietIri = existingItem ?? state?.activeOrderDietIri;
  const currentDietIndex = state?.items?.dietElements?.findIndex(
    row => row['@id'] === dietIri
  );

  Object.entries(restBasket).map(([key, value]) => {
    state[key] = value;
  });
  state.items.dietElements[currentDietIndex] = {
    ...state.items.dietElements[currentDietIndex],
    ...restPayload,
  };

  setOrderDetails(state);
};

export const addModifyDayToBasketReducer = () => {};

export const addItemToBasketReducer = (
  state,
  { payload: { item, options } }
) => {
  const { iri, day, quantity, dietIri = 'noDiet' } = item;

  let itemPlace = state.items;
  if (!options.isOnlyShop && options.basketKey === 'basketNewOrder') {
    const activeDiet = state.items.dietElements.find(
      row => row['@id'] === options.connectedTo
    );

    activeDiet.connectedElements ??= {};
    itemPlace = activeDiet.connectedElements;
  }

  if (isEmpty(itemPlace[dietIri])) {
    itemPlace[dietIri] = {};
  }

  if (isEmpty(itemPlace[dietIri][day])) {
    itemPlace[dietIri][day] = { [iri]: quantity };
  } else {
    itemPlace[dietIri][day][iri] = quantity;
  }

  setActiveCurrentAndCollapseRest({ state, dietIri, day });

  // clear store
  if (quantity === 0) {
    delete itemPlace[dietIri][day][iri];

    if (isEmpty(itemPlace[dietIri][day])) {
      delete itemPlace[dietIri][day];
      delete state.collapsed[dietIri].days[day];
      // delete state.orderDetails.summary[dietIri][day];

      if (isEmpty(itemPlace[dietIri])) {
        delete itemPlace[dietIri];
        delete state.collapsed[dietIri];
        // delete state.orderDetails.summary[dietIri];
      }
    }
  }
};

export const updateAdditionalMealTypesReducer = (
  state,
  { payload: { item, options } }
) => {
  const additionalMealTypesKey = 'additionalMealTypes';

  const { dates, quantity, mealTypeIri, mealTypeName, mealTypePrice } = item;

  const activeDiet = state.items.dietElements.find(
    row => row['@id'] === options.connectedTo
  );

  activeDiet.connectedElements ??= {};
  const itemPlace = activeDiet.connectedElements;

  if (isEmpty(itemPlace[additionalMealTypesKey])) {
    itemPlace[additionalMealTypesKey] = {};
  }

  if (isEmpty(itemPlace[additionalMealTypesKey][mealTypeIri])) {
    itemPlace[additionalMealTypesKey][mealTypeIri] = {
      mealTypeName,
      mealTypePrice,
      deliveryDates: dates.reduce(
        (acc, date) => ({
          ...acc,
          [date]: quantity,
        }),
        {}
      ),
    };
  } else {
    itemPlace[additionalMealTypesKey][mealTypeIri] = {
      ...itemPlace[additionalMealTypesKey][mealTypeIri],
      deliveryDates: dates.reduce(
        (acc, date) => ({
          ...acc,
          [date]: quantity,
        }),
        { ...itemPlace[additionalMealTypesKey][mealTypeIri]?.deliveryDates }
      ),
    };
  }

  // clear store
  if (quantity === 0) {
    const shouldRemoveWholeMealType =
      Object.keys(
        itemPlace[additionalMealTypesKey][mealTypeIri]?.deliveryDates ?? {}
      )?.length === dates?.length;

    if (shouldRemoveWholeMealType) {
      delete itemPlace[additionalMealTypesKey][mealTypeIri];
    }

    dates.forEach(date => {
      delete itemPlace[additionalMealTypesKey][mealTypeIri]?.deliveryDates[
        date
      ];
    });
  }
};

export const setAdditionalMealTypesReducer = (
  state,
  { payload: { data, options } }
) => {
  const additionalMealTypesKey = 'additionalMealTypes';

  const activeDiet = state.items.dietElements.find(
    row => row['@id'] === options.connectedTo
  );

  activeDiet.connectedElements ??= {};
  const itemPlace = activeDiet.connectedElements;

  itemPlace[additionalMealTypesKey] = data;
};
