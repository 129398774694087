import { useMemo } from 'react';

import { selectOrderForm, useOrderFormSelector } from '@hooks/useOrderForm';

const useWeekInclude = () => {
  const data = useOrderFormSelector(selectOrderForm);

  const weekInclude = useMemo(() => {
    const saturdayInclude = data?.saturdayInclude ?? true;
    const sundayInclude = data?.sundayInclude ?? true;

    const turnedOffDaysOfWeek =
      [saturdayInclude ? null : 6, sundayInclude ? null : 0].filter(
        Number.isInteger
      ) ?? [];

    return {
      saturdayInclude: !turnedOffDaysOfWeek.includes(6),
      sundayInclude: !turnedOffDaysOfWeek.includes(0),
    };
  }, []);

  return weekInclude;
};

export default useWeekInclude;
