const TAB_SLUGS = {
  PREMIUM: 'wybor-menu',
  SUMMARY: 'podsumowanie',
  SELECT_DIET: 'wybierz-diete',
  AUTHORIZATION: 'autoryzacja',
  DELIVERY_DATA: 'dane-dostawy',
  SELECT_DISHES: 'wybierz-dania',
  SELECT_ADDONS: 'wybierz-dodatki',
  ADDITIONAL_MEALTYPES: 'dodatkowe-posilki',
  CHOICES_DISH: 'wymien-menu',
};

export default TAB_SLUGS;
