import Head from 'next/head';

const DeclarationScript = () => {
  return (
    <Head>
      <script id="declaration-event">
        {`
          if (window.dataLayer) {
            const getPageType = pathName => {
              const pageTypes = [
                {
                  path: '/new-order/create',
                  type: 'Order form',
                },
                {
                  path: /^(?=.*new-order)(?=.*confirm)/,
                  type: 'Order confirmation',
                },
                {
                  path: '/my-account',
                  type: 'User account',
                },
                {
                  path: '/auth',
                  type: 'Authentication',
                },
                {
                  path: /menu|dishes|recipes|diets/,
                  type: 'Menu',
                },
              ]

              const pageType = pageTypes.find(pageType => new RegExp(pageType.path).test(pathName));

              return pageType ? pageType.type : '';
            };
          
            const getBreadcrumb = pathName =>
              window.location.pathname
                .substring(
                  1,
                  pathName.indexOf('?') > 0 ? pathName.indexOf('?') : undefined
                )
                .replaceAll('/', ' / ');
          
            const getHashedString = async (inputString, algorithm) => {
              const encoder = new TextEncoder();
              const data = encoder.encode(inputString);
              let hashedString = '';
          
              await crypto.subtle.digest(algorithm, data).then(hashBuffer => {
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                hashedString = hashArray
                  .map(byte => byte.toString(16).padStart(2, '0'))
                  .join('');
              });
          
              return hashedString;
            };
          
            const setDeclarationEvent = async (e) => {
              const user = e?.detail ?? JSON.parse(
                JSON.parse(window.localStorage['persist:nextjs']).auth
              ).user;
              
              const isAuthenticated = !!user.id
          
              const declarationEvent = {
                event: 'declaration',
                user: {
                  uid: user.id || "",
                  hmail: user.email ? await getHashedString(user.email, 'SHA-1') : "",
                  hmail256: user.email ? await getHashedString(user.email, 'SHA-256') : "",
                  logged: isAuthenticated,
                  new_client: isAuthenticated ? !user.hasAnyPayedOrder : null,
                },
                page: {
                  page_type: getPageType(window.location.pathname),
                  breadcrumb: getBreadcrumb(window.location.pathname),
                  last_modified: new Date().toISOString().split('T')[0],
                },
              };
          
              window.dataLayer.push(declarationEvent);
            };
          
            setDeclarationEvent();
            document.addEventListener('triggerDeclarationEvent', setDeclarationEvent);
          }
          `}
      </script>
    </Head>
  );
};

export default DeclarationScript;
