import type { ReactNode } from 'react';
import type { CustomStyles } from '@tsTypes/customStyles';
import tw from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

type Props = {
  children?: ReactNode;
  styles?: { mode?: 'merge' | 'replace'; css?: CustomStyles };
  type?: 'error' | 'warning' | 'info' | 'success';
};

const Alert = ({ children = null, styles = {}, type = 'error' }: Props) => {
  const twWrapperStyle = cssMerge({
    defaultCss: [
      tw`px-4 py-2 text-sm border rounded`,
      {
        error: tw`text-red-2 bg-red-0 border-red-2`,
        info: tw`text-blue-1 bg-blue-0 border-blue-1`,
        success: tw`text-green-1 bg-green-0 border-green-1`,
        warning: tw`text-orange-1 bg-orange-0 border-orange-1`,
        default: tw`border-gray-1 bg-gray-0`,
      }[type],
    ],
    ...styles,
  });

  return <div css={twWrapperStyle}>{children}</div>;
};

export default Alert;
