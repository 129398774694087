import { type HTMLProps, forwardRef } from 'react';
import tw, { type TwStyle } from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

type Size = 'xs' | 'sm' | 'md' | 'lg';

const PADDING: { [key in Size]: TwStyle } = {
  xs: tw`px-2 py-3 sm:px-5 sm:py-4 md:px-7 md:py-6 lg:px-8 lg:py-7`,
  sm: tw`px-4 py-4`,
  md: tw`px-5 py-4`,
  lg: tw`px-5 py-4 md:px-7 md:py-6 lg:px-8 lg:py-7`,
};

type Props = HTMLProps<HTMLDivElement> & {
  size?: Size;
  styles?: TwStyle | TwStyle[];
};

const Box = forwardRef<HTMLDivElement, Props>(
  ({ size = 'lg' as Size, children, styles = {}, ...restProps }, ref) => {
    const twStyle = cssMerge({
      defaultCss: [tw`bg-white rounded-md shadow-lg`, PADDING[size]],
      ...styles,
    });

    return (
      <div css={twStyle} {...restProps} ref={ref}>
        {children}
      </div>
    );
  }
);

Box.displayName = 'Box';

export default Box;
