import { toast } from 'react-toastify';

const showToast = (message, options = {}) =>
  toast(message, {
    style: { minHeight: 'auto' },
    type: 'error',
    ...options,
  });

export default showToast;
