import { useEffect, useMemo, useState } from 'react';
import { addDays, getDay, startOfWeek } from 'date-fns';
import { format as fnsFormat } from 'date-fns';
import { enUS } from 'date-fns/locale';
import uniq from 'lodash/uniq';

import {
  selectIsBlockDeliveryEnabled,
  selectModuleConfigClientPanel,
  selectWeekDays,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import { format, formatArray } from '@services/Date.service';

const useBlockDelivery = ({ daysLeftToChoose, remainingDaysCount } = {}) => {
  const isBlockDeliveryEnabled = useAppConfigSelector(
    selectIsBlockDeliveryEnabled
  );
  const { validateBagChangesWithSlots = true } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  const weekDaysSettings = useAppConfigSelector(selectWeekDays);
  const [disabledBlockDeliveryWeekDays, setDisabledBlockDeliveryWeekDays] =
    useState([]);

  useEffect(() => {
    const newDisabledBlockDeliveryWeekdDays = getDisabledWeekdays();

    setDisabledBlockDeliveryWeekDays(newDisabledBlockDeliveryWeekdDays);
  }, [daysLeftToChoose, remainingDaysCount]);

  const areWeekendsDeliveredInBlock = useMemo(
    () =>
      weekDaysSettings.saturday.shouldBeDeliveredInBlock !== 6 ||
      weekDaysSettings.sunday.shouldBeDeliveredInBlock !== 7,
    [weekDaysSettings]
  );

  const blocksFromConfig = arr => {
    let b = [],
      prev;

    arr.sort();
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = arr[i];
    }

    return uniq(b);
  };

  const blockSizes = blocksFromConfig(
    Object.values(weekDaysSettings)
      .filter(settings => typeof settings === 'object')
      .map(settings => settings.shouldBeDeliveredInBlock)
  );

  const getDisabledWeekdays = () => {
    if (
      !isBlockDeliveryEnabled ||
      daysLeftToChoose >= 7 ||
      daysLeftToChoose === undefined
    )
      return [];

    let currentlyAvailableBlockSizes =
      getCurrentlyAvailableBlockSizes(daysLeftToChoose);
    const smallestAvailableBlockSize = Math.min(
      ...currentlyAvailableBlockSizes
    );
    const currentWeek = getSelectedDayWeekDates(new Date());

    if (
      remainingDaysCount < smallestAvailableBlockSize &&
      blockSizes.includes(remainingDaysCount)
    ) {
      currentlyAvailableBlockSizes = [remainingDaysCount];
    }

    const newDisabledWeekdays = currentWeek
      .filter(day => {
        const blockSize = getBlockDays(day)?.length;

        return !currentlyAvailableBlockSizes?.includes(blockSize);
      })
      .map(day => getDay(day));

    return newDisabledWeekdays;
  };

  const getDaySettings = day => {
    const weekDayName = fnsFormat(day, 'EEEE', { locale: enUS });

    return weekDaysSettings[weekDayName.toLowerCase()];
  };

  const checkIfDayIsDeliveredInBlock = day => {
    if (!isBlockDeliveryEnabled) return false;

    const daySettings = getDaySettings(day) ?? {};

    const shouldBeDeliveredInBlockSelectedDay =
      daySettings?.shouldBeDeliveredInBlock;

    return shouldBeDeliveredInBlockSelectedDay > 0 ? true : false;
  };

  const getSelectedDayWeekDates = day => {
    const firstDayOfWeek = startOfWeek(new Date(day), {
      weekStartsOn: 1,
    });

    let currentDate = firstDayOfWeek;
    let selectedDayWeekDates = [firstDayOfWeek];

    while (selectedDayWeekDates.length < 7) {
      currentDate = addDays(new Date(currentDate), 1);

      selectedDayWeekDates = [...selectedDayWeekDates, currentDate];
    }

    return selectedDayWeekDates;
  };

  const getBlockDays = day => {
    const selectedDaySettings = getDaySettings(day);
    const weekDatesToCheck = getSelectedDayWeekDates(day);

    return weekDatesToCheck.filter(date => {
      const dateSettings = getDaySettings(date);

      return (
        dateSettings?.shouldBeDeliveredInBlock ===
        selectedDaySettings?.shouldBeDeliveredInBlock
      );
    });
  };

  const getUpdatedBlockDates = ({ clickedDay, deliveryDates } = {}) => {
    const blockDays = getBlockDays(clickedDay);
    const formatedDates = formatArray(deliveryDates);
    const formatedBlockDays = formatArray(blockDays);

    // Removing day
    if (formatedDates.includes(format(clickedDay))) {
      return [
        ...deliveryDates.filter(
          deliveryDate => !formatedBlockDays.includes(format(deliveryDate))
        ),
      ];
    }

    // Adding day
    return [...deliveryDates, ...blockDays];
  };

  const getCurrentlyAvailableBlockSizes = (currentDaysAmount, depth = 0) => {
    if (currentDaysAmount === 0) {
      return [];
    }
    const blocksSum = blockSizes.reduce((a, b) => a + b, 0);
    const result = [];

    if (currentDaysAmount > 13) {
      return blockSizes;
    }

    if (currentDaysAmount % blocksSum === 0) {
      return blockSizes;
    }

    const currentDaysModuloSumPlusSum =
      (currentDaysAmount % blocksSum) + blocksSum;
    if (currentDaysAmount > blocksSum) {
      blockSizes.forEach(block => {
        if (
          currentDaysModuloSumPlusSum % block === 0 ||
          currentDaysAmount % block === 0
        ) {
          result.push(block);

          if (depth < 5) {
            blockSizes.forEach(testowany_blok => {
              if (testowany_blok !== block) {
                result.push(
                  ...getCurrentlyAvailableBlockSizes(
                    currentDaysAmount - block,
                    ++depth
                  )
                );
              }
            });
          }
        }
      });
    } else {
      blockSizes.forEach(block => {
        if (currentDaysAmount % block === 0) {
          result.push(block);
        }
      });
    }
    blockSizes.forEach(block => {
      blockSizes.forEach(blockToTest => {
        if (blockToTest !== block) {
          let lastTestedLength;
          let multiplier = 1;
          do {
            const lastTestedLength =
              currentDaysAmount - multiplier * blockToTest;

            if (lastTestedLength > 0 && lastTestedLength % block === 0) {
              result.push(block);
              result.push(blockToTest);
            }
            multiplier++;
          } while (lastTestedLength > 0);
        }
      });
    });

    return uniq(result);
  };

  return {
    getBlockDays,
    getUpdatedBlockDates,
    isBlockDeliveryEnabled,
    areWeekendsDeliveredInBlock,
    validateBagChangesWithSlots,
    checkIfDayIsDeliveredInBlock,
    disabledBlockDeliveryWeekDays,
    getCurrentlyAvailableBlockSizes,
  };
};

export default useBlockDelivery;
