import { css, Global } from '@emotion/react';
import { parseToRgb } from 'polished';
import tw, { theme } from 'twin.macro';

import { screen } from '@utils/styleHelpers';

// const COLUMNS = 12;

const customCss = ({
  rootVars: {
    backgroundColor,
    backgroundImage,
    primaryColor,
    secondaryColor = '#B58A78',
    primaryFont,
    secondaryFont,
  },
}) => {
  const getColumns = screen => {
    const screenClass = screen ? `${screen}\\:` : '';
    return [
      '8.3333333333',
      '16.6666666667',
      '25',
      '33.3333333333',
      '41.6666666667',
      '50',
      '58.3333333333',
      '66.6666666667',
      '75',
      '83.3333333333',
      '91.6666666667',
      '100',
    ]
      .map(
        (percent, index) => `
        .${screenClass}col-${index + 1} {
          flex: 0 0 auto;
          width: ${percent}%;
        }`
      )
      .join();
  };

  const primaryColorRgb = parseToRgb(primaryColor);
  const primaryColorRgbString = `${primaryColorRgb.red}, ${primaryColorRgb.green}, ${primaryColorRgb.blue}`;

  const secondaryColorRgb = parseToRgb(secondaryColor);
  const secondaryColorRgbString = `${secondaryColorRgb.red}, ${secondaryColorRgb.green}, ${secondaryColorRgb.blue}`;

  return css`
    :root {
      --color-primary: ${primaryColorRgbString};
      --color-primary-hover: ${secondaryColorRgbString};
      --color-bodyBg: ${backgroundColor};
      --font-primary: ${primaryFont};
      --font-secondary: ${secondaryFont};
    }

    ::selection {
      ${tw`text-white bg-primary`}
    }

    *:focus-visible {
      ${tw`outline-none ring-2 ring-offset-2 ring-primary`}
    }

    * {
      -webkit-tap-highlight-color: transparent;
    }

    body {
      ${tw`text-base antialiased text-gray-3 font-primary`}
      ${backgroundImage
        ? `background: url(${backgroundImage}) 0 0 / cover fixed;`
        : `background: ${backgroundColor}`}
    }

    a,
    .primary-link {
      ${tw`no-underline transition-colors duration-300 cursor-pointer text-primary`};

      &:hover,
      &.is-active {
        ${tw`transition-colors duration-300 text-primary-hover`};
      }
    }

    button {
      ${tw`focus:(outline-none)`}
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
      ${tw`font-semibold font-secondary text-primary`}
    }

    h1,
    .h1 {
      ${tw`mb-5 text-3xl`}
    }

    h2,
    .h2 {
      ${tw`mb-4 text-2xl`}
    }

    h3,
    .h3 {
      ${tw`text-xl mb-3.5`}
    }

    h4,
    .h4 {
      ${tw`mb-3 text-base`}
    }

    h5,
    .h5 {
      ${tw`mb-2 text-sm`}
    }

    h6,
    .h6 {
      ${tw`mb-1 text-xs`}
    }

    p {
      ${tw`mb-3 last:mb-0`};
    }

    .container {
      width: 100%;
      padding-right: 16px;
      padding-left: 16px;
      margin-right: auto;
      margin-left: auto;

      ${screen.md`
        max-width: 720px;
      `}

      ${screen.lg`
        max-width: 960px;
      `}

      ${screen.xl`
        max-width: 1140px;
      `}

      ${screen.xxl`
        max-width: 1370px;
      `}
    }

    .container.lg {
      width: 100%;
      padding-right: 16px;
      padding-left: 16px;
      margin-right: auto;
      margin-left: auto;

      ${screen.md`
        max-width: 720px;
      `}

      ${screen.lg`
        max-width: 960px;
      `}

      ${screen.xl`
        max-width: 1140px;
      `}
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -16px;
      margin-left: -16px;
    }

    .row > * {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: 16px;
      padding-left: 16px;
    }

    .col-auto {
      flex: 0 0 auto;
      width: auto;
    }

    .col {
      flex: 1 0 0%;
    }

    ${getColumns()}

    ${screen.sm`
      .sm\\:col-auto {
        flex: 0 0 auto;
        width: auto;
      }

      .sm\\:col {
        flex: 1 0 0%;
      }

      ${getColumns('sm')}
    `}

    ${screen.md`
      .md\\:col-auto {
        flex: 0 0 auto;
        width: auto;
      }

      .md\\:col {
        flex: 1 0 0%;
      }

      ${getColumns('md')}
    `}

    ${screen.lg`
      .lg\\:col-auto {
        flex: 0 0 auto;
        width: auto;
      }

      .lg\\:col {
        flex: 1 0 0%;
      }

      ${getColumns('lg')}
    `}

    ${screen.xl`
      .xl\\:col-auto {
        flex: 0 0 auto;
        width: auto;
      }

      .xl\\:col {
        flex: 1 0 0%;
      }

      ${getColumns('xl')}
    `}

    ${screen.xxl`
      .xxl\\:col-auto {
        flex: 0 0 auto;
        width: auto;
      }

      .xxl\\:col {
        flex: 1 0 0%;
      }

      ${getColumns('xxl')}
    `}

    .Toastify__toast-container {
      @media only screen and (min-width: 480px) {
        ${tw`w-auto max-w-md`};
      }
    }

    @media only screen and (max-width: 480px) {
      .Toastify__toast-container {
        ${tw`p-2`}
      }

      .Toastify__toast-container--top-right {
        top: 0;
        right: 0;
      }

      .Toastify__toast {
        ${tw`mb-2`}
      }
    }

    .is-invalid {
      input {
        ${tw`border-red-2`}
      }

      .helper-text,
      label {
        ${tw`text-red-2`}
      }
    }

    /* FLEX */
    .col--main-left {
      ${tw`w-full`};

      @media (min-width: ${theme`screens.xl`}) {
        width: 746px;
      }

      @media (min-width: ${theme`screens.xxl`}) {
        width: 956px;
      }
    }

    .col--main-center {
      ${tw`w-full`};

      @media (min-width: ${theme`screens.xl`}) {
        width: 746px;
      }

      @media (min-width: ${theme`screens.xxl`}) {
        width: 574px;
      }
    }

    .col--main-right {
      ${tw`ml-8`};

      @media (min-width: ${theme`screens.xl`}) {
        width: 330px;
      }

      @media (min-width: ${theme`screens.xxl`}) {
        width: 350px;
      }
    }

    /* KEYFRAMES */

    @keyframes tremble {
      0% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
      }
      3.33333% {
        transform: translate(-0.18923px, 1.45485px) rotate(0deg) scale(1);
      }
      6.66667% {
        transform: translate(-0.84296px, -1.32524px) rotate(0deg) scale(1);
      }
      10% {
        transform: translate(0.67971px, 1.00422px) rotate(0deg) scale(1);
      }
      13.33333% {
        transform: translate(-0.5056px, 0.83616px) rotate(0deg) scale(1);
      }
      16.66667% {
        transform: translate(1.31368px, -0.51401px) rotate(0deg) scale(1);
      }
      20% {
        transform: translate(-1.21184px, 1.49193px) rotate(0deg) scale(1);
      }
      23.33333% {
        transform: translate(1.09065px, -0.21259px) rotate(0deg) scale(1);
      }
      26.66667% {
        transform: translate(-1.49916px, 0.56159px) rotate(0deg) scale(1);
      }
      30% {
        transform: translate(1.48086px, 1.21228px) rotate(0deg) scale(1);
      }
      33.33333% {
        transform: translate(-1.43889px, -1.152px) rotate(0deg) scale(1);
      }
      36.66667% {
        transform: translate(1.35914px, 1.34835px) rotate(0deg) scale(1);
      }
      40% {
        transform: translate(-1.42834px, 0.3091px) rotate(0deg) scale(1);
      }
      43.33333% {
        transform: translate(1.47472px, -1.49889px) rotate(0deg) scale(1);
      }
      46.66667% {
        transform: translate(-0.92402px, 1.4416px) rotate(0deg) scale(1);
      }
      50% {
        transform: translate(1.0657px, -0.75306px) rotate(0deg) scale(1);
      }
      53.33333% {
        transform: translate(-1.19035px, -1.07484px) rotate(0deg) scale(1);
      }
      56.66667% {
        transform: translate(0.28828px, 0.79337px) rotate(0deg) scale(1);
      }
      60% {
        transform: translate(-0.47167px, -1.42789px) rotate(0deg) scale(1);
      }
      63.33333% {
        transform: translate(0.64753px, -0.09795px) rotate(0deg) scale(1);
      }
      66.66667% {
        transform: translate(0.41006px, -0.26292px) rotate(0deg) scale(1);
      }
      70% {
        transform: translate(-0.22477px, -1.3683px) rotate(0deg) scale(1);
      }
      73.33333% {
        transform: translate(0.03588px, 0.92931px) rotate(0deg) scale(1);
      }
      76.66667% {
        transform: translate(-1.01937px, -1.18398px) rotate(0deg) scale(1);
      }
      80% {
        transform: translate(0.8724px, -0.60494px) rotate(0deg) scale(1);
      }
      83.33333% {
        transform: translate(-0.71151px, 1.4786px) rotate(0deg) scale(1);
      }
      86.66667% {
        transform: translate(1.40734px, -1.49607px) rotate(0deg) scale(1);
      }
      90% {
        transform: translate(-1.33062px, 0.46957px) rotate(0deg) scale(1);
      }
      93.33333% {
        transform: translate(1.23264px, 1.26738px) rotate(0deg) scale(1);
      }
      96.66667% {
        transform: translate(-1.48975px, -1.03867px) rotate(0deg) scale(1);
      }
      100% {
        transform: translate(0, 0) rotate(0) scale(1);
      }
    }

    /* END KEYFRAMES */

    /* SWIPERS */

    .swiper-slide--auto {
      ${tw`w-auto`}
    }

    .swiper--days-main {
      .swiper-wrapper {
        ${tw`items-center`};
        min-height: 112px;
      }

      .swiper-slide {
        ${screen.md`
        width: 126px;
      `}

        ${tw`w-16`}
      }
    }

    .swiper--days-sticky {
      .swiper-wrapper {
        ${tw`items-center`};
        min-height: 65px;
      }

      .swiper-slide {
        ${tw`w-16`}
      }
    }

    .swiper--orders-bag {
      .swiper-wrapper {
        ${tw`items-center`};
        min-height: 112px;
      }
    }

    .swiper--diets,
    .swiper--menu-day {
      .swiper-wrapper {
        ${tw`items-center`};
        width: 100%;
      }

      .swiper-slide {
        width: auto;
      }
    }

    .swiper--variants {
      .swiper-wrapper {
        ${tw`items-center`};
        max-height: 45px;
      }

      .swiper-slide {
        ${screen.md`
        width: 126px;
      `}

        ${tw`w-16`}
      }
    }

    .swiper--weekly-menu {
      .swiper-wrapper {
        ${tw`items-start`};
      }
    }

    .swiper-button-disabled {
      display: none;
    }

    .swiper--calorifics {
      .swiper-wrapper {
        ${tw`items-center`};
        min-height: 50px;
        padding: 0 -0.25rem;
        width: 100%;
      }

      .swiper-slide {
        width: auto;
      }
    }

    .swiper--home-page,
    .swiper--dish-page,
    .swiper--diet-page {
      .swiper-wrapper {
        align-items: stretch;
      }

      .swiper-slide {
        height: auto;
      }
    }

    .masonry-grid {
      ${tw`flex w-auto -mx-2 -mb-4 md:-mb-6 md:-mx-3`}
    }

    .masonry-grid-column {
      ${tw`px-2 md:px-3`}
      background-clip: padding-box;
    }

    /* Style your items */
    .masonry-grid-column > div {
      /* change div to reference your elements you put in <Masonry> */
      ${tw`mb-4 md:mb-6`}
    }

    .page--new-order #footer {
      ${tw`pb-20 xl:pb-6`}
    }

    #googlePayButton button {
      ${tw`rounded-md`}
      z-index: 0;
    }

    #googlePayButton iframe {
      ${tw`py-0.5`}
    }

    ol {
      list-style: revert;
      margin: revert;
      padding: revert;
    }
  `;
};

const GlobalStyles = ({ rootVars }) => (
  <Global styles={customCss({ rootVars })} />
);

GlobalStyles.displayName = 'GlobalStyles';

export default GlobalStyles;
