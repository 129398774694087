export const pushPollsterEvent = event => {
  if (!window || !window.pollsterTrack || !process.browser) return;

  window.pollsterTrack.event({ ...event });
};

export const pushAuthenticationPollsterEvent = ({ gid, userId }) => {
  const event = {
    gid,
    type: 'id',
    content: `user_id=${userId}`,
  };

  pushPollsterEvent(event);
};

export const pushPurchasePollsterEvent = ({ user, gid, orderDetails }) => {
  const {
    id: transactionId,
    items,
    pricePayed,
    paymentType,
    priceLeftToPay,
  } = orderDetails;

  let revenue = '';

  if (paymentType === 'STRIPE_CARD') {
    revenue =
      priceLeftToPay === 0 && pricePayed !== 0 ? pricePayed : priceLeftToPay;
  } else {
    revenue =
      paymentType === 'BANK_WIRE' || paymentType === 'CASH'
        ? priceLeftToPay
        : pricePayed;
  }

  const products = (items ?? []).map(
    ({ offerDiet, variant, calorific, priceAfterDiscount }) => ({
      name: offerDiet?.name,
      option: variant?.name,
      calories: calorific?.name,
      price: priceAfterDiscount,
    })
  );

  const event = {
    gid,
    type: 'buy_product',
    content: {
      products,
      user_id: user?.id,
      total_price: revenue,
      transaction_id: transactionId,
    },
  };

  pushPollsterEvent(event);
};
