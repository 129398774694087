const ROUTE_URLS = {
  HOME: '/',
  NEW_ORDER_FORM: '/new-order/create',
  NEW_ORDER_SHOP: '/new-order/shop',
  NEW_ORDER_AUTH: '/new-order/auth',
  NEW_ORDER_DELIVERY: '/new-order/delivery',
  NEW_ORDER_SUMMARY: '/new-order/summary',
  NEW_ORDER_ERROR: '/new-order/error',
  NEW_ORDER_FAILED: '/new-order/failed',
  NEW_ORDER_CONFIRMATION: (orderId: string) => `/new-order/${orderId}/confirm`,
  NEW_ORDER_PAYMENT_STATUS: (orderId: string) =>
    `/new-order/${orderId}/payment-status`,

  MENU: '/menu',
  DISH_PAGE: (dishId: string) => `/dishes/${dishId}`,
  RECIPE_PAGE: (dishId: string) => `/recipes/${dishId}`,

  AUTH_LOGIN: '/auth/login',
  AUTH_REGISTER: '/auth/register',
  LOGIN_AS_USER: '/auth/switch',
  RESET_PASSWORD: '/auth/reset-password',
  RESET_PASSWORD_EMAIL_SENT: '/auth/email-sent',

  MY_ORDERS: '/my-account/my-orders',
  MY_ORDER_DETAILS: ({
    day,
    dietId,
    bagId = 0,
    type,
  }: {
    day: string;
    dietId: string;
    bagId: number;
    type: string;
  }) => `/my-account/my-orders/details/${day}/${dietId}/${bagId}?type=${type}`,
  ACCOUNT_SETTINGS: '/my-account/account-settings',
  MONEY_BOX: '/my-account/money-box',
  HISTORY_ORDERS: '/my-account/history-orders',
  SHARE_PROGRAM: '/my-account/share-program',
  REORDER: (orderId: string) => `/my-account/my-orders/reorder/${orderId}`,
  BMI_CALCULATOR: '/my-account/bmi-calculator',
};

export default ROUTE_URLS;
