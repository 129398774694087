import { useCallback, useMemo } from 'react';

import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';

const useDefaultPaymentMode = () => {
  const {
    defaultPaymentMode,
    isOneTimePayPaymentMode,
    isSubscriptionPaymentMode,
  } = useAppConfigSelector(selectModuleConfigClientPanel);

  const checkIsDefaultPaymentModeValid = useCallback(() => {
    switch (defaultPaymentMode) {
      case '1':
        return isSubscriptionPaymentMode;
      case '2':
        return isOneTimePayPaymentMode;
      default:
        return true;
    }
  }, []);

  const memoizedValue = useMemo(() => {
    return checkIsDefaultPaymentModeValid() ? defaultPaymentMode : 2;
  }, [checkIsDefaultPaymentModeValid, defaultPaymentMode]);

  return memoizedValue;
};

export default useDefaultPaymentMode;
