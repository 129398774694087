import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import tw from 'twin.macro';

import Error404Icon from '@assets/icons/Error404Icon';
import Box from '@components/elements/Box';
import Button from '@components/elements/Button';
import Seo from '@components/modules/Seo/Seo';
import ROUTE_URLS from '@constants/routeUrls';

const Error404Page = () => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <>
      <Seo
        pageName={t('$*error404Page.pageName', '404 - Nie znaleziono strony')}
      />
      <div className="container">
        <Box
          styles={{
            css: tw`flex items-center justify-center max-w-3xl p-5 mx-auto text-center rounded-xl`,
          }}
        >
          <div tw="p-5 flex-1">
            <Error404Icon tw="w-52 mx-auto" />
          </div>
          <div tw="p-5 flex-1 border-l">
            <h1 tw="text-3xl font-bold">
              {t('$*errorPage.title', 'Oops! Coś poszło nie tak')}
            </h1>
            <p tw="text-lg">
              {t(
                '$*error404Page.description',
                'Wygląda na to, że strona, której szukasz nie istnieje.'
              )}
            </p>
            <Button
              onClick={() => {
                router.push(ROUTE_URLS.NEW_ORDER_FORM);
              }}
              styles={{ button: tw`mx-auto mt-10` }}
            >
              {t('$*errorPage.backToOrderForm', 'Powrót do zamówienia')}
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
};

export default Error404Page;
